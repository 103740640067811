import React from 'react';
import {aboutPic1, aboutPic2, aboutPic3, aboutPic4, aboutPic5} from '../../components/imagesComponents';

const AboutComponent = () => {
  return (
    <React.Fragment>
      <section className="special_container">
        <h1>About</h1>

        <div id="text_about">
          <p>
            Last February, as I entered a restaurant in Utah, I realized how many times during the day I touch contaminated objects and surfaces.
            <br/><br/>
            </p>
            <div className="about">
              <div className="about__container">
              <img src={aboutPic2} alt="" width="100%"/>
              <img src={aboutPic3} alt="" width="100%"/>
              </div>
              <div className="about__container2">
              <img src={aboutPic1} alt="" width="100%"/>
              </div>
            </div>
            <br/><br/><br/>
            <p>
              Concerned about COVID-19 and how the virus is transmitted, I started thinking about an easy way to disinfect my hands.
              The first thing I looked at was my wrist. I figured that having even a few drops of hand sanitizer in my hands would be enough to keep me safe. I thought about creating a wrist band that I could fill with hand sanitizer to dispense. I left that thought disappeared for a few weeks.<br/><br/>
              At that time COVID-19 was not a real threat in the US, but in China. I knew about the COVID-19 situation in China because we work with some chinese manufacturers. I started sharing this idea about SprayCare with some friends and co-workers.<br/><br/>
            </p>
            <div className="about">
              <img src={aboutPic4} alt="" width="100%"/>
            </div>
            <p>
            <br/>
              We started the plan for SprayCare in April. Then in May, we noticed a big demand for hand sanitizer dispensers. As a result, we started seeing more hand sanitizer dispensers in the market, but most of them seemed uncomfortable or bulky.
              We wanted to build something light-weight, innovate, and that could be worn all the time. After many iterations, we arrived at the perfect design and built the first SprayCare prototype
            <br/><br/> We have found out that 8 out of 10 people would love to wear a SprayCare band during this time.
          </p>
          <br/><br/><br/>
            <div className="about">
              <img src={aboutPic5} alt="" width="100%"/>
            </div>
          <br/><br/><br/>
        <a href="/">
          <button className="largeButtonOrder">HOME</button>
        </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutComponent;
