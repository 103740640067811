import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import DashboardComponent from './DashboardComponent';
const Dashboard = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <DashboardComponent />
      <FooterAppp />
    </React.Fragment>
  );
};

export default Dashboard;
