import React, { useState, useEffect,useContext } from 'react';
import { AccountCircle } from '../../components/imagesComponents';
import { deleteCookie } from '../../services/CookiesServices';
import { getStates } from '../../services/States';
import AddressComponent from '../../components/AddressComponent';
import { encryptInformation,dcryptInformation } from '../../services/EncryptService';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';
import $ from 'jquery';

const CheckoutCustomerComponent = ({ changeScreen, getData,userAddress,setTax}) => {
  const {state:{logged},dispatch} = useContext(AuthContext);

  const [customerName, setCustomerName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [arrayStates,setArrayStates] = useState([]);
  const [country, setCountry] = useState('');
  //const [province, setProvince] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    if (localStorage.getItem('token')) {
      let userInformation = JSON.parse(localStorage.getItem('user'));
      setEmail(userInformation.email);
      setCustomerName(userInformation.name);
    }
    let localData = localStorage.getItem('info');
    if (localData !== '' && localData !== null) {
      let objectData = dcryptInformation(localData);
      if(objectData != ''){
        setFirstName(objectData.firstName);
        setEmail(objectData.email);
        setLastName(objectData.lastName);
        setAddress(objectData.address);
        setApartment(objectData.apartment);
        setCity(objectData.city);
        setCountry(objectData.country);
        setState(objectData.state);
        setZip(objectData.zip);
        setPhone(objectData.phone);
        if (objectData.country !== undefined)
          setArrayStates(getStates(objectData.country));
      }
    }
  }, []);
  const getInformationAddress = (e) => {
    setFirstName(e.firstname);
    setLastName(e.lastname);
    setAddress(e.address);
    setApartment(e.apartment);
    setCity(e.city);
    setCountry(e.country);
    setState(e.state);
    setZip(e.zip);
    setPhone(e.phone);
    setArrayStates(getStates(e.country));
    let stateRate = getStates(e.country).find((val) => val.abbreviation === e.state);
    if(stateRate) setTax(stateRate, e.country);
    $('#modalAddress').modal("hide")
  }
  const onSubmit = (e) => {
    e.preventDefault();
    if(email !== ''){
      changeScreen({ id: 0, type: 'NEXT' });
      getData({
        id: 0,
        dataForm: {
          firstName,
          lastName,
          address,
          apartment,
          city,
          country,
          state,
          zip,
          phone,
          email,
        },
      });
    }else{
      let emailField = document.querySelector("#emailField");
      emailField.focus();
    }
  };

  const renderField = () => {
    if (logged) {
      return (
        <>
          <div className="card-login">
            <div className="card-login_image">
              <img src={AccountCircle} alt="user image" />
            </div>
            <div className="card-login_text-container">
              <p>
                {customerName} ( {email} )
              </p>
              <h5 className="card-login_select-address" onClick={() => $('#modalAddress').modal("show")}>Click to Select Address</h5>
              <span
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('user');
                  localStorage.removeItem('info');
                  sessionStorage.removeItem('last_order');
                  deleteCookie();
                  window.location.replace('login');
                }}>
                Log out
              </span>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <input
              id="emailField"
              type="email"
              placeholder="email"
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label placeholder="email"></label>
          </div>
        </>
      );
    }
  };
  return (
    <React.Fragment>
      <div className="forms_checkout-information">
        <h3>Contact Information</h3>
        <h5 style={logged ? { display: 'none' } : {}}>
          Already have an account? <a href="login" onClick={() => localStorage.setItem("return",true)}>Log in</a>
        </h5>
      </div>
      <div className="forms_checkout-email">{renderField()}</div>
      <div>
        <h4>Shipping Address</h4>
        <br/>
        <br/>
        <form className="forms" onSubmit={onSubmit}>
          <div>
            <div className="forms-multiple_fields forms_padding_fields">
              <input
                type="text"
                name="first_name"
                placeholder="First name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                required
              />
              <label placeholder="First name"></label>
            </div>
            <div className="forms-multiple_fields">
              <input
                type="text"
                name="last_name"
                placeholder="Last name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <label placeholder="Last name"></label>
            </div>
          </div>
          <div>
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
            <label placeholder="Address"></label>
          </div>
          <div>
            <input
              type="text"
              name="apartment"
              placeholder="Apartment, suite, etc."
              value={apartment}
              onChange={(e) => setApartment(e.target.value)}
            />
            <label placeholder="Apartment, suite, etc."></label>
          </div>
          <div>
            <div className="forms-multiple_fields forms_padding_fields">
              <select
                onChange={(e) => {
                  let statesArray = getStates(e.target.value);
                  if(statesArray) {
                    setArrayStates(statesArray);
                  }
                  setCountry(e.target.value)
                  console.log('setCountry', e.target.value)
                  if(e.target.value !== "US"){
                    setTax(0, e.target.value)
                  }else{
                    let stateRate = statesArray.find((val) => val.abbreviation === state)
                    if(stateRate){
                      setTax(stateRate, e.target.value)
                      setState(state)
                    }
                  }
                }}
                value={country}
                required>
                <option value="">Select country</option>
                <option value="US">United States</option>
                <option value="AU">Australia</option>
                <option value="AT">Austria</option>
                <option value="BH">Bahrain</option>
                <option value="BE">Belgium</option>
                <option value="BR">Brazil</option>
                <option value="KH">Cambodia</option>
                <option value="CA">Canada</option>
                <option value="CL">Chile</option>
                <option value="CN">China</option>
                <option value="CO">Colombia</option>
                <option value="HR">Croatia</option>
                <option value="DK">Denmark</option>
                <option value="EE">Estonia</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="DE">Germany</option>
                <option value="HK">Hong Kong</option>
                <option value="HU">Hungary</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IL">Israel</option>
                <option value="IT">Italy</option>
                <option value="JP">Japan</option>
                <option value="MY">Malaysia</option>
                <option value="MX">Mexico</option>
                <option value="MD">Moldova</option>
                <option value="NL">Netherlands</option>
                <option value="NZ">New Zealand</option>
                <option value="PH">Philippines</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="QA">Qatar</option>
                <option value="IE">Republic of Ireland</option>
                <option value="RU">Russia</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SG">Singapore</option>
                <option value="SI">Slovenia</option>
                <option value="ZA">South Africa</option>
                <option value="KR">South Korea</option>
                <option value="ES">Spain</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="TW">Taiwan</option>
                <option value="TH">Thailand</option>
                <option value="UA">Ukraine</option>
                <option value="AE">United Arab Emirates</option>
                <option value="GB">United Kingdom</option>
              </select>
            </div>
            <div className="forms-multiple_fields">
              <select
                onChange={(e) => {
                  let stateRate = arrayStates.find((val) => val.abbreviation === e.target.value);
                  if(stateRate) setTax(stateRate, country);
                  setState(e.target.value);
                }}
                value={state}
                required>
                <option value="">Select state</option>
                {arrayStates.map((val,i) => (<option key={i} value={val.abbreviation}>{val.name}</option>))}
              </select>
            </div>
          </div>
          <div>
            <input
              type="text"
              name="city"
              placeholder="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
            <label placeholder="City"></label>
          </div>
          <div>
            <div className="forms-multiple_fields forms_padding_fields">
              <input
                type="text"
                name="zip"
                placeholder="Zip code"
                required
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
              <label placeholder="Zip code"></label>
            </div>
            <div className="forms-multiple_fields">
              <input
                type="text"
                name="phone"
                placeholder="Phone"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <label placeholder="Phone"></label>
            </div>
          </div>
          <div>
            <div className="forms-multiple_fields forms_padding_fields">
              <a href="/shopping_car">
                <button
                  type="button"
                  className="largeButtonOrder btn_transparent">
                  RETURN TO CART
                </button>
              </a>
            </div>
            <div className="forms-multiple_fields">
              <button type="submit" className="largeButtonOrder">
                CONTINUE SHIPPING
              </button>
            </div>
          </div>
        </form>
      </div>
      <div
        id="modalAddress"
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content ">
            <h1 style={{ textAlign: 'center', marginTop: '30px' }}>
              Select your Address
            </h1>
            <div style={{height:500,overflow:"scroll",marginTop:"40px"}}>
              <div style={{margin:"0 auto",width:"50%"}}>
                {userAddress.length == 0?(<h5>You don'y have any address register, please click <a href="/dashboard" style={{fontSize:"1.25rem"}}>here</a> for create a new address.</h5>): userAddress.map((val,i) => {
                  return(<AddressComponent key={val._id} {...val} openModal={getInformationAddress} showDeleteButton={false}/> )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CheckoutCustomerComponent;
