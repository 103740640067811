import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import SpinnerComponent from '../../components/spinner';
import { URL } from '../../services/conection';
//import ipAddress from './location';

const CreateLeadComponent = () => {
 const [loading,setLoading] = useState(false);
 const [email, setEmail] = useState('');
 const [ip, setIp] = useState('');

 const getIP = async (e)=> {
  let response = await fetch('https://api.ipify.org/');
  let data = await response.text();
  setIp(data);
 };
 useEffect(() => {
  getIP()
 }, []);
 const onSubmit = async (e) => {
  e.preventDefault();
  setLoading(true);
  let object = {
   email,
   ip,
  };
  let sendCreate = await fetch(`${URL}api/lead`, {
   method: 'POST',
   headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
   },
   body: JSON.stringify(object),
  });
  let resp = await sendCreate.json();
  if (resp) {
   setLoading(false);

   Swal.fire({
    title: resp.message,
    onClose: () => {
     window.location.replace('/');
    },
   });
  } else {
   Swal.fire({
    type: 'error',
    title: 'An Error has occurred',
   });
  }
 };
 return (
  <React.Fragment>
   {loading && <SpinnerComponent color={"transparent"} />}
   <section className="special_container">
    <div style={{fontSize: 18, width: '90%', paddingLeft: '20%', paddingRight: '5%'}}>Enter your email to be notified when we go live on Kickstarter and<b> get 10%-40% OFF.</b></div>
    <form className="forms" onSubmit={onSubmit}>
     <input type="hidden" id="ip" name="ip" value={ip} onChange={(e) => setIp(e.target.value)}/>
     <div>
      <input
       type="email"
       name="email"
       required
       placeholder="Email"
       value={email}
       onChange={(e) => setEmail(e.target.value)}
      />
      <label placeholder="Email"></label>
     </div>
     <div>
      <div
       className="g-recaptcha"
       data-sitekey="6LfrtrAZAAAAAOvZcltG3rM76acz9e_YN_GX0vGs"></div>
     </div>
     <button type="submit" className="largeButtonOrder">
      SUBMIT
     </button>
    </form>
   </section>
  </React.Fragment>
 );
};

export default CreateLeadComponent;
