import React, { useState } from 'react';
import Swal from 'sweetalert2';
import SpinnerComponent from '../../components/spinner';
import { URL } from '../../services/conection';
const CreateAccountComponent = () => {
  const [loading,setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let object = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
    };
    let sendCreate = await fetch(`${URL}api/user/register-user`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    let resp = await sendCreate.json();
    if (resp) {

      setLoading(false);
      if (resp.code === 200) {
        Swal.fire({
          type: 'success',
          title: 'User created successfully',
          onClose: () => {
            window.location.replace('/login');
          },
        });
      } else if (resp.errors) {
        let htmlError = '<ul> ';
        for (let error of resp.errors) {
          htmlError += `<li>${error.msg}</li>`;
        }
        htmlError += '<ul>';
        Swal.fire({
          title: 'Here are your errors',
          html: htmlError,
        });
      } else {
        Swal.fire({
          title: resp.message,
        });
      }
    } else {
      Swal.fire({
        type: 'error',
        title: 'Error to send request',
      });
    }
  };
  return (
    <React.Fragment>
    {loading && <SpinnerComponent color={"transparent"} />}
      <section className="special_container">
        <h1>Create account</h1>
        <form className="forms" onSubmit={onSubmit}>
          <div>
            <input
              type="text"
              name="first_name"
              required
              placeholder="First name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <label placeholder="First name"></label>
          </div>
          <div>
            <input
              type="text"
              name="last_name"
              required
              placeholder="Last name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <label placeholder="Last name"></label>
          </div>
          <div>
            <input
              type="email"
              name="email"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label placeholder="Email"></label>
          </div>
          <div>
            <input
              type="password"
              name="password"
              required
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <label placeholder="Password"></label>
          </div>
          <div>
            <div
              className="g-recaptcha"
              data-sitekey="6LfrtrAZAAAAAOvZcltG3rM76acz9e_YN_GX0vGs"></div>
          </div>
          <button type="submit" className="largeButtonOrder">
            CREATE
          </button>
          <a href="login" className="createAccountButton">
            Sign in
          </a>
        </form>
      </section>
    </React.Fragment>
  );
};

export default CreateAccountComponent;
