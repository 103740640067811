import React from "react";
import {
  CommonSprayers,
  SprayCare,
  SprayCareReachableArea
} from "../../components/imagesComponents";
import ScrollAnimation from "react-animate-on-scroll";

const SprayCareInformationSection = ({informationRef}) => {
  return (<section className='information' ref={informationRef}>
    <div className='information__wrapper'>
      <div className='information__text-box'>
        <ScrollAnimation animateIn='animate__slideInUp'>
          <h1 className='heading-primary heading-primary--white heading-primary--line'>
            Why SprayCare is&nbsp;better
              </h1>
          <p className='paragraph paragraph--white u-margin-top-medium'>
            Our atomizer dispenses droplets of sanitizer that produces
            superior coverage. The result is effective and economic use of
            the disinfectant.
              </p>
        </ScrollAnimation>
      </div>
      <div className='information__card information__card--large'>
        <img src={SprayCareReachableArea} alt='Imagen de las manos' />
      </div>
      <div className='information__card-box'>
        <div className='information__card'>
          <h2 className='heading-quinary heading-quinary--white heading-quinary--features'>
            Common Sprayers
              </h2>
          <br />
          <p className='paragraph paragraph--tertiary paragraph--white'>
            With common sprayers it is necessary&nbsp;to&nbsp;rub your hands
            or wipe&nbsp;the&nbsp;surface in order to spread the
            disinfectant.
                <br />
            <br />
          </p>
          <div className='information__card__img information__card__img--large'>
            <img src={CommonSprayers} alt='Imagen de las manos' />
          </div>
        </div>
        <div className='information__card'>
          <h2 className='heading-quinary heading-quinary--white heading-quinary--features'>
            SprayCare Atomizer
              </h2>
          <br />
          <p className='paragraph paragraph--tertiary paragraph--white'>
            SprayCare atomizing technology dispenses the disinfectant so
            that rubbing your hands or wiping the surface to spread the
            disinfectant is not necessary.
              </p>
          <div className='information__card__img'>
            <img src={SprayCare} alt='Imagen de las manos' />
          </div>
        </div>
      </div>
    </div>
  </section>);
}

export default SprayCareInformationSection;