import React from 'react';

const Refund = () => {
  return (
    <React.Fragment>
      <section className="special_container">
      <div className="container termss">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h1Terms">Refund and Cancellation Policy</h1>
            {/* <h3 className="h3LegalInformation">Legal Information</h3> */}
            {/* <p className="termsParrafo">Last updated July 31, 2018</p> */}
            <br/>
            <div className="divTerms">
              <p className="termsParrafo">
                Thank you for choosing to be part of our community at Smart Projects LLC, doing business as SprayCare Band("<b>SprayCare”, “we”,
                  “us”, or “our</b>"). We pride ourselves on our product quality with strict quality control checks in place. To provide you with additional peace of mind, we also offer a comprehensive 30 Day Returns Guarantee. Buy with confidence and enjoy your new SprayCare Band.
              </p>
              <br/>
              <p className="termsParrafo"><br />
              </p>
              <h3><b>TABLE OF CONTENTS</b></h3>
              <p className="termsParrafo">
                <br />
                <a href="#1" className="link" style={{textTransform:'uppercase', color : '#2f80ed'}}>1. Money Back Guarantee</a><br />
                <a href="#2" className="link" style={{textTransform:'uppercase', color : '#2f80ed'}}>2. Replacement Warranty</a><br />
                <a href="#3" className="link" style={{textTransform:'uppercase', color : '#2f80ed'}}>3. Warranty Exemptions and Notes</a><br />
                <a href="#4" className="link" style={{textTransform:'uppercase', color : '#2f80ed'}}>4. Special Notes</a><br />
                <a href="#5" className="link" style={{textTransform:'uppercase', color : '#2f80ed'}}>5. Cancellation & Refund</a><br />
                <a href="#6" className="link" style={{textTransform:'uppercase', color : '#2f80ed'}}>6. Returns and Compensation Process</a><br />
              </p>
              <br/>
              <br/>
              <h3 id={1} style={{textTransform:'uppercase'}}><b>
                  1. Money Back Guarantee
                </b></h3>
              <br/>
              <p className="termsParrafo">
              For items, in the unlikely situation that your product is faulty (only under this situation), you can send it back for an item exchange within 30 days of receiving it.
              </p>
              <br/>
              <br/>
              <br/>
              <br/>
                <h3 id={2} style={{textTransform:'uppercase'}}><b>
                    2. Replacement Warranty
                  </b></h3><br />
              <div>
                <p className="termsParrafo">
                Unless otherwise noted on the product page, the default product warranty period is one month starting from the date you receive the goods, during which you are eligible for free repair.

                Any free repair needs to be confirmed with the manufacturer via customer service at <b>hello@spray.care.</b> If the buyer has damaged/misused the item(s), it will not be eligible for free repair, however, customers can return it at their own cost and pay a fee for the repair. Return shipping fees will be the customers responsibility in such cases.
                Product must arrive to our location in resalable condition. Please note that if the return is not in it's original packaging, there will be a 30% restocking fee applied to the return. No defects or damages to product or packaging should be present to obtain a full credit (less shipping and restocking fees).
                </p>
                <br />
                <br />
                <br />
                <br />
              </div>
                <h3 id={3} style={{textTransform: 'uppercase'}}><b>
                3. Warranty Exemptions and Notes
                  </b></h3>
                  <br />
              <div>
                <p className="termsParrafo">
                1. If the customer has damaged/misused the item(s), the product's warranty is immediately rendered void. No compensation is available in such cases. However, customers are welcome to contact us to purchase a replacement or spare parts (if applicable). We will charge the original value of the components and a shipping fee to dispatch them.
                </p>
                <br />
                <p className="termsParrafo">
                2. The customer voids the warranty if they :
                <br />
                &nbsp;&nbsp; >>&nbsp;Flash the firmware of a device or root a device
                <br />
                &nbsp;&nbsp; >>&nbsp;Open the body in an attempt to fix the device
                <br />
                &nbsp;&nbsp; >>&nbsp;Modify, remove, customize, or swap parts of the product
                <br />
                &nbsp;&nbsp; >>&nbsp;Use the device in a way that it is not originally intended for
                <br />
                &nbsp;&nbsp; >> Continue to use the item once a fault occurs and causes more damage
                </p>
                <br />
                <p className="termsParrafo">
                3. All returns must first be authorized by SprayCare's Support Team prior to return. Please kindly contact us before return items at <b>hello@spray.care.</b> For incomplete warranty requests, SprayCare reserves the right to refuse any compensation. If the customer has returned the package without prior authorization, sends to the wrong address, returns an incorrect item, or submits an empty package, then again SprayCare reserves the right to refuse any compensation.
                </p>
                <br />
                <p className="termsParrafo">
                4. All returns will be inspected by our technical team upon arrival. If the returned item cannot be repaired, SprayCare will offer an alternative solution.
                </p>
                <br />
                <br />
                <br />
                <br />
              </div>
                <h3 id={4} style={{textTransform: 'uppercase'}}><b>
                    4. Special Notes
                  </b></h3>
                  <br />
                <div>
                  <p className="termsParrafo">
                  1. The shipping fee for returning the product back will be paid by the customer and is non-refundable if the customer damaged the products and the products are not faulty.
                  </p>
                  <br />
                  <p className="termsParrafo">
                  2. During the return shipping, the customer is responsible for any and all customs charges, duties or tariffs during the items return to our warehouse. We do not charge any tax fees.
                  </p>
                  <br />
                  <p className="termsParrafo">
                  3. In case of any misuse, any and all repairs, accessories, are fully the customer’s responsibility and are at their own expense. We will cover the re-shipping cost and offer free shipment.
                  </p>
                  <br />
                  <p className="termsParrafo">
                  4. Customers will only be charged once at most for shipping costs (this includes returns if it's buyer's fault); No restocking fee to be charged to the consumers for the return of a product.
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
                <h3 id={5} style={{textTransform: 'uppercase'}}><b>
                    5. Cancellation & Refund
                  </b></h3>
                  <br />
              <div>
                <p className="termsParrafo">
                SprayCare accepts order cancellation before the product is committed to production. If the order is cancelled you will get full refund. We cannot cancel the order if the product has already been committed to production.
                </p>
              </div>
              <br />
              <br />
              <br />
              <br />
                <h3 id={6} style={{textTransform: 'uppercase'}}><b>
                6. Returns and Compensation Process
                  </b></h3>
                  <br />
              <div>
              <p className="termsParrafo">
              If you have any sales or product issues, please contact our customer service at <b>hello@spray.care</b> directly and they will offer you a solution according to your case.
              </p>
              <br />
              <p className="termsParrafo">
              1. For exchanges, the processing time for the exchanged item will be between 6-10 business days, upon receiving your item back, and depending on stock availability.
              </p>
              <br />
              <p className="termsParrafo">
              2. For refunds, the processing time for us to proceed with the refund will be between 1-3 business days upon receiving your returned item. After this the refund time will depend on the payment method. Please see below for details:
              <br />
                &nbsp;&nbsp; >>&nbsp;PayPal refunds may take up to 48 hours to process and appear in your account.
              <br />
                &nbsp;&nbsp; >>&nbsp;Credit card refunds will take between 1-3 business days to appear in your account. This delay depends entirely on your credit card company and any intermediaries.
              </p>
              <br />
              <p className="termsParrafo">
              3. For repairs, it will take between 10-15 business days depending on the item and the manufacturers stock of spare parts.
              </p>
              <br/>
              <br/>
              <br/>
              <br/>
              <p className="termsParrafo">
              If you still need assistance, please feel free to contact us at hello@spray.care. We will do our very best to help you.
              </p>
              </div>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <h2 className="h3Footer">Copyright © 2023 Smart Projects. All rights reserved. SprayCare Band ™</h2>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <a href="/">
          <button className="largeButtonOrder">HOME</button>
        </a>
      </section>
    </React.Fragment>
  );
};

export default Refund;
