import React, { useState, useEffect } from 'react';
import { getCookie } from '../../services/CookiesServices';
import generateRequest from '../../services/RequestService';
import Swal from 'sweetalert2';

const ShippingMethodComponent = ({
  changeScreen,
  data,
  shipping,
  setNewShipping,
  getData,
}) => {

  const [shippingInfo, setShippingInfo] = useState()
  const [shippingPrice, setShippingPrice] = useState()
  const [checked, setChecked] = useState(false);
  const [disableContinuePayment, setDisableContinuePayment] = useState(true);
  const [alertMessage, setAlertMessage] = useState(false);


  const next = () => {
    changeScreen({ id: 1, type: 'NEXT' });
    getData({ id: 1 });
  };
  const previus = () => {
    changeScreen({ id: 1, type: 'PREVIUS' });
  };
  const pleaseAcceptRefundTerms = () => {
    setAlertMessage(true)
    const timeout = setTimeout(() => {
      setAlertMessage(false);
    }, 3000);

   return () => clearTimeout(timeout);
  }

  useEffect(() => {
    if(shipping === 0 || shipping === '0'){
      setShippingInfo('Standard Shipping (20 Days)')
      setShippingPrice('Free')
    }else {
      setShippingInfo('Standard Shipping (20 Days)')
      setShippingPrice('$' + shipping.toFixed(2))
    }
  }, [])

  return (
    <React.Fragment>
      <div className="tabs_checkout">
        <div >
          <div>
            <h4>Contact</h4>
          </div>
          <div >
            <p>{data.email}</p>
          </div>
          <div className="tabs_checkout__first-child">
            <a href="#" onClick={previus}>
              CHANGE
            </a>
          </div>
        </div>
        <div>
          <div>
            <h4>Ship to</h4>
          </div>
          <div>
            <p>{data.address}</p>
          </div>
          <div className="tabs_checkout__first-child">
            <a href="#" onClick={previus}>
              CHANGE
            </a>
          </div>
        </div>
      </div>
      <div className="shipping_method">
        <h4>Shipping Method</h4>
        <div className="tabs_checkout tab_form">
          <div>
            <div>
              <input
                type="radio"
                name="shipping_selected"
                value="0"
                defaultChecked
              />
            </div>
            <div>
              <p>{shippingInfo}</p>
            </div>
            <div>
              <span><strong>{shippingPrice}</strong></span>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <label className={'checkout-label'}>
        <span>I accept the <a style={{color : '#2f80ed'}} target='_blank' href="/refund">refund and cancellation policies</a>&nbsp;</span>
        <input
        type="checkbox"
        id="refundPolicy"
        checked={checked}
        onChange={e => {
          setChecked(e.target.checked);
          setDisableContinuePayment(!e.target.checked);
        }}
        />
        <p className={"checkout-label-paragraph--hide"} style={{transform: alertMessage ? 'scaleY(1)' : 'scaleY(0)', top: alertMessage ? '110%' : '100%'}}>Please accept the refund and cancellation policies</p>
        </label>
        <br/>
        <div className="button_form_shipping_container">
          <div>
            <button
              type="button"
              className="largeButtonOrder btn_transparent"
              onClick={previus}>
              RETURN TO CUSTOMER
            </button>
          </div>
          <div>
            <button
            type="button"
            className="largeButtonOrder"
            style={{backgroundColor: disableContinuePayment ? '#676767' : '#2f80ed', cursor: disableContinuePayment ? 'default' : 'pointer'}}
            onClick={disableContinuePayment ? pleaseAcceptRefundTerms : next}
            >
              CONTINUE PAYMENT
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShippingMethodComponent;
