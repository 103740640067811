const questions = [
  { id:"One",
    q:"What is SprayCare?",
    a: "SprayCare is a wrist band with a light-weight liquid hand sanitizer dispenser."
  },
  { id:"Eleven",
    q:"What type of liquids should I use?",
    a: "SprayCare works well with liquid hand sanitizers that consist of 60-80% alcohol. This can be either isopropyl alcohol or denatured ethyl alcohol.Some of our favorites include Bath and Body works anti-bacterial hand spray, Solimo 70% Isopropyl alcohol, and Heritage Distillery 80% denatured ethyl alcohol. Please do not use with any solvents (ethyl acetate, menthyl acetate, acetaldehyde, methanol, keytones (acetone), and others). Please refer to the user manual for a list of other liquids that could damage the atomizer."
  },
  { id:"Two",
    q:"How many times can I use SprayCare before it has to be refilled?",
    a: "SprayCare has the capacity for about 50 applications of 2 seconds before it needs to be refilled, but it also depends on the density of the liquid being used."
  },
  { id:"Three",
    q:"What is SprayCare's capacity?",
    a: "5ml"
  },
  { id:"Four",
    q:"How does SprayCare work?",
    a: "The device uses one of the latest generation atomizers to spray disinfectant at the touch of a button. The liquid can be directed onto any surface or into the palm of your hand."
  },
  { id:"Five",
    q:"Can SprayCare be used by children?",
    a: "SprayCare is recommended for kids ages 8 or older. Any SprayCare user must read, understand and follow the safety precautions of hand sanitizers and SprayCare included in our package."
  },
  { id:"Six",
    q:"What makes SprayCare different than regular sprayers?",
    a: "SprayCare uses atomizing technology to improve the coverage when applying disinfectant spray. The sanitizing liquid is electronically dispensed at the touch of a button. No pumping or squeezing is required."
  },
  { id:"Seven",
    q:"Does SprayCare come with a warranty?",
    a: "Yes, SprayCare is covered for 60 days after the date of purchase."
  },
  { id:"Eight",
    q:"Can I use any type of hand sanitizer with SprayCare?",
    a: "SprayCare has been optimized to work with liquid hand sanitizers. Liquids with higher density than water can damage the device and should not be used."
  },
  { id:"Nine",
    q:"How long do the batteries last?",
    a: "The 60mAh rechargeable lithium-ion battery can provide enough for a refill. It takes about 50 minutes to fully recharge it."
  },
  { id:"Ten",
    q:"Does SprayCare meet the requirements of the Consumer Product Safety Commission?",
    a: "CE, RoHS, FCC, PSE certifications."
  },
]

export default questions;
