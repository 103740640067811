import React from 'react';
import { ManualES, ManualEN, ManualJP } from '../../components/imagesComponents';
import { ReactComponent as ManualIcon } from '../../assets/img/download-manual.svg';
import { Link } from "react-router-dom";


const DownloadButton = ({children, pdf}) => {
    return (
    <Link to={pdf ? pdf : "/manual"} target={pdf ? "_blank" : ""}className="download">
        <div className="download-icon">
            <ManualIcon />
        </div>
        <div className="download-text">
            {children}
        </div>
    </Link>);
}


const AboutComponent = () => {
    return (
        <React.Fragment>
            <section className="special_container">
                <h1 className="download-title">Download user manual</h1>
                <div className="downloads">
                    <DownloadButton pdf={ManualJP}>
                        日本語ハンドブック.pdf
                    </DownloadButton>
                    <DownloadButton pdf={ManualES}>
                        Manual Español.pdf
                    </DownloadButton>
                    <DownloadButton pdf={ManualEN}>
                        User Manual English.pdf
                    </DownloadButton>
                </div>
            </section>
        </React.Fragment>
    );
};

export default AboutComponent;
