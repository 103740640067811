import React, { useRef } from "react";
import {
  FeatParts,
  FeatHooks,
  FeatAdvantage,
  StrapAdapter
} from "../../components/imagesComponents";
import IconLED from '../../assets/img/LEDIndicator.svg';
import ScrollAnimation from "react-animate-on-scroll";
import { Link } from "react-router-dom";
import Group_172 from'../../assets/img/Group_172.svg';
import Group_173 from'../../assets/img/Group_173.svg';
import Group_174 from'../../assets/img/Group_174.svg';
import Group_220 from'../../assets/img/Group_220.svg';
import Group_175 from'../../assets/img/Group_175.svg';


function FeatureBox() {
    return (<div className='spraycare-features__features-box spraycare-features__features-box--mobile-visible'>
        <div className='spraycare-features__feature spraycare-features__feature'>
          <img src={Group_172} alt='' className='spraycare-features__img' />
          <div className='spraycare-features__text'>
            <p className='paragraph paragraph--features'>Power button</p>
          </div>
        </div>

        <div className='spraycare-features__feature spraycare-features__feature'>
          <img src={Group_173} alt='' className='spraycare-features__img' />
          <div className='spraycare-features__text'>
            <p className='paragraph paragraph--features'>Atomizer</p>
          </div>
        </div>

        <div className='spraycare-features__feature spraycare-features__feature'>
          <img src={Group_175} alt='' className='spraycare-features__img' />
          <div className='spraycare-features__text'>
            <p className='paragraph paragraph--features'>
              Liquid container
                </p>
          </div>
        </div>

        <div className='spraycare-features__feature spraycare-features__feature'>
          <img src={IconLED} alt='' className='spraycare-features__img' />
          <div className='spraycare-features__text'>
            <p className='paragraph paragraph--features'>LED indicator</p>
          </div>
        </div>

        <div className='spraycare-features__feature spraycare-features__feature'>
          <img src={Group_174} alt='' className='spraycare-features__img' />
          <div className='spraycare-features__text'>
            <p className='paragraph paragraph--features'>USB Port</p>
          </div>
        </div>
        <div className='spraycare-features__feature spraycare-features__feature'>
          <img src={Group_220} alt='' className='spraycare-features__img' />
          <div className='spraycare-features__text'>
            <p className='paragraph paragraph--features'>Refill Port</p>
          </div>
        </div>
      </div>);
}


const SprayCareFeaturesZigZagSection = (props) => {

  const strapAdapterVideo = useRef(null);

  const handlePlayVideo = () => {
    strapAdapterVideo.current.play();
  }
  const handleRestartVideo = () => {
    strapAdapterVideo.current.currentTime = 0;
  }

  return (
    <section className='features' ref={props.featuresRef}>
      <div className='features__wrapper'>
        <div className='features__content'>
          <div className='features__text-box'>
            <ScrollAnimation animateIn='animate__fadeInUp'>
              <h2 className='heading-primary heading-primary--line-left'>
                SprayCare™
              </h2>
              <p className='paragraph'>
                Uses one of the latest generation atomizers to electronically
                spray the liquid at the touch of a button.
              </p>
            </ScrollAnimation>
          </div>
          <div className='features__image-box'>
          </div>
       <FeatureBox></FeatureBox>
        </div>
       <div className='features__content'>
          <div className='features__text-box'>
            <ScrollAnimation animateIn='animate__fadeInUp' animateOut='animate__fadeOut' afterAnimatedIn={handlePlayVideo} afterAnimatedOut={handleRestartVideo}>
              <h2 className='heading-primary heading-primary--line-left'>
                It can be attached&nbsp;to your smartwatch strap
              </h2>
              <p className='paragraph'>
                Smartwatch bands, purses, handbags or
                backpacks.
              </p>

              {/*<a href='/strap-adapter' className="btn btn--order-now">*/}
              {/*  ORDER ADAPTER*/}
              {/*</a>*/}
            </ScrollAnimation>
          </div>
          <div className='features__image-box'>
            <video
              ref={strapAdapterVideo}
              muted
              playsInline
            >
              <source src={StrapAdapter} type='video/mp4' />
              <img src={FeatHooks} alt={""} />
            </video>
          </div>
        </div>
        <div className='features__content'>
          <div className='features__text-box'>
            <ScrollAnimation animateIn='animate__fadeInUp'>
              <h2 className='heading-primary heading-primary--line-left'>
                Elegant and
                <br />
                Convinient
              </h2>
              <p className='paragraph'>
                Our team placed great emphasis on the product design to make it
                an elegant alternative to carrying cumbersome bottles of hand
                sanitizer.
              </p>
            </ScrollAnimation>
          </div>
          <div className='features__image-box'>
            <img src={FeatAdvantage} alt={""} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SprayCareFeaturesZigZagSection;
