import React, { useState } from "react";
import ReactGA from "react-ga";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import ScrollLink from "../ScrollLink";

function LineKickStarter({ id, noAnalytics }) {
  const [warningBand, setWarningBand] = useState(true)
  const ClickHandler = () => {
    ReactGA.event({
      category: "Button",
      action: "User pressed kickStarter button",
    });

    window.open("https://spray.care/product", "_blank");
  };

  return (
    <>
      {noAnalytics ? (
        warningBand && <div id={id}>
          <div>
            <span style={{ color: "black" }}>
                Please see some of the products that work well with the
                SprayCare Band {" "}
            </span>
            <ScrollLink style={{ color: "black" }} className="spraycare-modal-link" to={"/troubleshooting"} hash={"#favoritesSprays"}>Read More</ScrollLink>
          </div>
          <button
            className="close-btn"
            onClick={() => {
              setWarningBand(false)
            }}
          >
            <Cross />
          </button>
        </div>
      ) : (
        <button onClick={ClickHandler} id={id}>
          <div>
            <p>
              <b style={{ color: "white" }}>SALE 30% OFF</b>
            </p>
          </div>
        </button>
      )}
    </>
  );
}

export default LineKickStarter;

