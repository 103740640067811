import React, { useState, useEffect } from "react";
import CheckCircle from '../../assets/img/bx_bx-check-circle.svg';
import SpinnerComponent from "../../components/spinner";
import { URL } from "../../services/conection";
import "../../includes/bootstrap";
import { BackerFormData } from "./BackerFormData";
import { getStates, getCountryAbbreviation, getStateAbbreviation } from "../../services/States";
import RewardItems from "./RewardItems";
import generateRequest from "../../services/RequestService";
import { toWordsOrdinal } from "number-to-words";
import { Link } from "react-router-dom";
import {
  encryptInformation,
  dcryptInformation,
} from "../../services/EncryptService";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
  });
};

const Survey = () => {

  const [backerNumber, setBackerNumber] = useState("");
  const [backerEmail, setBackerEmail] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [editDisabled, setEditDisabled] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [disableSurvey, setDisableSurvey] = useState(false);
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState("");
  const [arrayStates, setArrayStates] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [apartment, setApartment] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [stateName, setStateName] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [backerDevices, setBackerDevices] = useState(0);
  const [backerDevicesObject, setBackerDevicesObject] = useState({});
  const [displayAlert, setDisplayAlert] = useState(false);
  const [message, setMessage] = useState(false);
  const [backerId, setBackerId] = useState("");
  const [processing, setProcessing] = useState(false);
  const [surveySent, setSurveySent] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");

  const fireMessage = (m) => {
    setDisplayAlert(true);
    setMessage(m);
    const timeout = setTimeout(() => {
      setDisplayAlert(false);
    }, 3000);

    return () => clearTimeout(timeout);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (backerEmail !== "" && backerNumber !== "") {
      setProcessing(true);
      generateRequest(
        "api/user/backers-survey",
        "POST",
        { backerEmail, backerNumber },
        true
      )
        .then(({ isExist, code, orderedDevices, idUser, message, surveyData }) => {
          if (isExist) {
            if (code === 200) {
              const deviceObject = {};
              for (let i = 0; i < orderedDevices; i++) {
                deviceObject[toWordsOrdinal(i + 1) + "Wristband"] = "BLACK";
              }
              setShowSurvey(true);
              setBackerDevices(orderedDevices);
              setBackerDevicesObject(deviceObject);
              setProcessing(false);
              setBackerId(encryptInformation(idUser));
            } else if (code === 100) {

              const {
                country,
                first_name,
                last_name,
                address,
                city,
                apartment,
                state,
                zip_code,
                phone,
                items_quantity,
                item_colors,
              } = surveyData;

              setProcessing(false);
              setShowSurvey(true);
              setEditDisabled(true);
              setCountry(getCountryAbbreviation(country));
              setFirstName(first_name);
              setLastName(last_name);
              setAddress(address);
              setApartment(city);
              setCity(apartment);
              setArrayStates(getStates(getCountryAbbreviation(country)));
              setCountryName(country);
              setState(getStateAbbreviation(getStates(getCountryAbbreviation(country)), state));
              setStateName(state);
              setZip(zip_code);
              setPhone(phone);
              setBackerDevices(items_quantity);
              setBackerDevicesObject(item_colors);
            } else if (code === 101) {
              const {
                country,
                first_name,
                last_name,
                address,
                city,
                apartment,
                state,
                zip_code,
                phone,
                items_quantity,
                item_colors,
              } = surveyData;

              setProcessing(false);
              setShowSurvey(true);
              setDisableSurvey(true);
              setEditDisabled(true);
              setCountry(getCountryAbbreviation(country));
              setFirstName(first_name);
              setLastName(last_name);
              setAddress(address);
              setApartment(city);
              setCity(apartment);
              setArrayStates(getStates(getCountryAbbreviation(country)));
              setCountryName(country);
              setState(getStateAbbreviation(getStates(getCountryAbbreviation(country)), state));
              setStateName(state);
              setZip(zip_code);
              setPhone(phone);
              setBackerDevices(items_quantity);
              setBackerDevicesObject(item_colors);
              setDisplayMessage(message);
            } else {
              setProcessing(false);
              fireMessage(message);
            }
          } else {
            setProcessing(false);
            fireMessage(
              "The email or backer number does not seem to match, please try again"
            );
          }
        })
        .catch((e) => console.log(e, message));
    }
  };

  const handleSurveySubmit = async (event) => {
    event.preventDefault();

    scrollToTop();
    setProcessing(true);

    let object = {
      id_backer: dcryptInformation(backerId),
      email: backerEmail,
      backer_number: backerNumber,
      country: countryName,
      first_name: firstName,
      last_name: lastName,
      address: address,
      city: city,
      apartment: apartment,
      state: stateName,
      zip_code: zip,
      phone: phone,
      items_quantity: backerDevices,
      item_colors: backerDevicesObject,
    };

    let sendSurvey = await fetch(`${URL}api/user/survey`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    });

    let res = await sendSurvey.json();

    if (res) {
      if (res.code === 200) {
        setSurveySent(true);
        setProcessing(false);
      }
      if (res.code === 403) {
        setProcessing(false);
      }
    } else {
      console.log("Your request could not be completed");
    }
  };

  const handleUpdateSurveySubmit = async (event) => {
    event.preventDefault();

    scrollToTop();
    setProcessing(true);

    let object = {
      // id_backer: dcryptInformation(backerId),
      email: backerEmail,
      backer_number: backerNumber,
      country: countryName,
      first_name: firstName,
      last_name: lastName,
      address: address,
      city: city,
      apartment: apartment,
      state: stateName,
      zip_code: zip,
      phone: phone,
    };

    let sendSurvey = await fetch(`${URL}api/user/update-survey`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(object),
    });

    let res = await sendSurvey.json();

    if (res) {
      if (res.code === 200) {
        setSurveySent(true);
        setProcessing(false);
      }
      if (res.code === 403) {
        setProcessing(false);
      }
    } else {
      console.log("Your request could not be completed");
    }
  };

  const onHandleBackerNumber = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setBackerNumber(e.target.value);
      setDisabled(!(backerEmail && e.target.value) ? true : false);
    }
  };
  const onHandleBackerEmail = (e) => {
    const re =
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setBackerEmail(e.target.value);
    if (e.target.value === "" || re.test(e.target.value)) {
      setDisabled(!(e.target.value && backerNumber) ? true : false);
    } else setDisabled(!(false && backerNumber) ? true : false);
  };

  return (
    <React.Fragment>
      <section className="special_container survey-ctnr">
        <h1 onClick={() => console.log(backerEmail)}>
          {surveySent ? "Survey sent successfully" : "Filling out our survey"}
        </h1>
        <br />
        <br />
        {surveySent && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <img src={CheckCircle} alt="" style={{ width: "12rem" }} />
          </div>
        )}
        <p className="paragraph paragraph--tertiary">
          {surveySent
            ? "We have sent you the survey with your answers to your email address"
            : "The SprayCare Band campaign has been a success, thanks to you!"}
          <br />
          {surveySent
            ? "You can come back and change your address if you wish during the next 20 days"
            : "Now we need some information from you to deliver your reward."}
        </p>
        {surveySent && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Link
              to="/"
              className="largeButtonOrder"
              style={{ maxWidth: "650px" }}
            >
              <button type="button" className="largeButtonOrder">
                BACK HOME
              </button>
            </Link>
          </div>
        )}
        {!surveySent &&
          (showSurvey ? (
            processing ? (
              <div style={{ height: "40rem", position: "relative" }}>
                <SpinnerComponent color="#ffffff" height="98%" />
              </div>
            ) : (
              <>
                <div>
                  <form
                    className={`forms ${disableSurvey ? "form--disabled" : ""}`}
                    onSubmit={
                      editDisabled
                        ? handleUpdateSurveySubmit
                        : handleSurveySubmit
                    }
                    style={{ width: "100%", maxWidth: "650px" }}
                    id="Survey"
                  >
                    {disableSurvey &&
                    <>
                    <h3 style={{color: "#2F80ED"}}>
                      {displayMessage}
                    </h3>
                    <br/>
                    <br/>
                    </>
                    }
                    {disableSurvey && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          opacity: "0.5",
                          zIndex: 2000,
                        }}
                      ></div>
                    )}
                    <div>
                      <select
                        onChange={(e) => {
                          let statesArray = getStates(e.target.value);
                          if (statesArray) {
                            setArrayStates(getStates(e.target.value));
                          }
                          setCountry(e.target.value);
                          setCountryName(
                            e.target.options[e.target.selectedIndex].text
                          );
                        }}
                        value={country}
                        disabled={disableSurvey}
                        required
                      >
                        <option value="">Select country</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="BH">Bahrain</option>
                        <option value="BE">Belgium</option>
                        <option value="BR">Brazil</option>
                        <option value="KH">Cambodia</option>
                        <option value="CA">Canada</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CO">Colombia</option>
                        <option value="HR">Croatia</option>
                        <option value="DK">Denmark</option>
                        <option value="EE">Estonia</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="DE">Germany</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JP">Japan</option>
                        <option value="MY">Malaysia</option>
                        <option value="MX">Mexico</option>
                        <option value="MD">Moldova</option>
                        <option value="NL">Netherlands</option>
                        <option value="PH">Philippines</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="QA">Qatar</option>
                        <option value="IE">Republic of Ireland</option>
                        <option value="RU">Russia</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SG">Singapore</option>
                        <option value="SI">Slovenia</option>
                        <option value="ZA">South Africa</option>
                        <option value="KR">South Korea</option>
                        <option value="ES">Spain</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="TW">Taiwan</option>
                        <option value="TH">Thailand</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                      </select>
                    </div>
                    <div>
                      <div className="forms-multiple_fields forms_padding_fields">
                        <input
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                          disabled={disableSurvey}
                          required
                        />
                        <label placeholder="First name"></label>
                      </div>
                      <div className="forms-multiple_fields">
                        <input
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          disabled={disableSurvey}
                          required
                        />
                        <label placeholder="Last name"></label>
                      </div>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="address"
                        placeholder="Address"
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        disabled={disableSurvey}
                        required
                      />
                      <label placeholder="Address"></label>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="city"
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        disabled={disableSurvey}
                        required
                      />
                      <label placeholder="City"></label>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="apartment"
                        placeholder="Apartment, suite, etc."
                        value={apartment}
                        onChange={(e) => setApartment(e.target.value)}
                        disabled={disableSurvey}
                      />
                      <label placeholder="Apartment, suite, etc."></label>
                    </div>
                    <div>
                      <select
                        onChange={(e) => {
                          setState(e.target.value);
                          setStateName(
                            e.target.options[e.target.selectedIndex].text
                          );
                        }}
                        value={state}
                        required
                        disabled={disableSurvey}
                      >
                        <option value="">Select state</option>
                        {arrayStates.map((val, i) => (
                          <option key={i} value={val.abbreviation}>
                            {val.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <div className="forms-multiple_fields forms_padding_fields">
                        <input
                          type="text"
                          name="zip"
                          placeholder="Zip code"
                          required
                          value={zip}
                          onChange={(e) => setZip(e.target.value)}
                          disabled={disableSurvey}
                        />
                        <label placeholder="Zip code"></label>
                      </div>
                      <div className="forms-multiple_fields">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Phone"
                          required
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          disabled={disableSurvey}
                        />
                        <label placeholder="Phone"></label>
                      </div>
                    </div>
                  </form>
                </div>
                <br />
                <br />
                <div
                  className={`${editDisabled ? "rewardItemsCtnrDisable" : ""}`}
                >
                  {editDisabled && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        opacity: "0.5",
                        zIndex: 2000,
                      }}
                    ></div>
                  )}

                  {editDisabled
                    ? [...Array(backerDevices)].map((_e, k) => (
                        <RewardItems
                          key={k}
                          deviceNumber={toWordsOrdinal(k + 1)}
                          group={k}
                          backerDevicesObject={backerDevicesObject}
                          setBackerDevicesObject={setBackerDevicesObject}
                          disabled={editDisabled}
                          editDisabled={editDisabled}
                          filled={Object.values(backerDevicesObject)[k]}
                        />
                      ))
                    : [...Array(backerDevices)].map((_e, k) => (
                        <RewardItems
                          key={k}
                          deviceNumber={toWordsOrdinal(k + 1)}
                          group={k}
                          backerDevicesObject={backerDevicesObject}
                          setBackerDevicesObject={setBackerDevicesObject}
                        />
                      ))}
                </div>
                <br />
                <br />
                <div
                  style={{
                    maxWidth: "650px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  {disableSurvey ? (
                    <Link
                      to="/"
                      className="largeButtonOrder"
                      style={{ maxWidth: "650px" }}
                    >
                      <button type="button" className="largeButtonOrder">
                        BACK HOME
                      </button>
                    </Link>
                  ) : (
                    <button
                      type="submit"
                      className="largeButtonOrder"
                      form="Survey"
                    >
                      {editDisabled ? "UPDATE DATA" : "SEND SURVEY"}
                    </button>
                  )}
                </div>
              </>
            )
          ) : (
            <BackerFormData
              backerNumber={backerNumber}
              backerEmail={backerEmail}
              setBackerEmail={setBackerEmail}
              disabled={disabled}
              handleSubmit={handleSubmit}
              onHandleBackerNumber={onHandleBackerNumber}
              onHandleBackerEmail={onHandleBackerEmail}
              displayAlert={displayAlert}
              message={message}
              processing={processing}
            />
          ))}
      </section>
    </React.Fragment>
  );
};

export default Survey;
