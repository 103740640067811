import React, { useState, useEffect } from 'react';
import TableCarComponent from './TableCarComponent';
import { getCookie, deleteCookieData,getNumberDevices } from '../../services/CookiesServices';
const ShoppingCarComponent = () => {
  const [devices, setDevices] = useState([]);
  const [subtotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingCost, setShippingCost] = useState(0.00);

  useEffect(() => {
    let deviceOrder = getCookie('device_order');
    let backerStored = getCookie('backer_validation');

    if (backerStored !== '') {
      setShippingCost(0);
    }
    if (deviceOrder !== '') {
      let deviceParser = JSON.parse(deviceOrder);
      let subtotalLocal = 0;
      for (let order of deviceParser) subtotalLocal += parseFloat(order.total);
      setSubTotal(subtotalLocal);
      setTotal(subtotalLocal + (backerStored !== '' ? 0.00 : 0.00));
      setDevices(deviceParser);
    }
  }, []);
  const updatePrice = (e) => {
    setSubTotal(e);
    setTotal(e + shippingCost);
  };

  const validationCart = () => {
    if (devices.length > 0) {
      return (
        <>
          <div className="table_container">
            <table id="table_car">
              <thead className="u-sm-device-hide">
                <tr>
                  <th className="display_normal"></th>
                  <th></th>
                  <th className="u-sm-device-hide">Product</th>
                  <th className="textRighMobile u-sm-device-hide">Price</th>
                  <th className="textAlignCenterClass display_normal">Quantity</th>
                  <th className="textAlignRightClass display_normal">Total</th>
                </tr>
              </thead>
              <tbody>
                {devices.map((device, index) => (
                  <TableCarComponent
                    key={device.id}
                    {...device}
                    total_checkout={subtotal}
                    update_subtotal={(e) => {
                      updatePrice(e);
                    }}
                    deleteElement={(e) => {
                      let newDevices = deleteCookieData(e.id);
                      setSubTotal(Math.abs(subtotal - e.total));
                      setTotal(total - e.total);
                      setDevices(newDevices);
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div id="subtotal_container">
            <table>
              <tbody>
                <tr>
                  <td className="subtotal_container__td-left">
                    <strong>Cost of shipping</strong>
                  </td>
                  <td className="textRighMobile">${shippingCost}</td>
                </tr>
                <tr>
                  <td className="subtotal_container__td-left">
                    <strong>Subtotal</strong>
                  </td>
                  <td className="textRighMobile">
                    $<span>{subtotal.toFixed(2)}</span>
                  </td>
                </tr>
                <tr>
                  <td className="subtotal_container__td-left">
                    <strong style={{ color: '#2F80ED' }}>Total</strong>
                  </td>
                  <td className="textRighMobile">
                    $<span>{total.toFixed(2)}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="button_container">
            <ul>
              <li>
                <a href="product">
                  <button className="normalButton btn_transparent">
                    CONTINUE SHOPPING
                  </button>
                </a>
              </li>
              <li>
                <a href="checkout">
                  <button className="normalButton" id="checkoutButton">
                    CHECK OUT
                  </button>
                </a>
              </li>
            </ul>
          </div>
        </>
      );
    } else {
      return (
        <div>
          <h1 style={{ textAlign: 'center' }}>You don't have any product </h1>
        </div>
      );
    }
  };
  return (
    <React.Fragment>
      <section className="special_container shopping_car_container">
        <h1>Your cart</h1>
        {validationCart()}
      </section>
    </React.Fragment>
  );
};

export default ShoppingCarComponent;
