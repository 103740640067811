import React, { useState, useEffect } from 'react';

import AddressComponent from '../../components/AddressComponent';
import { URL } from '../../services/conection';
import { getStates } from '../../services/States';
import generateRequest from '../../services/RequestService';
import OrdersComponent from './OrdersComponent';
import $ from 'jquery';
import Swal from 'sweetalert2';
const DashboardComponent = () => {
  const [userName, setUserName] = useState('');
  //Variables para crear una nueva direccion
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [apartment, setApartment] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [arrayStates,setArrayStates] = useState([]);
  const [arrayStatesU,setArrayStatesU] = useState([]);
  //const [province, setProvince] = useState('');
  const [state,setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  //Variables para actualizar direccion
  const [firstNameU, setFirstNameU] = useState('');
  const [lastNameU, setLastNameU] = useState('');
  const [addressU, setAddressU] = useState('');
  const [apartmentU, setApartmentU] = useState('');
  const [cityU, setCityU] = useState('');
  const [countryU, setCountryU] = useState('');
  //const [provinceU, setProvinceU] = useState('');
  const [stateU,setStateU] = useState('');
  const [zipU, setZipU] = useState('');
  const [phoneU, setPhoneU] = useState('');
  const [id, setId] = useState('');

  const [addresses, setAddresses] = useState([]);
  const [orders,setOrders] = useState([]);

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      generateRequest('api/address/get-all-address','GET',{},true).then((json) =>{
        if (json.address.length > 0) setAddresses(json.address);
        setUserName(json.user.name);
      }).catch(e => console.log("error"))

      generateRequest('api/user/order-history','GET',{},true).then((value) =>{
        setOrders(value.orders);
      }).catch(e => console.log("error"))
    }
  }, []);

  const createAddress = async (e) => {
    e.preventDefault();
    let object = {
      first_name: firstName,
      last_name: lastName,
      address,
      apartment,
      city,
      country,
      state,
      zip,
      phone,
    };

    let token = localStorage.getItem('token');
    let request = await fetch(`${URL}api/address/create-address`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token ? token : ''}`,
      },
      body: JSON.stringify(object),
    });

    let resp = await request.json();
    let addressesLocal = addresses;
    let newArray = addressesLocal.concat(resp.address);
    $('#largeModal').modal('hide');
    setLastName('');
    setFirstName('');
    setAddress('');
    setApartment('');
    setCity('');
    setCountry('');
    setState('');
    setZip('');
    setPhone('');
    setAddresses(newArray);
    setArrayStates([])
  };
  const openModalUpdate = (e) => {
    setFirstNameU(e.firstname);
    setLastNameU(e.lastname);
    setAddressU(e.address);
    setZipU(e.zip);
    setCityU(e.city);
    setStateU(e.state);
    setCountryU(e.country);
    setApartmentU(e.apartment);
    setPhoneU(e.phone);
    setId(e._id);
    setArrayStatesU(getStates(e.country));
    console.log(e.stateU)
    $('#largeModal2').modal('show');
  };
  const updateAddress = async (e) => {
    e.preventDefault();
    let object = {
      first_name: firstNameU,
      last_name: lastNameU,
      address: addressU,
      apartment: apartmentU,
      city: cityU,
      country: countryU,
      state: stateU,
      zip: zipU,
      phone: phoneU,
      id,
    };

    let token = localStorage.getItem('token');
    let request = await fetch(`${URL}api/address/update-address`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token ? token : ''}`,
      },
      body: JSON.stringify(object),
    });

    let resp = await request.json();
    if (resp.code === 200) {
      let addressLocal = addresses;
      let index = addressLocal.findIndex((element) => element._id === id);
      Object.assign(addressLocal[index], resp.address);
      setAddresses([...addressLocal]);
      $('#largeModal2').modal('hide');
    } else Swal.fire({ type: 'error', title: resp.message });
  };

  const deleteAddress = async (e) => {
    let object = { id: e };
    let token = localStorage.getItem('token');
    let request = await fetch(`${URL}api/address/delete-address`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token ? token : ''}`,
      },
      body: JSON.stringify(object),
    });
    let resp = await request.json();
    if (resp.code === 200) {
      let addressLocal = addresses;
      let index = addressLocal.findIndex((element) => element._id === e);
      if (index > -1) addressLocal.splice(index, 1);
      setAddresses([...addressLocal]);
    } else {
      Swal.fire({
        type: 'error',
        title: 'Error when address deleted',
      });
    }
  };
  return (
    <React.Fragment>
      <section className="special_container">
        <h1>Hello! {userName}</h1>
        <div id="dashboard_container" className="dashboard_container">
          <div className="dashboard_container__first_element">
            <h6>Order history</h6>
            <div className="dashboard_container__order-list">
              {(orders.length === 0 || orders === undefined) ?(<p>You haven't place any orders yet.</p>):orders.map((order,i) => (<OrdersComponent key={i} {...order}/>))}
            </div>
          </div>
          <div>
            <h6>Account details</h6>
            {addresses.length === 0?(<p style={{marginBottom:"10px"}}>You don't have any address</p>):(<div id="address_container">
              {addresses.length > 0 ? addresses.map((item, index) => (
                <AddressComponent
                  key={item?._id}
                  {...item}
                  openModal={(e) => openModalUpdate(e)}
                  deleteAddress={deleteAddress}
                />
              )) : null}
            </div>)}

            <button
              className="normalButton"
              data-toggle="modal"
              data-target="#largeModal">
              ADD ADDRESS
            </button>
          </div>
        </div>
      </section>
      <div
        id="largeModal"
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <h5 style={{ textAlign: 'center', marginTop: '30px' }}>
              Add new Address
            </h5>
            <form className="forms" onSubmit={createAddress}>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  <label placeholder="First name"></label>
                </div>
                <div className="forms-multiple_fields">
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                  <label placeholder="Last name"></label>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
                <label placeholder="Address"></label>
              </div>
              <div>
                <input
                  type="text"
                  name="apartment"
                  placeholder="Apartment, suite, etc."
                  value={apartment}
                  onChange={(e) => setApartment(e.target.value)}

                />
                <label placeholder="Apartment, suite, etc."></label>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <select
                    onChange={(e) => {
                      let stateArray = getStates(e.target.value);
                      if(stateArray) setArrayStates(getStates(e.target.value));
                      setCountry(e.target.value)
                    }}
                    value={country}
                    required>
                    <option value="">Select your country</option>
                    <option value="US">United States</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="BH">Bahrain</option>
                    <option value="BE">Belgium</option>
                    <option value="BR">Brazil</option>
                    <option value="KH">Cambodia</option>
                    <option value="CA">Canada</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CO">Colombia</option>
                    <option value="HR">Croatia</option>
                    <option value="DK">Denmark</option>
                    <option value="EE">Estonia</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JP">Japan</option>
                    <option value="MY">Malaysia</option>
                    <option value="MX">Mexico</option>
                    <option value="MD">Moldova</option>
                    <option value="NL">Netherlands</option>
                    <option value="NZ">New Zealand</option>
                    <option value="PH">Philippines</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="QA">Qatar</option>
                    <option value="IE">Republic of Ireland</option>
                    <option value="RU">Russia</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SG">Singapore</option>
                    <option value="SI">Slovenia</option>
                    <option value="ZA">South Africa</option>
                    <option value="KR">South Korea</option>
                    <option value="ES">Spain</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="TW">Taiwan</option>
                    <option value="TH">Thailand</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                  </select>
                </div>
                <div className="forms-multiple_fields">
                  <select
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    required>
                    <option value="">Select your state</option>
                    {arrayStates.map((val,i) => (<option key={i} value={val.abbreviation}>{val.name}</option>))}
                  </select>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  required
                />
                <label placeholder="City"></label>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <input
                    type="text"
                    name="zip"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                    placeholder="Zip code"
                    required
                  />
                  <label placeholder="Zip code"></label>
                </div>
                <div className="forms-multiple_fields">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  <label placeholder="Phone"></label>
                </div>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <button
                    type="button"
                    className="largeButtonOrder btn_transparent"
                    data-dismiss="modal">
                    CANCEL
                  </button>
                </div>
                <div className="forms-multiple_fields">
                  <button type="submit" className="largeButtonOrder">
                    ADD ADDRESS
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        id="largeModal2"
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <h5 style={{ textAlign: 'center', marginTop: '30px' }}>
              Update Address
            </h5>
            <form className="forms" onSubmit={updateAddress}>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First name"
                    value={firstNameU}
                    onChange={(e) => setFirstNameU(e.target.value)}
                    required
                  />
                  <label placeholder="First name"></label>
                </div>
                <div className="forms-multiple_fields">
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                    value={lastNameU}
                    onChange={(e) => setLastNameU(e.target.value)}
                    required
                  />
                  <label placeholder="Last name"></label>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="address"
                  placeholder="Address"
                  value={addressU}
                  onChange={(e) => setAddressU(e.target.value)}
                  required
                />
                <label placeholder="Address"></label>
              </div>
              <div>
                <input
                  type="text"
                  name="apartment"
                  placeholder="Apartment, suite, etc."
                  value={apartmentU}
                  onChange={(e) => setApartmentU(e.target.value)}

                />
                <label placeholder="Apartment, suite, etc."></label>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <select
                    onChange={(e) => setCountryU(e.target.value)}
                    value={countryU}
                    required>
                    <option value="">Select your country</option>
                    <option value="US">United States</option>
                  </select>
                </div>
                <div className="forms-multiple_fields">
                  <select
                    onChange={(e) => setStateU(e.target.value)}
                    value={stateU}
                    required>
                    <option value="">Select your state</option>
                    {arrayStatesU.map((val,i) => (<option key={i} value={val.abbreviation}>{val.name}</option>))}
                  </select>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={cityU}
                  onChange={(e) => setCityU(e.target.value)}
                  required
                />
                <label placeholder="City"></label>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <input
                    type="text"
                    name="zip"
                    placeholder="Zip code"
                    value={zipU}
                    onChange={(e) => setZipU(e.target.value)}
                    required
                  />
                  <label placeholder="Zip code"></label>
                </div>
                <div className="forms-multiple_fields">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={phoneU}
                    onChange={(e) => setPhoneU(e.target.value)}
                    required
                  />
                  <label placeholder="Phone"></label>
                </div>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <button
                    type="button"
                    className="largeButtonOrder btn_transparent"
                    data-dismiss="modal">
                    CANCEL
                  </button>
                </div>
                <div className="forms-multiple_fields">
                  <button type="submit" className="largeButtonOrder">
                    SAVE CHANGES
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardComponent;
