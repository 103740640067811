import React from "react";
import {
 Elements
} from "@stripe/react-stripe-js";

import {loadStripe} from "@stripe/stripe-js";
import CheckoutPaymentComponent from "./CheckoutPaymentComponent";


const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);





const CheckoutForm = ({changeScreen}) => {




  return (

      <Elements stripe={stripePromise}>
        <CheckoutPaymentComponent changeScreen={changeScreen}/>
      </Elements>

  );
};

export default CheckoutForm;
