import React, { useState, useRef, useEffect, useContext } from "react";
import Header from "./Header";
import SprayCareInformationSection from "./SprayCareInformationSection";
import SprayCareFeaturesZigZagSection from "./SprayCareFeaturesZigZagSection";
import SprayCareBuyNowSection from "./SprayCareBuyNowSection";
import SprayCareBannerFeaturesSection from "./SprayCareBannerFeaturesSection";
import SizeComparison from "./SizeComparison";
import SprayCareCriticsSection from "./SprayCareCriticsSection";
import SprayCareReviewSection from "./SprayCareReviewSection";

// import ExplanationSection from "./ExplanationSection";
// import SprayCareFeaturesSection from "./SprayCareFeaturesSection";
// import SprayCareBannerSection from "./SprayCareBannerSection";
// import SprayCareUserTestimonial from "./SprayCareUserTestimonial";

const MainComponent = () => {
  // const explanationRef = useRef();
  // const bannerRef = useRef();
  // const spraycareFeaturesRef = useRef();
  // const testimonialsRef = useRef();

  const size = useWindowSize();
  const headerRef = useRef();
  const bannerFeat = useRef();
  const informationRef = useRef();
  const featuresRef = useRef();
  const criticRef = useRef();
  const sizeComparisonRef = useRef();
  const productRef = useRef();
  const reviewRef = useRef();
  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }
  const refposition = () => {
    let navBar = document.getElementById("navBar");
    let sprayCareLogo = document.getElementById("sprayCareLogo");
    let shoppingCarIcon = document.getElementById("shoppingCarIcon");
    let userIcon = document.getElementById("userIcon");
    let kickstarter = document.getElementById("kickstarter_line");
    let warningband = document.getElementById("warning_line");
    let logOutIcon = document.getElementById("logOutIcon");
    let signOutIcon = document.getElementById("signOutIcon");
    let menuButton = document.getElementById("menuButton");
    let links = navBar.getElementsByClassName("nav__link");
    let spanCar = document.querySelector(".shopping_car_number");
    const changeNavAndLinkColor = (navBarColor, linkColor, ref = headerRef) => {
      const reactColor = () =>
        size.width < 768 && linkColor === "white"
          ? "black"
          : linkColor;
      for (var i = 0; i < links.length; i++) {
        links[i].style.color = reactColor();
      }
      menuButton.style.color = linkColor === "black" ? "black" : "white";
      logOutIcon.style.color = linkColor === "black" ? "black" : "white";
      signOutIcon
      ? signOutIcon.style.color = linkColor === "black" ? "black" : "white"
      : logOutIcon.style.color = linkColor === "black" ? "black" : "white";
      sprayCareLogo.style.filter =
        linkColor === "black" ? "brightness(0%)" : "brightness(100%)";
      shoppingCarIcon.style.filter = linkColor === "black" ? "brightness(0%)" : "brightness(100%)";
      userIcon.style.filter =
        linkColor === "black" ? "brightness(0%)" : "brightness(100%)";
      navBar.style.backgroundColor = navBarColor;
      if (linkColor == "white") {
        spanCar.setAttribute('style', 'background:white;color:black')
      } else {
        spanCar.removeAttribute('style')
      }
    };

    if (
      headerRef.current.getBoundingClientRect().top <= 0 &&
      headerRef.current.getBoundingClientRect().bottom - 200 >= 0
    ) {
      changeNavAndLinkColor("transparent", "white", headerRef);
      kickstarter.style.transform = 'scaleY(0)';
      if(warningband) warningband.style.transform = 'scaleY(0)';
    } 
    else if (
      bannerFeat.current.getBoundingClientRect().top <= 0 &&
      bannerFeat.current.getBoundingClientRect().bottom - 200 >= 0
    ) {
      changeNavAndLinkColor("transparent", "white", bannerFeat);
      kickstarter.style.transform = 'scaleY(1)';
      if(warningband) warningband.style.transform = 'scaleY(1)';
    } 
    else if (
      informationRef.current.getBoundingClientRect().top - 200 <= 0 &&
      informationRef.current.getBoundingClientRect().bottom >= 200
    ) {
      changeNavAndLinkColor("transparent", "white", informationRef);
      kickstarter.style.transform = 'scaleY(1)'
      if(warningband) warningband.style.transform = 'scaleY(1)'
    } 
    else if (
      featuresRef.current.getBoundingClientRect().top - 200 <= 0 &&
      featuresRef.current.getBoundingClientRect().bottom >= 150
    ) {
      if(
        size.width > 850
        ){
          changeNavAndLinkColor("white", "black", sizeComparisonRef);
          kickstarter.style.transform = 'scaleY(1)'
          if(warningband) warningband.style.transform = 'scaleY(1)'
        }else{
          changeNavAndLinkColor("#EBEFF0", "black", sizeComparisonRef);
          kickstarter.style.transform = 'scaleY(1)'
          if(warningband) warningband.style.transform = 'scaleY(1)'
        }
    } 
    else if (
      criticRef.current.getBoundingClientRect().top - 200 <= 0 &&
      criticRef.current.getBoundingClientRect().bottom >= 150
    ) {
      changeNavAndLinkColor("#F7F7FB", "black", criticRef);
    } 
    else if (
      reviewRef.current.getBoundingClientRect().top - 200 <= 0 &&
      reviewRef.current.getBoundingClientRect().bottom >= 150
    ) {
      changeNavAndLinkColor("white", "black", criticRef);
    }
    else if (
      sizeComparisonRef.current.getBoundingClientRect().top - 200 <= 0 &&
      sizeComparisonRef.current.getBoundingClientRect().bottom >= 150
    ) {
      changeNavAndLinkColor("transparent", "white", sizeComparisonRef);
      kickstarter.style.transform = 'scaleY(1)'
      if(warningband) warningband.style.transform = 'scaleY(1)'
    }
    else if (
      productRef.current.getBoundingClientRect().top - 200 <= 0 &&
      productRef.current.getBoundingClientRect().bottom >= 0
    ) {
      changeNavAndLinkColor("transparent", "white");
      kickstarter.style.transform = 'scaleY(0)'
      if(warningband) warningband.style.transform = 'scaleY(0)'
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", refposition);
    return(() => {
      window.removeEventListener("scroll", refposition)
    })
  });

  return (
    <React.Fragment>
      <Header headerRef={headerRef} width={size.width}/>
      <SprayCareBannerFeaturesSection bannerFeat={bannerFeat}/>
      <SprayCareFeaturesZigZagSection featuresRef={featuresRef} />
      <SprayCareInformationSection informationRef={informationRef} />
      <SizeComparison sizeComparisonRef={sizeComparisonRef}/>
      <SprayCareCriticsSection criticRef={criticRef}/>
      <SprayCareReviewSection reviewRef={reviewRef}/>
      <SprayCareBuyNowSection productRef={productRef}/>
      {/* <ExplanationSection explanationRef={explanationRef} /> */}
      {/* <SprayCareFeaturesSection spraycareFeaturesRef={spraycareFeaturesRef}/> */}
      {/* <SprayCareBannerSection bannerRef={bannerRef}/> */}
      {/* <SprayCareUserTestimonial testimonialsRef={testimonialsRef} /> */}
    </React.Fragment>
  );
};

export default MainComponent;