import React,{useState,useEffect} from 'react';
import { Reloj } from '../../components/imagesComponents';
const OrdersComponent = ({
  _id,
  name,
  quantity,
  total,
  date
}) => {
  const [dateFormat,setDateFormat] = useState('');
  useEffect(() => {
    let newDate = new Date(date);
    let month = newDate.getMonth() + 1;
    let day = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
    let fullDate = `${day}/${month < 10 ? '0' + month : month}/${newDate.getFullYear()}`;
    setDateFormat(fullDate)
  },[date])
  return (
    <React.Fragment>
    <div className="frame frame_product">
      <div>
        <img src={Reloj} style={{ width: '100px' }} alt={'Reloj'} />
      </div>
      <div className="frame_product__frame_second-container">
        <ul className="frame_product__frame_second-container__list">
          <li>
            <strong>{name}</strong>
          </li>
          <li>Quantity: {quantity}</li>
          <li>Order: {dateFormat}</li>
          <li>Total: ${total} USD</li>
        </ul>
      </div>
    </div>
    </React.Fragment>
  );
};
export default OrdersComponent;
