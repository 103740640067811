import React,{useContext, useEffect} from 'react';
import { Switch, Route } from 'react-router-dom';
import { AuthContext } from '../auth/AuthContext';
import Main from '../pages/main';
import Product from '../pages/product';
import About from '../pages/about';
import Manual from '../pages/manual';
import Troubleshooting from '../pages/troubleshooting';
import FAQs from '../pages/faqs';
import Survey from '../pages/survey';
import Contact from '../pages/contact';
import ShoppingCar from '../pages/shopping_car';
import Login from '../pages/login';
import CreateAccount from '../pages/create_account';
import CreateLead from '../pages/lead';
import Dashboard from '../pages/dashboard';
import CheckOut from '../pages/checkout';
import PaySuccess from '../pages/pay_success';
import RecoveryPassword from '../pages/recovery_password';
import {PrivateRoute,PublicRoute,CheckoutPublicRoute} from '../auth/ProtectedRoutes';
import Terms from '../pages/terms';
import Policy from '../pages/policy';
import Refund from '../pages/refund';
import ReactGA from "react-ga";

const trackingId = "G-Q9QQBPT559"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);


const AppRouter = () => {
 useEffect(() => {
  ReactGA.pageview(window.location.pathname + window.location.search)
  ReactGA.set({ page: window.location.pathname + window.location.search })
 },[])

  const {state:{logged}} = useContext(AuthContext);

  return (
    <Switch>
      <Route exact path="/" component={Main} />
      <Route path="/product" component={Product} />
      <Route path="/about" component={About} />
      <Route path="/faqs" component={FAQs} />
      <Route path="/survey" component={Survey} />
      <Route path="/contact" component={Contact} />
      <Route path="/shopping_car" component={ShoppingCar} />
      <Route path="/lead" component={CreateLead} />
      <PublicRoute path="/login" component={Login} isAuthenticated={logged}/>
      <Route path="/create_account" component={CreateAccount} />
      <PrivateRoute path="/dashboard" component={Dashboard} isAuthenticated={logged} />
      <CheckoutPublicRoute path="/checkout" component={CheckOut} />
      <Route path="/pay_success" component={PaySuccess} />
      <Route path="/terms" component={Terms} />
      <Route path="/policy" component={Policy} />
      <Route path="/refund" component={Refund} />
      <Route path="/manual" component={Manual} />
      <Route path="/troubleshooting" component={Troubleshooting} />
      <Route
        exact
        path="/recovery_password/:token"
        component={RecoveryPassword}
      />
    </Switch>
  );
};
export default AppRouter;
