import React,{useState,useEffect,useRef} from 'react';
import generateRequest from '../../services/RequestService';
import SpinnerComponent from '../../components/spinner';
import Swal from 'sweetalert2';
const RecoveryPasswordComponent = ({match}) => {

  const inpuRef = useRef(null);
  const [password,setPassword] = useState('');
  const [loading,setLoading] = useState(true);
  const [isValid,setIsValid] = useState(null);

  useEffect(() => {
    let {token} = match.params;
    generateRequest('api/user/verify-token','POST',{token}).then((json) => {
      if(json.code === 200){
        localStorage.setItem("tempToken",token);
        setLoading(false);
      }else {
        Swal.fire({
          title:"You don't have permission for this part",
          onClose:() => {
            window.location.replace("/login")
          }
        })
      }
    }).catch(err => {
      Swal.fire({
        title:"You don't have permission for this part",
        onClose:() => {
          window.localion.replace("/login")
        }
      })
    });
  },[]);

  const onSubmit =async (e) => {
    e.preventDefault();
    if(!isValid) {
      inpuRef.current.focus();
      return;
    }
    setLoading(true);
    let resp = await generateRequest('api/user/update-password','POST',{password},false,true);
    if(resp.code === 200){
      localStorage.removeItem("tempToken");
      Swal.fire({
        title:"password changed correctly",
        onClose:()=> {
          window.location.replace("/login");
        }
      });
    }else if(resp.errors){
      let htmlError = '<ul> ';
      for (let error of resp.errors) {
        htmlError += `<li>${error.msg}</li>`;
      }
      htmlError += '<ul>';
      Swal.fire({
        title: 'Here is your errors',
        html: htmlError,
      });
      setLoading(false);
    }else {
      Swal.fire({
        title:"Error when try to updatePassword"
      });
      setLoading(false);
    }

  }
  if(loading) return (<SpinnerComponent />);

  return (
    <>
    {loading && <SpinnerComponent />}
    <section className="special_container">
      <h1>Change Password</h1>
      <form className="forms" id="recover_form" onSubmit={onSubmit}>
        <div>
          <input
            type="password"
            name="password"
            placeholder="Password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label placeholder="Password"></label>
        </div>
        <div className="margin_reduce_for_form">
          <input
            ref={inpuRef}
            type="password"
            name="password"
            className={isValid == false ? 'invalid':''}
            required
            onFocus={() => setIsValid(false)}
            onBlur={() => {
              if(!isValid) setIsValid(null)
            }}
            placeholder="Verify Password"
            onChange={(e) => {
              if(password === e.target.value) setIsValid(true);
              else setIsValid(false);
            }}
          />
          <label placeholder="Verify Password"></label>
        </div>
        <p className="forms__text-invalidated" style={{display:isValid===false?"inherit":"none"}}>Your password doesn't match with the first field</p>
        <button type="submit" className="largeButtonOrder">
          Change Password
        </button>
      </form>
    </section>
    </>
  )
}

export default RecoveryPasswordComponent;
