import React from "react";
import {
  SprayCareSprayers,
  SprayCareSprayersMedium,
  AmazonBadge
} from "../../components/imagesComponents";
import ScrollAnimation from "react-animate-on-scroll";
import {ReactComponent as AmazonLogo} from '../../assets/img/AmazonBadge.svg';

const SprayCareBuyNowSection = ({productRef}) => (

  <section ref={productRef} className='buyNow'>
    <div className='buyNow__wrapper'>
      <div className='buyNow__content-box'>
        <div className='buyNow__text-box'>
            <h3 className='heading-primary heading-primary--white heading-primary--line'>
            Buy now
                </h3>
            <p className='paragraph paragraph--white paragraph--semiBold u-margin-top-medium'>
            Available on amazon
            </p>
            <br />
            <br />
            <p className='paragraph paragraph--white'>Available colors</p>
            <div className="buyNow__color-options">
          <ul>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className="btn-group">
            <a href='/product' target="_blank" className='btn btn--order-now'>ORDER NOW</a>
            <a href='https://www.amazon.com/dp/B09QT68WZ8' target="_blank" className='btn btn--amazon'><AmazonLogo/></a>
        </div>
        </div>
      </div>
    </div>
  </section>
  );

export default SprayCareBuyNowSection;
