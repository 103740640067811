import React, { useState, useEffect } from 'react';
import { updateCookie, getNumberDevices,geTotalDevices } from '../../services/CookiesServices';

const TableCarComponent = ({
  id,
  img,
  name,
  color,
  total,
  price,
  basePrice,
  wholesaleprice,
  quantity,
  total_checkout,
  update_subtotal,
  deleteElement,
}) => {
  const [quantityElement, setQuantityElement] = useState(quantity);
  const [totalElement, setTotalElement] = useState(total);
  const [singlePrice, setSinglePrice] =  useState(price)
  const car = document.querySelector('.shopping_car_number');

  useEffect(() => {
    ((basePrice && wholesaleprice) && quantity < 25) ? setSinglePrice(basePrice) : setSinglePrice(wholesaleprice || price);
  }, [basePrice, price, quantity, wholesaleprice])
  

  const plusLess = (type) => {
    let newQuantity = quantityElement;
    let newTotal = parseFloat(totalElement);
    let newSubtotal = total_checkout;
    let currentPrice = ((basePrice && wholesaleprice) && quantity < 25) ? basePrice : wholesaleprice || price;
    switch (type) {
      case 'pluss':
        if (quantityElement >= 25){
          setSinglePrice(wholesaleprice ? wholesaleprice : price);
          currentPrice = wholesaleprice ? wholesaleprice : price;
          break;
        }
        if ((quantityElement + 1 ) == 5){
          newQuantity = 25
          newTotal = basePrice && wholesaleprice ? (newTotal - (basePrice * 4)) + (wholesaleprice * 25) : newTotal + (price*21);
          newSubtotal = basePrice && wholesaleprice ? (newSubtotal - (basePrice * 4)) + (wholesaleprice * 25) : newSubtotal + (price*21);
          setQuantityElement(newQuantity);
          setSinglePrice(wholesaleprice ? wholesaleprice : price);
          currentPrice = wholesaleprice ? wholesaleprice : price;

        }else{
          newTotal += basePrice ? basePrice : price;
          newSubtotal += basePrice ? basePrice : price;
          newQuantity += 1
          currentPrice = wholesaleprice ? basePrice : price;
          setQuantityElement(newQuantity);
        }
        break;
      case 'less':
        if (quantityElement > 1) {
          newQuantity = quantityElement - 1;
          if (newQuantity == 24){
            newQuantity = 4;
            newTotal = basePrice && wholesaleprice ? (newTotal + (basePrice * 4)) - (wholesaleprice * 25) : (newTotal - (price * 21));
            newSubtotal = basePrice && wholesaleprice ? (newSubtotal + (basePrice * 4)) - (wholesaleprice * 25) : (newSubtotal - (price * 21));
            setSinglePrice(basePrice ? basePrice : price);
            currentPrice = wholesaleprice ? wholesaleprice : price;
          }else{
            newTotal -= basePrice ? basePrice : price;
            newSubtotal -= basePrice ? basePrice : price;
          }
          setQuantityElement(newQuantity);
          currentPrice = wholesaleprice ? basePrice : price;
        }
        break;
      default:
      break;
    }
    setTotalElement(newTotal.toFixed(2));
    updateCookie(id, newQuantity, newTotal, currentPrice);

    car.innerHTML = geTotalDevices();
    update_subtotal(newSubtotal);
  };
  return (
    <React.Fragment>
      <tr id="element_${item.id}">
        <td className="display_normal u-sm-device-hide" >
          <a
            data-id={id}
            className="delete_image_table"
            onClick={() => {
              deleteElement({ id, total: parseFloat(totalElement)});
              if(getNumberDevices() === 0) car.setAttribute("style","visibility:hidden")
              else car.innerHTML = geTotalDevices();
            }}>
            Remove
          </a>
        </td>
        <td className="textCenterMobile u-sm-device-hide" >
          <div>
            <img src={img} />
          </div>
        </td>
        <td className="u-sm-device-hide" >
          <div>
            <p>
              <strong>{name}</strong>
            </p>
            <strong>Color: </strong>
            <span
              className="color_background"
              style={{ backgroundColor: color }}></span>
              <br />
              <a
                data-id={id}
                className="delete_image_table display_mobile"
                onClick={() => {
                  deleteElement({ id, total: parseFloat(totalElement) });
                  if(getNumberDevices() === 0) car.setAttribute("style","visibility:hidden")
                  else car.innerHTML = geTotalDevices();
                }}>
                Remove
              </a>
          </div>
        </td>
        <td className="u-sm-device-visible">
          <div>
            <p>
              <strong>{name}</strong>
            </p>
            <strong>Color: </strong>
            <span
              className="color_background"
              style={{ backgroundColor: color }}></span>
            <br />
            <br />
          </div>
          <span style={{ fontSize: 'inherit' }}>
            ${(singlePrice).toFixed(2)}
          </span>
          <br />
          <br />
          <div>
            <button
              data-id={id}
              className="plusLesButtons lessButton"
              onClick={() => plusLess('less')}>
              -
              </button>{' '}
            <span className="quantity_value">{quantityElement}</span>{' '}
            <button
              data-id={id}
              className="plusLesButtons plussButton"
              onClick={() => plusLess('pluss')}>
              +
              </button>
          </div>
        </td>
        <td className="u-sm-device-hide" >
          <span className='single-price' style={{fontSize: 'inherit'}}>
            ${(singlePrice).toFixed(2)}

          </span>
            <div className="display_mobile">
              <button
                data-id={id}
                className="plusLesButtons lessButton"
                onClick={() => plusLess('less')}>
                -
              </button>{' '}
              <span className="quantity_value">{quantityElement}</span>{' '}
              <button
                data-id={id}
                className="plusLesButtons plussButton"
                onClick={() => plusLess('pluss')}>
                +
              </button>
            </div>
        </td>
        <td className="text-right u-sm-device-visible">
          <div>
            <img src={img} />
          </div>
          <br />
          <br />
          <br />
          <a
            data-id={id}
            className="delete_image_table display_mobile"
            onClick={() => {
              deleteElement({ id, total: parseFloat(totalElement) });
              car.innerHTML = geTotalDevices();
            }}>
            Remove
              </a>
        </td>
        <td className="display_normal u-sm-device-hide" >
          <div>
            <button
              data-id={id}
              className="plusLesButtons lessButton"
              onClick={() => plusLess('less')}>
              -
            </button>{' '}
            <span className="quantity_value">{quantityElement}</span>{' '}
            <button
              data-id={id}
              className="plusLesButtons plussButton"
              onClick={() => plusLess('pluss')}>
              +
            </button>
          </div>
        </td>
        <td className="display_normal">
          <span className="total_item">${totalElement}</span>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TableCarComponent;
