import React, { useState, useEffect,useContext } from 'react';
import { URL } from '../../services/conection';
import Swal from 'sweetalert2';
import SpinnerComponent from '../../components/spinner';
import ModalContent from './ModalContent';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';

const LoginComponent = () => {
  const {dispatch} = useContext(AuthContext);

  const [loading,setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  useEffect(() => {
    //if (localStorage.getItem('token')) window.location.replace('/dashboard');
  }, []);
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let object = {
      email:email.trim(),
      password,
    };
    let sendCreate = await fetch(`${URL}api/user/login`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(object),
    });
    let resp = await sendCreate.json();
    if (resp.code === 200) {
      localStorage.setItem('token', resp.token);
      localStorage.setItem('user', JSON.stringify(resp.user));
      if(localStorage.getItem('return')){
        localStorage.removeItem("return");
        window.location.replace('/checkout');
      }else window.location.replace('/dashboard');
    } else if (resp.errors) {
      let htmlError = '<ul> ';
      for (let error of resp.errors) {
        htmlError += `<li>${error.msg}</li>`;
      }
      htmlError += '<ul>';
      Swal.fire({
        title: 'Here is your errors',
        html: htmlError,
      });
      setLoading(false);
      dispatch({
        type:types.login,
        payload:{}
      })
    }else {
      setLoading(false);
      console.log(resp);
      Swal.fire('Error',resp.message);
    }
  };
  return (
    <React.Fragment>
      {loading && <SpinnerComponent color={"transparent"}/>}
      <section className="special_container">
        <h1>Login</h1>
        <form className="forms" id="login_form" onSubmit={onSubmit}>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label placeholder="Email"></label>
          </div>
          <div className="margin_reduce_for_form">
            <input
              type="password"
              name="password"
              minlenght="5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              placeholder="Password"
            />
            <label placeholder="Password"></label>
          </div>
          <div>
            <a href="#" data-target="#recoverModal" data-toggle="modal">Forgot your password?</a>
          </div>
          <button type="submit" className="largeButtonOrder">
            SIGN IN
          </button>
          <a href="create_account" className="createAccountButton">
            Create account
          </a>
        </form>
        <ModalContent />
      </section>
    </React.Fragment>
  );
};

export default LoginComponent;
