import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import TroubleshootingContent from './TroubleshootingContent';

const Troubleshooting = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} noTransparent/>
      <TroubleshootingContent />
      <FooterAppp />
    </React.Fragment>
  );
};

export default Troubleshooting;