const updateCookie = (pId, pQuantity, pTotal, pPrice) => {
  let devices = getCookie('device_order');
  if (devices !== '') {
    let deviceParser = JSON.parse(devices);
    let device = deviceParser
      .map(function (d) {
        return d.id;
      })
      .indexOf(pId);

    if (device > -1) {
      deviceParser[device].total = parseFloat(pTotal).toFixed(2);
      deviceParser[device].quantity = pQuantity;
      deviceParser[device].price = pPrice;
      let objectParse = JSON.stringify(deviceParser);
      document.cookie = `device_order=${objectParse};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    }
  }
};

const deleteCookieData = (pId) => {
  let devices = getCookie('device_order');
  if (devices !== '') {
    let deviceParser = JSON.parse(devices);
    let device = deviceParser
      .map(function (d) {
        return d.id;
      })
      .indexOf(pId);

    if (device > -1) deviceParser.splice(device, 1);
    let objectParse = JSON.stringify(deviceParser);
    document.cookie = `device_order=${objectParse};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    document.cookie = "backer_validation=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "backer_number=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    return deviceParser;
  }
  return [];
};

const deleteCookie = () => {
  document.cookie = `device_order=${''};Expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  document.cookie = "backer_validation=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "backer_number=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

const getCookie = (pCookieName) => {
  let name = pCookieName + '=';
  let allCookieArray = document.cookie.split(';');
  for (let i = 0; i < allCookieArray.length; i++) {
    let temp = allCookieArray[i].trim();
    if (temp.indexOf(name) == 0)
      return temp.substring(name.length, temp.length);
  }
  return '';
};
const getNumberDevices = () => {
  let name = 'device_order=';
  let allCookieArray = document.cookie.split(';');
  for (let i = 0; i < allCookieArray.length; i++) {
    let temp = allCookieArray[i].trim();
    if (temp.indexOf(name) == 0) {
      let object = temp.substring(name.length, temp.length);
      return JSON.parse(object).length;
    }
  }
  return 0;
};

const geTotalDevices = () => {
  let name = 'device_order=';
  let allCookieArray = document.cookie.split(';');
  for (let i = 0; i < allCookieArray.length; i++) {
    let temp = allCookieArray[i].trim();
    if (temp.indexOf(name) == 0) {
      let object = temp.substring(name.length, temp.length);
      let findObject = JSON.parse(object);
      let quantity = 0;
      for (let val of findObject)quantity += val.quantity;
      return quantity;
    }
  }
  return 0;
}
export {
  getCookie,
  updateCookie,
  deleteCookieData,
  deleteCookie,
  getNumberDevices,
  geTotalDevices
};
