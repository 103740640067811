import React, { useState, useEffect,useContext } from 'react';
//import { ReactComponent as ShoppingCar } from "../../assets/img/car.svg";

import UserImage  from  '../../assets/img/user.svg';
import UserImageBlack  from '../../assets/img/user_black.svg';
import  LogoBlack from '../../assets/img/logo-black.svg';
import LogoBlackMin from '../../assets/img/logo-black-min.svg';
import  ShoppingCarBlack from '../../assets/img/car_black.svg';
import LogoMin from '../../assets/img/logo-min.svg'
import  Logo from '../../assets/img/logo.svg';
import ShoppingCar  from "../../assets/img/car.svg";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteCookie,geTotalDevices } from '../../services/CookiesServices';
import { AuthContext } from '../../auth/AuthContext';
import { types } from '../../types/types';

const Navbar = ({ isBlack = false, noTransparent = false }) => {
  const size = useWindowSize();
  const {state:{logged},dispatch} = useContext(AuthContext);
  const [carNumber, setCarNumber] = useState(0);
  useEffect(() => {
    setCarNumber(geTotalDevices());
  }, []);

  function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
  }

  const showResponsiveMenu = () => {
    let navbar = document.querySelector('.responsive_class');
    if (navbar.hasAttribute('style')) navbar.removeAttribute('style');
    else navbar.setAttribute('style', 'visibility:visible;opacity:1');
  };

  return (
    <React.Fragment>
      <nav id="navBar" className={`${noTransparent ? "nav nav--no-transparent" : "nav"}`}>
        <div className="nav__wrapper">
          <div>
            <a href="/">
              <img
                id="sprayCareLogo"
                src={isBlack ? size.width <= 1024 ? LogoBlackMin : LogoBlack : size.width <= 1024 ? LogoMin : Logo}
                alt={'Logo'}
              />
            </a>
          </div>
          <div>
            <button id='menuButton' className="btn_navbar" onClick={showResponsiveMenu}>
              &#9776;
            </button>
            <ul className="responsive_class">
              <li>
                <a
                  className={`nav__link ${
                    isBlack ? 'nav__link--black' : 'nav__link--white'
                  }`}
                  href="about">
                  About
                </a>
              </li>
              <li>
                <a
                  className={`nav__link ${
                    isBlack ? 'nav__link--black' : 'nav__link--white'
                  }`}
                  href="faqs">
                  FAQ
                </a>
              </li>
              <li>
                <a
                  className={`nav__link ${
                    isBlack ? 'nav__link--black' : 'nav__link--white'
                  }`}
                  href="contact">
                  Contact
                </a>
              </li>
              <li>
                <a
                  className={`nav__link ${
                    isBlack ? 'nav__link--black' : 'nav__link--white'
                  }`}
                  href="/product">
                  <button className="pre_order">Order</button>
                </a>
              </li>
              <li>
                <a
                  className={`nav__link display_normal ${
                    isBlack ? 'nav__link--black' : 'nav__link--white'
                  }`}
                  href="shopping_car">
                  <img
                    id="shoppingCarIcon"
                    src={isBlack ? ShoppingCarBlack : ShoppingCar}
                  />
                </a>
                <a
                  className={`nav__link display_mobile ${
                    isBlack ? 'nav__link--black' : 'nav__link--white'
                  }`}
                  href="shopping_car">
                  <img src={ShoppingCarBlack} />
                </a>
                <span className={`shopping_car_number ${carNumber === 0?'hiddenClass':''}`}>{carNumber}</span>
              </li>
              <li id='logOutIcon' style={{transition: "all 0.5s ease-in-out"}}>
                <a
                  href={logged ? 'dashboard' : 'login'}
                  className="display_normal">
                  <img
                    id="userIcon"
                    src={isBlack ? UserImageBlack : UserImage}
                  />
                </a>

                <a
                  href={logged ? 'dashboard' : 'login'}
                  className="display_mobile">
                  <img src={UserImageBlack} />
                </a>
              </li>
              {logged && (
                <li id="signOutIcon" className={'icon_logout-container'} style={{color: isBlack ? 'black' : 'white'}}>
                  <FontAwesomeIcon
                    onClick={() => {
                      localStorage.removeItem('token');
                      localStorage.removeItem('user');
                      localStorage.removeItem('info');
                      sessionStorage.removeItem('last_order');
                      deleteCookie();
                      window.location.replace('/login');
                      dispatch({type:types.logout});
                    }}
                    className="icon_logout"
                    icon={faSignOutAlt}
                    style={{transition: "all 0.5s ease-in-out", color: size.width < 768 ? 'black' : 'currentcolor'}}
                  />
                </li>
              )}
            </ul>

          </div>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navbar;
