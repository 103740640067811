import React from 'react'
import {
    Comment0,
    Comment1,
    Comment2,
    Comment3,
    Comment4,
    Comment5,
} from "../../components/imagesComponents";

import ScrollAnimation from "react-animate-on-scroll";


const SprayCareReviewSection = ({ reviewRef }) => {
    return (
        <div ref={reviewRef}>
            <div className="reviews reviews--desktop">
                <ScrollAnimation animateIn='animate__fadeInUp' className='heading-primary heading-primary--line'>
                Users comments
                </ScrollAnimation>
                <div className="reviews-comments-ctnr reviews-comments-ctnr--desktop">
                <div className="custom-ratio-box">
                    <ScrollAnimation animateIn='animate__zoomIn' delay={100} className="reviews-comment reviews-comment--1" >
                    <img  src={Comment1} alt="" />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__zoomIn' delay={200} className="reviews-comment reviews-comment--2" >
                    <img  src={Comment2} alt="" />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__zoomIn' delay={300} className="reviews-comment reviews-comment--3" >
                    <img  src={Comment3} alt="" />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__zoomIn' delay={400} className="reviews-comment reviews-comment--4" >
                    <img  src={Comment4} alt="" />
                    </ScrollAnimation>
                    <ScrollAnimation animateIn='animate__zoomIn' delay={500} className="reviews-comment reviews-comment--5" >
                    <img  src={Comment5} alt="" />
                    </ScrollAnimation>
                </div>
                </div>
            </div>
            <div className="reviews reviews--mobile">
                <h1 className='heading-primary heading-primary--line'>
                    Users Comments
                </h1>
                <div className="reviews-comments-ctnr reviews-comments-ctnr--mobile">
                    <img className="reviews-comment reviews-comment--0" src={Comment0} alt="" />
                </div>
            </div>
        </div>
    )
}

export default SprayCareReviewSection
