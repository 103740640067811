import React, {useEffect, useState } from "react";
import { ReactComponent as Cross } from "../../assets/img/cross.svg";
import { ReactComponent as Blackfriday } from "../../assets/img/blackfriday.svg";
import ScrollLink from "../../components/ScrollLink";


const BlackfridayAlert = ({show = false}) => {

  const [ showWarning, setShowWarning] = useState(show);
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds - 1);
    }, 1000);

    const closeModal = setTimeout(() => {
      clearInterval(interval);
      setShowWarning(false)
    }, 2500);


    return () => {
      clearTimeout(closeModal);
    };
  }, [])

  return (
    <div
      className={`spraycare-modal-full-screen-ctnr ${showWarning ? "spraycare-modal-full-screen-ctnr--display" : "spraycare-modal-full-screen-ctnr--none"}`}
    >
      <div className="spraycare-modal">
        <div className="spraycare-modal-body--blackfriday">
          <button
            className="close-btn"
            onClick={() => {
              setShowWarning(false)
              // handleScrollBehavior(false)
            }}
          >
            <Cross />
          </button>
          {/* <div className="close-btn" style={{width: "auto"}}>
            Closing in {seconds}
          </div> */}
            <div className="spraycare-modal-icon--backfriday">
              <Blackfriday />
            </div>
          <div className="spraycare-modal-header--backfriday">
            <ScrollLink className="spraycare-modal-link--blackfriday" to={"/product"} onPress={() => setShowWarning(false)}>ORDER NOW</ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlackfridayAlert;
