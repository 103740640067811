import React from 'react';

const ElementToPayComponent = ({ quantity, img, name, total, colorName }) => {
  return (
    <React.Fragment>
      <div>
        <div>
          <img src={img} alt="" />
          <i>{quantity}</i>
        </div>
        <div>
          <h4>{name}</h4>
          <span>Color: {colorName}</span>
        </div>
        <div>
          <span>${Number(total).toFixed(2)}</span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ElementToPayComponent;
