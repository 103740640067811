import React, { useReducer, useState } from 'react';
import { AuthContext } from './auth/AuthContext';
import { authReducer } from './auth/authReducer';
import AppRouter from './routes/AppRouter';

const getToken = () => {
  let initialState = { logged: false };
  if (localStorage.getItem('token')) initialState.logged = true;
  return initialState;
};
function App() {
  const [state, dispatch] = useReducer(authReducer, {}, getToken);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
        <AppRouter />
    </AuthContext.Provider>
  );
}

export default App;
