import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import CreateLeadComponent from './CreateLead.js';
const CreateLead = () => {
 return (
  <React.Fragment>
   <Navbar isBlack={true} />
   <CreateLeadComponent />
   <FooterAppp />
  </React.Fragment>
 );
};

export default CreateLead;
