import { map } from "jquery";
import React from "react";
import "../../includes/bootstrap";
import questions from "./questions";

const FrequesceQuestionsComponent = () => {
  
  const Accordion = ({ id, q, a, index }) => {
    return (
      <div className="card-custom">
        <div id={`heading${id}`}>
          <h2 className="mb-0">
            <button
              className={`${index === 0 ? "btn" : "btn collapsed"}`}
              type="button"
              data-toggle="collapse"
              data-target={`#collapse${id}`}
              aria-expanded={`${index === 0 ? "true" : "false"}`}
              aria-controls={`collapse${id}`}
            >
              {q}
            </button>
          </h2>
        </div>

        <div
          id={`collapse${id}`}
          className={`${index === 0 ? "collapse show" : "collapse"}`}
          aria-labelledby={`heading${id}`}
          data-parent="#accordionQuestions"
        >
          <div className="card-body">
            <p>{a}</p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <section className="special_container">
        <h1>Frequently Asked Questions</h1>
        <div className="accordion" id="accordionQuestions">
          {questions.map((question, index) => (
            <Accordion
              index={index}
              key={`question${question.id}`}
              id={question.id}
              q={question.q}
              a={question.a}
            />
          ))}
        </div>
        <a href="/">
          <button className="largeButtonOrder">HOME</button>
        </a>
      </section>
    </React.Fragment>
  );
};

export default FrequesceQuestionsComponent;
