import React from "react";
import { Link } from "react-router-dom";
import {
  troubleshooting1,
  troubleshooting2,
  troubleshooting3,
  troubleshooting4,
  troubleshooting5,
  troubleshooting1Mobile,
  troubleshooting2Mobile,
  troubleshooting3Mobile,
  troubleshooting4Mobile,
  troubleshooting5Mobile,
} from "../../components/imagesComponents";

const TroubleshootingContent = () => {
  return (
    <React.Fragment>
      <section className="special_container special_container--troubleshooting">
        <h1 className="troubleshooting">Troubleshooting</h1>

        <div id="text_about">
          <p>
            After refilling your SprayCare Band, the initial sprays may be
            weaker as the liquid fills the pores in the atomizer.
          </p>
          <br />
          <br />
          <p>
          SprayCare <b>works well with liquid hand sanitizers that consist of 60-80% alcohol.</b> This can be either isopropyl alcohol or denatured ethyl alcohol.
          </p>
          <br />
          <br />
          <p id="favoritesSprays">
           Some of our favorites include Bath and Body works anti-bacterial hand spray, Solimo 70% Isopropyl alcohol, and Heritage Distillery 80% denatured ethyl alcohol.
          </p>
          <br />
          <br />
          <div className="about">
              <img src={troubleshooting5} className="imgPicDesktop" alt="" width="100%"/>
              <img src={troubleshooting5Mobile} className="imgPicMobile" alt="" width="100%"/>
          </div>
          <p style={{fontSize: "1.6rem", lineHeight: "3rem", paddingBottom: "3rem"}}>
          <b>
          We don't sell any product to use with SprayCare. However, the previously mentioned products have had great results with the SprayCare band.
          </b>
          </p>
          <br />
          <br />
          <p>
            In our testing, the GoodSense brand of Isopropyl alcohol <b>does not seem to work well</b> with SprayCare.
            If you have found a particular product that you enjoy using with SprayCare,
            please let us know and we will add it to the list of suggested products. You can email us at hello@spray.care
          </p>
          <br />
          <br />
          <div className="about">
              <img src={troubleshooting4} className="imgPicDesktop" alt="" width="100%"/>
              <img src={troubleshooting4Mobile} className="imgPicMobile" alt="" width="100%"/>
          </div>
          <br />
          <br />
          <div id="oil"></div>
          <p>
          For dense liquids such as essential oils, mix with distilled water. Example: Use 50-100 ml of purified water and add 6 drops of oil/repellent, mix it with distilled water, <b>do not use the oil directly.</b>
          </p>
          <br />
          <br />
          <div className="about">
              <img src={troubleshooting1} className="imgPicDesktop" alt="" width="100%"/>
              <img src={troubleshooting1Mobile} className="imgPicMobile" alt="" width="100%"/>
          </div>
          <br />
          <br />
          <p>
          Please<b> do not use</b> with any solvents (ethyl acetate, menthyl acetate, acetaldehyde, methanol, keytones (acetone), and others). Please refer to the USER MANUAL for a list of other liquids that could damage the SprayCare Band.
          </p>
          <br />
          <br />
          <div className="about">
              <img src={troubleshooting3} className="imgPicDesktop" alt="" width="100%"/>
              <img src={troubleshooting3Mobile} className="imgPicMobile" alt="" width="100%"/>
          </div>
          <br />
          <br />
          <p>
            While refilling the liquid container, bubbles may form at the refill
            port. Bubbles do not necessarily indicate that the container is
            full. Pop any bubbles that form and continue to fill the liquid
            container until it is completely full.
          </p>
          <br />
          <br />
          <div className="about">
              <img src={troubleshooting2} className="imgPicDesktop" alt="" width="100%"/>
              <img src={troubleshooting2Mobile} className="imgPicMobile" alt="" width="100%"/>
          </div>
          <br />
          <br />
          <p>
            <b>
              IF YOUR SPRAYCARE BAND DOES NOT FUNCTION AFTER PRESSING THE POWER
              BUTTON, ENSURE THAT THE BATTERY IS COMPLETELY CHARGED AND THAT THE
              TANK IS FULL.
            </b>
          </p>
          <br />
          <br />
          <p>
            If this does not resolve the problem, perform a clog clean out. (For
            more info go to{" "}
            <span>
              <Link className="aboutLink" to="/manual">manuals download section</Link>
            </span>{" "}
            and see section 7 for cleaning instructions.)
          </p>
          <br />
          <br />
          <a href="/">
            <button className="largeButtonOrder">HOME</button>
          </a>
        </div>
      </section>
    </React.Fragment>
  );
};

export default TroubleshootingContent;
