import React, { useState, useEffect } from 'react';
import  LogoBlack from '../../assets/img/logo-black.svg';
import BlueCar from '../../assets/img/bx_bxs-cart.svg';

import ShippingMethodComponent from './ShippingMethodComponent';
import CheckoutCustomerComponent from './CheckoutCustomerComponent';
//import CheckoutPaymentComponent from './CheckoutPaymentComponent';
import ElementToPayComponent from '../../components/ElemetToPayComponent';

//Services
import { getCookie } from '../../services/CookiesServices';
import { getStates } from '../../services/States';
import generateRequest from '../../services/RequestService';
import { encryptInformation,dcryptInformation } from '../../services/EncryptService';
import CheckoutForm from "./CheckoutForm";

const CheckOutComponent = ({history}) => {
  const [devices, setDevices] = useState([]);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [saleTax, setSaleTax] = useState(0);
  const [shippingCost, setShippingCost] = useState(10);
  const [data, setData] = useState({});
  const [address,setAddress] = useState([]);
  const [indexContent, setIndexContent] = useState(0);
  const [hasFreeShipping, setHasFreeShipping] = useState(false);
  const shippingCostsUSA = {1 : 9, 2 : 12, 3 : 17, 4 : 20, 10 : 40,  25 : 65, 40 : 85, 50 : 105, 100 : 160 }
  const shippingCostsWorld = {1 : 32, 2 : 34, 3 : 36, 4 : 39, 10 : 65, 25 : 120, 40 : 145, 50 : 180, 100 : 240 }
  const shippingAdapterCostsUSA = {1 : 8.99, 2 : 8.99, 3 : 8.99, 4 : 13.99, 25 : 30}
  const shippingAdapterCostsWorld = {1 : 32, 2 : 32, 3 : 32, 4 : 39, 25 : 55}

  const setTax = (e, c) => {
    costShipping = getShippingCosts(c, devices)
    let {sales_tax} = e;
    let shipping = sales_tax ? hasFreeShipping ? 0 : costShipping : hasFreeShipping ? 0 : costShipping
    let getValueTax = (sales_tax * subTotal)/100;
    setSaleTax(sales_tax ? getValueTax : 0);
    setShippingCost(sales_tax ? hasFreeShipping ? 0 : costShipping : hasFreeShipping ? 0 : costShipping)
    setTotal(subTotal+shipping+(sales_tax ? getValueTax : 0));
  }

  //let country = null
  useEffect(() => {
    let backerStored = getCookie('backer_validation');
    let devicesStored = getCookie('device_order');
    let info = localStorage.getItem("info");
    let sales_tax = 0;
    let shipping_cost = 0;
    let subtotal = 0;

    if (backerStored !== '') {
      setShippingCost(parseFloat(0))
      shipping_cost = 0
      setHasFreeShipping(true)
    }
    let deviceParser = JSON.parse(devicesStored);
    if(info) {
      let objectInfo = dcryptInformation(info);

      for (let element of deviceParser) {
        subtotal += parseFloat(element.total);
      }

      if(objectInfo != ''){
        if(objectInfo.country === "US"){
          let statesArray = getStates(objectInfo.country)
          let stateRate = statesArray.find((val) => val.abbreviation === objectInfo.state)
          let saleTaxStoraged = stateRate.sales_tax;
          sales_tax = parseFloat(((subtotal / 100) * saleTaxStoraged).toFixed(2));
        }else{
          sales_tax = parseFloat(objectInfo.sales_tax);
        }

        let costShipping = getShippingCosts(objectInfo.country, deviceParser)
        shipping_cost = backerStored !== '' ? 0 : costShipping

        if(objectInfo.shipping){
          if(backerStored !== ''){
            objectInfo.shipping = '0.00';
            let informationEncrypt = encryptInformation(objectInfo);
            localStorage.setItem('info', informationEncrypt);
            shipping_cost = 0;
            setShippingCost(0)
          }else{
            objectInfo.shipping = objectInfo.country ? costShipping : '14.00' ;
            let informationEncrypt = encryptInformation(objectInfo);
            localStorage.setItem('info', informationEncrypt);
            setShippingCost(parseFloat(objectInfo.shipping))
            shipping_cost = objectInfo.country ? costShipping : 14.00
          }
        }
      }
    }else{
      for (let element of deviceParser) {
        subtotal += parseFloat(element.total);
      }
    }

    setShippingCost(shipping_cost)
    setSaleTax(sales_tax);
    setSubTotal(subtotal);
    setTotal(subtotal + sales_tax + shipping_cost);
    setDevices(JSON.parse(devicesStored))

    if(localStorage.getItem('token')){
      generateRequest('api/address/get-all-address','GET',{},true).then((value) => {
        setAddress(value.address);
      }).catch(e => console.log("Error"))
    }
  }, []);

  const TabFunctionForm = (e) => {
    let { id, type } = e;
    let tabsClass = document.querySelectorAll('.checkout_tab');
    if (type === 'NEXT') {
      setIndexContent(id + 1);
      tabsClass[id].classList.remove('active');
      tabsClass[id + 1].classList.add('active');
    } else if (type === 'PREVIUS') {
      setIndexContent(id - 1);
      tabsClass[id].classList.remove('active');
      tabsClass[id - 1].classList.add('active');
    }
  };


  let costShipping = 0
  const getShippingCosts = (c, devices)=> {
    let quantitySprayCares = 0
    let quantityAdapters = 0
    if (devices){
      const bands = devices.filter(x => 'SprayCare' == x.name)
      if (bands && bands.length > 0){
        bands.map(device => {
          quantitySprayCares += device.quantity
        })
      }
      const adapters = devices.filter(x => x.name.indexOf('Strap') != -1 || x.name.indexOf('Plug') != -1)
      if (adapters && adapters.length > 0){
        adapters.map(device => {
          quantityAdapters += device.quantity
        })
      }

      if (quantityAdapters > 4 && quantityAdapters < 25){
        quantityAdapters = 4
      }else if (quantityAdapters > 25){
        quantityAdapters = 25
      }

      if (quantitySprayCares >= 4 && quantitySprayCares < 8){
        quantitySprayCares = 4
      }else if (quantitySprayCares >= 8 && quantitySprayCares < 20) {
        quantitySprayCares = 10
      }else if (quantitySprayCares >= 20 && quantitySprayCares < 30){
        quantitySprayCares = 25
      }else if (quantitySprayCares >= 30 && quantitySprayCares < 40){
        quantitySprayCares = 40
      }else if (quantitySprayCares >= 40 && quantitySprayCares < 50){
        quantitySprayCares = 50
      }else if (quantitySprayCares >= 50){
        quantitySprayCares = 100
      }


      if ('US' != c){
        let costs = Object.assign(shippingCostsWorld)
        costShipping = costs[quantitySprayCares]

        if (costShipping == undefined || costShipping == null){
          costShipping = 0
        }
        if (quantityAdapters > 0){
          let costs = Object.assign(shippingAdapterCostsWorld)
          if (costShipping == 0){
            costShipping = costs[quantityAdapters]
          }else if (quantityAdapters >= 25){ // if not more then 25, then it could be enough with the quote from the spraycares
            costShipping += costs[quantityAdapters]
          }
        }
      }else{
        let costs = Object.assign(shippingCostsUSA)
        costShipping = costs[quantitySprayCares]
        if (costShipping == undefined || costShipping == null){
          costShipping = 0
        }
        if (quantityAdapters > 0){
          let costs = Object.assign(shippingAdapterCostsUSA)
          if (costShipping == 0){
            costShipping = costs[quantityAdapters]
          }else if (quantityAdapters >= 25){ // if not more then 25, then it could be enough with the quote from the spraycares
            costShipping += costs[quantityAdapters]
          }
        }
      }
    }
    return costShipping
  }

  const SetDataToSend = (e) => {
    let dataLocal = data;
    if (e.id === 0) {
      let {
        firstName,
        lastName,
        address,
        apartment,
        city,
        country,
        state,
        zip,
        phone,
        email,
      } = e.dataForm;
      dataLocal.firstName = firstName;
      dataLocal.lastName = lastName;
      dataLocal.address = address;
      dataLocal.city = city;
      dataLocal.country = country;
      dataLocal.state = state;
      dataLocal.zip = zip;
      dataLocal.phone = phone;
      dataLocal.apartment = apartment;
      dataLocal.email = email;
      dataLocal.backerNumber = getCookie('backer_number');
      dataLocal.sales_tax = saleTax.toFixed(2);
    } else if (e.id === 1) {
      dataLocal.total = total.toFixed(2);
      dataLocal.subtotal = subTotal.toFixed(2);
      dataLocal.shipping = shippingCost.toFixed(2);
      dataLocal.devices = devices;
    }
    let informationEncrypt = encryptInformation(dataLocal);
    localStorage.setItem('info', informationEncrypt);
    setData(dataLocal);
  };

  const rederTab = () => {
    if (indexContent === 0) {
      return (
        <CheckoutCustomerComponent
          getData={(e) => SetDataToSend(e)}
          userAddress={address}
          changeScreen={(e) => {
            TabFunctionForm(e);
          }}
          setTax={setTax}
        />
      );
    } else if (indexContent === 1) {
      return (
        <ShippingMethodComponent
          getData={(e) => SetDataToSend(e)}
          changeScreen={(e) => {
            TabFunctionForm(e);
          }}
          data={data}
          shipping={shippingCost}
          setNewShipping={(e) => {
            let totalChange = parseFloat(total - shippingCost);
            if (e === '0') setTotal(totalChange);
            else setTotal(parseFloat(totalChange + e));
            setShippingCost(parseFloat(e));
          }}
          // getData={SetDataToSend}
        />
      );
    } else if (indexContent === 2) {
      return (
        <CheckoutForm
          getData={(e) => SetDataToSend(e)}
          changeScreen={(e) => {
            TabFunctionForm(e);
          }}
        />
      );
    }
  };
  return (
    <React.Fragment>
      <section id="checkout_container">
        <div>
          <div className="checkout_container-image_logo">
            <a href="/">
              <img src={LogoBlack} alt="" />
            </a>
          </div>
          <div className="checkout_container-nav">
            <ul>
              <li>Cart</li>
              <li className="checkout_tab active">Information</li>
              <li className="checkout_tab">Shipping</li>
              <li className="checkout_tab"> Payment</li>
            </ul>
          </div>
          <div className="accordion display_mobile" id="accordionCheckout">
            <div className="card-custom">
              <div id="headingOne">
                <h2 className="mb-0">
                  <button
                    className=""
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne">
                    <div className="accordionCheckout_element">
                     <div>
                        <p className="accordionCheckout_element__paragraph"><img src={BlueCar} alt="Blue car" />{'  '}<strong>Show order summary</strong></p>
                      </div>
                      <div>
                        <p><strong>${total.toFixed(2)}</strong></p>
                      </div>
                    </div>
                  </button>
                </h2>
              </div>

              <div
                id="collapseOne"
                className="collapse show"
                aria-labelledby="headingOne"
                data-parent="#accordionCheckout">
                <div className="card-body" style={{background:"#f7f7fb",paddingTop:"30px"}}>
                  <div >
                    <div id="car_item_checkout">
                      {devices.map((element, index) => (
                        <ElementToPayComponent key={index} {...element} />
                      ))}
                    </div>
                    <div className="car_item_checkout-costs">
                      <div className="cost_container">
                        <div>
                          <h5>Cost of shipping</h5>
                        </div>
                        <div><span>${shippingCost.toFixed(2)}</span></div>
                      </div>
                      {(saleTax !== 0) && <div className="cost_container">
                        <div>
                          <h5>Sale Tax</h5>
                        </div>
                        <div><span>${saleTax.toFixed(2)}</span></div>
                      </div>}
                      <div className="cost_container">
                        <div>
                          <h5>Subtotal</h5>
                        </div>
                        <div><span>${subTotal.toFixed(2)}</span></div>
                      </div>
                    </div>
                    <div>
                      <div className="cost_container">
                        <div>
                          <h1>Total</h1>
                        </div>
                        <div><span>${total.toFixed(2)}</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="forms_checkout">
            <div>{rederTab()}</div>
          </div>
        </div>
        <div className="display_normal">
          <div id="car_item_checkout">
            {devices.map((element, index) => (
              <ElementToPayComponent key={index} {...element} />
            ))}
          </div>
          <div className="car_item_checkout-costs">
            <div className="cost_container">
              <div>
                <h5>Cost of shipping</h5>
              </div>
              <div><span>${shippingCost.toFixed(2)}</span></div>
            </div>
            {(saleTax !== 0) && <div className="cost_container">
              <div>
                <h5>Sale Tax</h5>
              </div>
              <div><span>${saleTax.toFixed(2)}</span></div>
            </div>}
            <div className="cost_container">
              <div>
                <h5>Subtotal</h5>
              </div>
              <div><span>${subTotal.toFixed(2)}</span></div>
            </div>
          </div>
          <div>
            <div className="cost_container">
              <div>
                <h1>Total</h1>
              </div>
              <div><span>${total.toFixed(2)}</span></div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default CheckOutComponent;
