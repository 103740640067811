import { Base64 } from 'js-base64';

const encryptInformation = (pInformation) => {
  let informatoToEnpryt = JSON.stringify(pInformation);
  let infomationEncryped = Base64.encode(informatoToEnpryt)
  return infomationEncryped;
}

const dcryptInformation = (pInformation) => {
  try{
    let infomationDecryped = Base64.decode(pInformation);
    let json = JSON.parse(infomationDecryped);
    return json;
  }catch(e) {
    return '';
  }
}
export {encryptInformation,dcryptInformation};
