import React, { useState, useRef, useEffect } from 'react';
import {
  ProducHook,
  ProductSC,
  ProductCoverArea,
  SCColorBlack,
  SCColorBlue,
  SCColorPink,
  SCColorWhite,
  SCMosquitos,
  SCSpraying,
  SCStrapBlack,
  SCStrapBlue,
  SCStrapWhite,
  SCStrapPink,
  SCStrapAdapter,
  SCPlugBlack,
  SCPlugBlue,
  SCPlugPink,
  SCPlugWhite,
  SCColorBlack2,
  SCColorBlue2,
  SCColorPink2,
  SCColorWhite2,
} from '../../components/imagesComponents';
import { getCookie } from '../../services/CookiesServices';
import ProductView from './ProductView';
import generateRequest from '../../services/RequestService';

const devices = [
  {
    id: 1,
    img: SCColorBlack2,
    name: 'SprayCare',
    color: '#000',
    price: 23.00, // Current price using for operations Mutable Value
    basePrice: 23.00, // Original price/ default price
    wholesaleprice: 25.99, // Price when you buy 25 devices
    colorName: 'BLACK',
  },
  {
    id: 2,
    img: SCColorBlue2,
    name: 'SprayCare',
    color: '#0057b8',
    price: 23.00, // Current price using for operations Mutable Value
    basePrice: 23.00, // Original price/ default price
    wholesaleprice: 25.99, // Price when you buy 25 devices
    colorName: 'BLUE',
  },
  {
    id: 3,
    img: SCColorWhite2,
    name: 'SprayCare',
    color: '#fff',
    price: 23.00, // Current price using for operations Mutable Value
    basePrice: 23.00, // Original price/ default price
    wholesaleprice: 25.99, // Price when you buy 25 devices
    colorName: 'WHITE',
  },
  {
    id: 4,
    img: SCColorPink2,
    name: 'SprayCare',
    color: '#FFA1C6',
    price: 23.00, // Current price using for operations Mutable Value
    basePrice: 23.00, // Original price/ default price
    wholesaleprice: 25.99, // Price when you buy 25 devices
    colorName: 'PINK',
  },
];


const ProductComponent = () => {
  const [backerEmail, setBackerEmail] = useState('');
  const [backerNumber, setBackerNumber] = useState('');
  const [addStrapAdapter, setAddStrapAdapter] = useState(false);
  const [addStrapPink, setAddStrapPink] = useState(false);
  const [addStrapBlue, setAddStrapBlue] = useState(false);
  const [addStrapBlack, setAddStrapBlack] = useState(false);
  const [addStrapWhite, setAddStrapWhite] = useState(false);
  const [addPlugBlack, setAddPlugBlack] = useState(false);
  const [addPlugBlue, setAddPlugBlue] = useState(false);
  const [addPlugPink, setAddPlugPink] = useState(false);
  const [addPlugWhite, setAddPlugWhite] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [idReloj, setIdReloj] = useState(null);
  const [addonsSelected, setAddonsSelected] =  useState([])
  const [selectedColor, setSelectedColor] = useState({color:null, colorValue: 'transparent', images: SCColorBlack});
  const [discount, setDiscount] = useState(false)

  const wholesaleprice = (d) => {
    if (d) {
      for (let i = 0; i < devices.length; i++) {
        devices[i].price = 25.99
      }
    }else{
      for (let i = 0; i < devices.length; i++) {
        devices[i].price = 23.00
      }
    }
  }

  const setColor = (color) => {
    if (color === 0 || color === '0') {
      setSelectedColor({color:'Black', colorValue: '#000000', images: SCColorBlack});
    }else if(color === 1 || color === '1'){
      setSelectedColor({color:'Blue', colorValue: '#123772', images: SCColorBlue});
    }else if(color === 2 || color === '2'){
      setSelectedColor({color:'White', colorValue: '#bfbfbf', images: SCColorWhite});
    }else if(color === 3|| color === '3'){
      setSelectedColor({color:'Pink', colorValue: '#FFA1C6', images: SCColorPink});
    }else{
      setSelectedColor({color:null, colorValue: 'transparent'});
    }
    }

  const plusLess = (type) => {
    let button = document.querySelector('#add_car');

    // eslint-disable-next-line default-case
    let newQuantity = 0
    switch (type) {
      case 'pluss':
        newQuantity = quantity + 1
        if (newQuantity > 25){
          break;
        }
        if (newQuantity == 5){
          newQuantity = 25
          setDiscount(true)
          wholesaleprice(true)
        }
        setQuantity(newQuantity);
        if (button.classList.contains('disabled') && idReloj !== null)
          button.classList.remove('disabled');
        break;
      case 'less':
        newQuantity = quantity - 1
        if (newQuantity == 0){
          setQuantity(newQuantity);
          button.classList.add('disabled');
          break;
        }

        if (quantity > 0) {
          if (newQuantity == 24){
            newQuantity = 4
            setDiscount(false)
            wholesaleprice(false)
          }
          setQuantity(newQuantity);
        }

        if (newQuantity == 1) button.classList.add('disabled');
        break;
    }
  };

  const selectItem = (e) => {
    let button = document.querySelector('#add_car');
    let selectedElements = document.querySelectorAll(
      '.producto_container-colors > ul >li'
    );
    for (let element of selectedElements) {
      if (element.classList.contains('selected'))
        element.classList.remove('selected');
    }
    let { currentTarget } = e;
    if (quantity > 0) button.classList.remove('disabled');
    currentTarget.classList.add('selected');
    setIdReloj(currentTarget.getAttribute('data-index'));
    setColor(currentTarget.getAttribute('data-index'))
  };
  const onHandleBackerNumber = (e) =>{
    const re = /^[0-9\b]+$/;
    if(e.target.value === '' || re.test(e.target.value)) {
      setBackerNumber(e.target.value)
    }
  }

  const saveBackerNumber = () => {
    if(backerEmail !== '' && backerNumber !== ''){
      generateRequest("api/user/backers-data", "POST", {backerEmail, backerNumber}, true)
      .then(({isExist}) => {
        if (isExist) {
          document.cookie = `backer_validation=${backerEmail};expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
          document.cookie = `backer_number=${backerNumber};expires=Fri, 31 Dec 9999 23:59:59 GMT;`;
          addShoppingCart()
        }else{
          addShoppingCart()
          document.cookie = "backer_validation=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          document.cookie = "backer_number=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
      })
      .catch((e) => console.log(e, "error"));
    }else{
    document.cookie = "backer_validation=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    addShoppingCart()
    }
  }

  const testFunction = async () => {
      generateRequest("api/user/backers-data", "POST", {backerEmail, backerNumber}, true)
      .then(({isExist}) => {
        if (isExist) {
          console.log(isExist)
        }
        })
        .catch((e) => console.log(e, "error"));
  }
  // const testFunction = () => {
  //     console.log('object')
  // }
  //💡 No representa ningun error por el momento pero hay que hecharle un ojo a esto
  useEffect(() => {
    if(window.location.pathname === '/product'){
      setIdReloj(0);
      setColor(0);
    }
  }, [])

  useEffect(() => {
    let buttonCart = document.querySelector('#add_car');
    if (addonsSelected.length > 0){
      buttonCart.classList.remove('disabled');
    }else{
      buttonCart.classList.add('disabled');
    }
}, [addonsSelected]);



  const passAddon = (position) => {
    setAddonsSelected(addonsSelected => [...addonsSelected, addOn[position]])
  }

  const removeAddOn = (itemId) => {
    setAddonsSelected(addonsSelected.filter(({ id }) => id !== itemId));
  }

  useEffect(() => {
    addStrapAdapter ? passAddon(4) : removeAddOn("54dd0n");
}, [addStrapAdapter]);
  useEffect(() => {
    addStrapPink ? passAddon(2) : removeAddOn("64dd0n");
}, [addStrapPink]);
  useEffect(() => {
    addStrapBlue ? passAddon(1) : removeAddOn("74dd0n");
}, [addStrapBlue]);
  useEffect(() => {
    addStrapBlack ? passAddon(0) : removeAddOn("84dd0n");
}, [addStrapBlack]);
  useEffect(() => {
    addStrapWhite ? passAddon(3) : removeAddOn("94dd0n");
}, [addStrapWhite]);
  useEffect(() => {
    addPlugBlack ? passAddon(5) : removeAddOn("addPlugBlack");
}, [addPlugBlack]);
  useEffect(() => {
    addPlugBlue ? passAddon(6) : removeAddOn("addPlugBlue");
}, [addPlugBlue]);
  useEffect(() => {
    addPlugPink ? passAddon(7) : removeAddOn("addPlugPink");
}, [addPlugPink]);
  useEffect(() => {
    addPlugWhite ? passAddon(8) : removeAddOn("addPlugWhite");
}, [addPlugWhite]);

  const addOn = [
    {
      id:"84dd0n",
      img: SCStrapBlack,
      color: '#000000',
      name: 'Black Strap',
      text:'Black Strap',
      checked:addStrapBlack,
      price:6.99,
      quantity: 1,
      setChecked:(() => {setAddStrapBlack(!addStrapBlack)}),
      colorName: 'BLACK',
    },
    {
      id:"74dd0n",
      img: SCStrapBlue,
      color: '#123772',
      name: 'Blue Strap',
      text:'Blue Strap',
      checked:addStrapBlue,
      price:6.99,
      quantity: 1,
      setChecked:(() => {setAddStrapBlue(!addStrapBlue)}),
      colorName: 'BLUE',
    },
    {
      id:"64dd0n",
      img: SCStrapPink,
      color: '#FFA1C6',
      name: 'Pink Strap',
      text:'Pink Strap',
      checked:addStrapPink,
      price:6.99,
      quantity: 1,
      setChecked:(() => {setAddStrapPink(!addStrapPink)}),
      colorName: 'PINK',
    },
    {
      id:"94dd0n",
      img: SCStrapWhite,
      color: '#ffffff',
      name: 'White Strap',
      text:'White Strap',
      checked:addStrapWhite,
      price:6.99,
      quantity: 1,
      setChecked:(() => {setAddStrapWhite(!addStrapWhite)}),
      colorName: 'WHITE',
    },
    {
      id:"54dd0n",
      img: SCStrapAdapter,
      color: '#000000',
      name: 'Strap Adapter',
      text:'Strap Adapter',
      checked:addStrapAdapter,
      price:6.99,
      quantity: 1,
      setChecked:(() => {setAddStrapAdapter(!addStrapAdapter)}),
      colorName: 'BLACK',
    },
    {
      id:"104dd0n",
      img: SCPlugBlack,
      color: '#000000',
      name: 'Black Plug',
      text:'Black Plug',
      checked:addPlugBlack,
      price:1.5,
      quantity: 1,
      setChecked:(() => {setAddPlugBlack(!addPlugBlack)}),
      colorName: 'BLACK',
    },
    {
      id:"114dd0n",
      img: SCPlugBlue,
      color: '#123772',
      name: 'Blue Plug',
      text:'Blue Plug',
      checked:addPlugBlue,
      price:1.5,
      quantity: 1,
      setChecked:(() => {setAddPlugBlue(!addPlugBlue)}),
      colorName: 'BLUE',
    },
    {
      id:"124dd0n",
      img: SCPlugPink,
      color: '#FFA1C6',
      name: 'Pink Plug',
      text:'Pink Plug',
      checked:addPlugPink,
      price:1.5,
      quantity: 1,
      setChecked:(() => {setAddPlugPink(!addPlugPink)}),
      colorName: 'PINK',
    },
    {
      id:"134dd0n",
      img: SCPlugWhite,
      color: '#ffffff',
      name: 'White Plug',
      text:'White Plug',
      checked:addPlugWhite,
      price:1.5,
      quantity: 1,
      setChecked:(() => {setAddPlugWhite(!addPlugWhite)}),
      colorName: 'WHITE',
    },
  ]

  const addShoppingCart = () => {
    let selectedAddons = Object.keys(addonsSelected).map(function(addon) {
      return {
        id: addonsSelected[addon].id,
        img: addonsSelected[addon].img,
        name: addonsSelected[addon].name,
        color: addonsSelected[addon].color,
        quantity: addonsSelected[addon].quantity,
        price: addonsSelected[addon].price,
        colorName: addonsSelected[addon].colorName,
        total: parseFloat((addonsSelected[addon].quantity * addonsSelected[addon].price).toFixed(2)),
      }
    });

    let devicesStored = getCookie('device_order');
    let deviceSelected = window.location.pathname === '/product' ? devices[idReloj] : addOn[0];

    let { id, img, name, price, basePrice, color, colorName, wholesaleprice } = deviceSelected;
    let objectCookie = [];

    let total = parseInt(quantity) * price;
    if (devicesStored != '') {
      let parserDevice = JSON.parse(devicesStored);
      objectCookie = parserDevice;
      let parserAddons = JSON.parse(devicesStored);
      objectCookie = parserAddons;
      let deviceFounded = objectCookie.map(function (d) {
          return d.id;
        }).indexOf(id);
      if (deviceFounded >= 0) {
        objectCookie[deviceFounded].quantity = parseInt(quantity);
        objectCookie[deviceFounded].total = parseFloat(total).toFixed(2);
      } else {
        objectCookie.push({
          id,
          img,
          name,
          color,
          quantity: quantity,
          price,
          basePrice,
          wholesaleprice,
          colorName,
          total: parseFloat(total.toFixed(2)),
        });
      }
      if(selectedAddons.length > 0){
          let parsedDevicesStored = JSON.parse(devicesStored);
          for(let sA = 0; sA < selectedAddons.length; sA++){
            let isExist = false;
            for(let dS = 0; dS < parsedDevicesStored.length; dS++){
              if(selectedAddons[sA].id === parsedDevicesStored[dS].id)
              isExist = true;
            }

            if(!isExist){
              objectCookie.push(selectedAddons[sA])
            }

          }
        }
    } else {
      objectCookie.push({
        id,
        img,
        name,
        color,
        quantity: quantity,
        price,
        basePrice,
        wholesaleprice,
        colorName,
        total: parseFloat(total.toFixed(2)),
      });
      if(selectedAddons.length > 0){
        for(let addons = 0; addons < selectedAddons.length; addons++){
          objectCookie.push(selectedAddons[addons]);
        }
      }
    }

    let objectParse = JSON.stringify(objectCookie);
    document.cookie = `device_order=${objectParse};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    window.location.href = '/shopping_car';
  };

  const addJustAddons = () => {
    let selectedAddons = Object.keys(addonsSelected).map(function(addon) {
      return {
        id: addonsSelected[addon].id,
        img: addonsSelected[addon].img,
        name: addonsSelected[addon].name,
        color: addonsSelected[addon].color,
        quantity: addonsSelected[addon].quantity,
        price: addonsSelected[addon].price,
        colorName: addonsSelected[addon].colorName,
        total: parseFloat((addonsSelected[addon].quantity * addonsSelected[addon].price).toFixed(2)),
      }
    });
    let devicesStored = getCookie('device_order');
    let objectCookie = [];

    if(devicesStored != ''){
      if(selectedAddons.length > 0){
        let parserAddons = JSON.parse(devicesStored);
        objectCookie = parserAddons;
        let parsedDevicesStored = JSON.parse(devicesStored);
        for(let sA = 0; sA < selectedAddons.length; sA++){
          let isExist = false;
          for(let dS = 0; dS < parsedDevicesStored.length; dS++){
            if(selectedAddons[sA].id === parsedDevicesStored[dS].id)
            isExist = true;
          }

          if(!isExist){
            objectCookie.push(selectedAddons[sA])
          }

        }
      }
    }else{
      if(selectedAddons.length > 0){
        for(let addons = 0; addons < selectedAddons.length; addons++){
          objectCookie.push(selectedAddons[addons]);
        }
      }
    }

    let objectParse = JSON.stringify(objectCookie);
    document.cookie = `device_order=${objectParse};expires=Fri, 31 Dec 9999 23:59:59 GMT`;
    window.location.href = '/shopping_car';
  }

  let spraycareWatches = useRef();
  const changeNavColor = () => {
    let navBar = document.getElementById('navBar');
    if (spraycareWatches.current.getBoundingClientRect().top <= 0) {
      navBar.style.backgroundColor = 'white';
    } else {
      navBar.style.backgroundColor = 'transparent';
    }
  };
  window.addEventListener('scroll', changeNavColor);
  return (
    <>
{
  window.location.pathname === "/product" ? (
    <ProductView
      quantity={quantity}
      idReloj={idReloj}
      length={addonsSelected.length}
      selectedColor={selectedColor}
      plusLess={plusLess}
      selectItem={selectItem}
      addOn={addOn}
      addShoppingCart={addShoppingCart}
      addJustAddons={addJustAddons}
      spraycareWatches={spraycareWatches}
      imageProduct2={SCMosquitos}
      imageProduct3={SCSpraying}
      thumbEnable
      enableAccesories
      enableColorChoose
      discount={discount}
      funcionCualquiera={getCookie('device_order')}
    ></ProductView>
  ) : (
    <ProductView
      quantity={quantity}
      idReloj={idReloj}
      length={addonsSelected.length}
      selectedColor={selectedColor}
      plusLess={plusLess}
      selectItem={selectItem}
      addOn={addOn}
      addShoppingCart={addShoppingCart}
      addJustAddons={addJustAddons}
      spraycareWatches={spraycareWatches}
      addonImage={SCStrapAdapter}
      backerInput
      backerNumber={backerNumber}
      setBackerNumber={onHandleBackerNumber}
      backerEmail={backerEmail}
      setBackerEmail={(e) => setBackerEmail(e.target.value)}
      saveBackerNumber={saveBackerNumber}
      testFunction={testFunction}
    ></ProductView>
  )
}

      <section className='product-gallery'>
        <div className="produc-photo">
          <p className="product-text-box">
            Strap <br/> Adapter
          </p>
          <img src={ProducHook} alt=""/>
        </div>
        <div className="produc-photo">
          <p className="product-text-box">
            Elegant & <br/> Convenient
          </p>
          <img src={ProductSC} alt=""/>
        </div>
        {window.location.pathname === '/product' &&
        <div className="produc-photo">
          <p className="product-text-box">
            Reachable <br/> Area
          </p>
          <img src={ProductCoverArea} alt=""/>
        </div>}
      </section>
    </>
  );
};

export default ProductComponent;
