import React from 'react';
import FooterAppp from '../../components/footerApp';
import CheckOutComponent from './CheckOutComponent';
const Checkout = ({history}) => {
  return (
    <React.Fragment>
      <CheckOutComponent history={history} />
      <FooterAppp />
    </React.Fragment>
  );
};

export default Checkout;
