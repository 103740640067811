import React from 'react';

export const Frame = require('../../assets/img/Frame.png');
export const aboutPic1 = require('../../assets/img/about.jpg');
export const aboutPic2 = require('../../assets/img/about2.jpg');
export const aboutPic3 = require('../../assets/img/about3.jpg');
export const aboutPic4 = require('../../assets/img/about6.png');
export const aboutPic5 = require('../../assets/img/SC-042021.png');
export const troubleshooting1 = require('../../assets/img/trouble1.png');
export const troubleshooting2 = require('../../assets/img/trouble2.png');
export const troubleshooting3 = require('../../assets/img/corrosive.png');
export const troubleshooting4 = require('../../assets/img/AvoidThis.png');
export const troubleshooting5 = require('../../assets/img/Recomendations.png');
export const troubleshooting1Mobile = require('../../assets/img/trouble1-mobile.png');
export const troubleshooting2Mobile = require('../../assets/img/trouble2-mobile.png');
export const troubleshooting3Mobile = require('../../assets/img/corrosive-mobile.png');
export const troubleshooting4Mobile = require('../../assets/img/AvoidThis-mobile.png');
export const troubleshooting5Mobile = require('../../assets/img/Recomendations-mobile.png');
export const Group_114 = require('../../assets/img/Group_114.png');
export const Union = require('../../assets/img/Union.png');
export const Ellipse_24 = require('../../assets/img/Ellipse_24.png');
export const Street = require('../../assets/img/street.png');
export const Subway = require('../../assets/img/subway.png');
export const Doors = require('../../assets/img/doors.png');
export const SprayCareBand = require('../../assets/img/SprayCareSpraying.png');
export const SprayCareBandVideo = require('../../assets/img/SprayCareVideo.mp4');
export const SprayCareBandVideoPhone = require('../../assets/img/SprayCareVideo-small.mp4');
export const SprayCareBandFeatures = require('../../assets/img/SprayCareFeaturesLarge.png');
export const BackUsButton = require('../../assets/img/comingsoon.png');
export const SprayCareBandFeaturesMobile = require('../../assets/img/SprayCareBandFeaturesMobile.png');
export const KickstarterBadge = require('../../assets/img/KickstarterBadge.png');

export const Car = require('../../assets/img/car.png');
export const Group_118 = require('../../assets/img/Group_118.png');
export const CommonSprayers = require('../../assets/img/CommonSprayers.png');
export const SprayCareReachableArea = require('../../assets/img/SprayCareReachableArea.png');
export const SprayCare = require('../../assets/img/SprayCareAtomizer.png');
export const HandsPicture = require('../../assets/img/hands_picture.png');
export const HandsPictureMobile = require('../../assets/img/hands_picture-medium.png');
export const Group_93 = require('../../assets/img/Group_93.png');
export const Group_94 = require('../../assets/img/Group_94.png');
export const Family = require('../../assets/img/family.png');
export const Testimonio1 = require('../../assets/img/testimonio1.png');
export const Testimonio2 = require('../../assets/img/testimonio2.png');
export const Testimonio3 = require('../../assets/img/testimonio3.png');
export const Testimonio4 = require('../../assets/img/testimonio4.png');
export const Group_119 = require('../../assets/img/Group_119.png');
export const BuySprayCare = require('../../assets/img/buySprayCare.png');
export const SprayCareSprayers = require('../../assets/img/SprayCareSprayers.png');
export const SprayCareSprayersMedium = require('../../assets/img/SprayCareSprayers-Medium.png');


export const Reloj = require('../../assets/img/reloj.png');
export const Right = require('../../assets/img/right.svg');
export const AccountCircle = require('../../assets/img/account-circle.png');


export const FtDisinfectant = require('../../assets/img/SC-ft-disinfectant.png');
export const FtRepellent = require('../../assets/img/SC-ft-repellent.png');
export const FtOils = require('../../assets/img/SC-ft-oils.png');
export const FtAromatherapy = require('../../assets/img/SC-ft-aromatherapy.png');
export const FeatParts = require('../../assets/img/SC-parts.png');
export const FeatHooks = require('../../assets/img/SC-hooks.png');
export const FeatAdvantage = require('../../assets/img/SC-advantages.png');
export const SmartwatchFront = require('../../assets/img/Smartwatch-front.png');
export const SmartwatchSide = require('../../assets/img/Smartwatch-side.png');
export const SprayCareFront = require('../../assets/img/SC-front.png');
export const SprayCareSide = require('../../assets/img/SC-side.png');
export const SprayCareBannerFeaturesVideo = require('../../assets/img/spraycare-banner-features-video.mp4');
export const SprayCareBannerFeaturesVideoTablet = require('../../assets/img/spraycare-banner-features-video-medium.mp4');
export const SprayCareBannerFeaturesVideoMobile = require('../../assets/img/spraycare-banner-features-video-small.mp4');
export const CriticSlash = require('../../assets/img/critic-slash.png');
export const CriticTrendHunter = require('../../assets/img/critic-trendhunter.png');
export const CriticYanko = require('../../assets/img/critic-yanko.png');
export const CheckIcon = require('../../assets/img/ant-design_check-circle-filled.svg');
export const ProducHook = require('../../assets/img/SC-Product-Hook.png');
export const ProductSC = require('../../assets/img/SC-Product-SC.png');
export const ProductCoverArea = require('../../assets/img/SC-Product-Cover-Area.png');
export const SCColorBlack = require('../../assets/img/SC-BlackColor.png');
export const SCColorBlue = require('../../assets/img/SC-BlueColor.png');
export const SCColorPink = require('../../assets/img/SC-PinkColor.png');
export const SCColorWhite = require('../../assets/img/SC-WhiteColor.png');
export const SCColorBlack2 = require('../../assets/img/SCBlackColor.png');
export const SCColorBlue2 = require('../../assets/img/SCBlueColor.png');
export const SCColorPink2 = require('../../assets/img/SCPinkColor.png');
export const SCColorWhite2= require('../../assets/img/SCWhiteColor.png');
export const SCStrapBlack = require('../../assets/img/SCStrapBlack.png');
export const SCStrapBlue = require('../../assets/img/SCStrapBlue.png');
export const SCStrapWhite = require('../../assets/img/SCStrapWhite.png');
export const SCStrapPink = require('../../assets/img/SCStrapPink.png');
export const SCStrapAdapter = require('../../assets/img/SCStrapAdapter.png');
export const SCPlugBlack = require('../../assets/img/SCPlugBlack.png');
export const SCPlugBlue = require('../../assets/img/SCPlugBlue.png');
export const SCPlugPink = require('../../assets/img/SCPlugPink.png');
export const SCPlugWhite = require('../../assets/img/SCPlugWhite.png');
export const SCMosquitos = require('../../assets/img/SC-Mosquitos.png');
export const SCSpraying = require('../../assets/img/SC-Spraying.png');
export const AmazonBadge = require('../../assets/img/AmazonBadge.svg');
export const Comment0 = require('../../assets/img/Comment0.webp');
export const Comment1 = require('../../assets/img/Comment1.webp');
export const Comment2 = require('../../assets/img/Comment2.webp');
export const Comment3 = require('../../assets/img/Comment3.webp');
export const Comment4 = require('../../assets/img/Comment4.webp');
export const Comment5 = require('../../assets/img/Comment5.webp');
export const StrapAdapter = require('../../assets/img/StrapAdapter.mp4');

// Manuals
export const ManualES = require('../../assets/pdf/SprayCare-Manual-ES.pdf');
export const ManualEN = require('../../assets/pdf/SprayCare-Manual-EN.pdf');
export const ManualJP = require('../../assets/pdf/SprayCare-Manual-JP.pdf');
