import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import ProductComponent from './ProductComponent';
const Product = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <ProductComponent />
      <FooterAppp />
    </React.Fragment>
  );
};

export default Product;
