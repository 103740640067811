import React from 'react';
import Navbar from '../../components/navbar';
import Terms from './Terms';
const Product = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <Terms />
    </React.Fragment>
  );
};

export default Product;
