import React from 'react';

const SpinnerComponent = ({color="white", height="100%"}) => {
  return (
    <>
      <div style={{backgroundColor:color, height: height}} className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </>
  )
}
export default SpinnerComponent;
