import React from 'react';
import { Link } from 'react-router-dom';
// import './footer.css';

const FooterApp = ({padding="0px"}) => {
  return (
    <React.Fragment>
      <footer style={{paddingBottom:padding}}>
        <div>
          <div>
            <ul>
              <li>
                <h4 className="heading-quinary">Company Info</h4>
              </li>
              <li>
                <Link className="paragraph paragraph--tertiary paragraph--white" to="policy">
                  Privacy Policy
                </Link>
                </li>
              <li>
              <Link className="paragraph paragraph--tertiary paragraph--white" to="terms">
              Terms & Conditions
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <h4 className="heading-quinary">Costumer Service</h4>
              </li>
              <li className="paragraph paragraph--tertiary">
                <Link
                  className="paragraph paragraph--tertiary paragraph--white"
                  to="troubleshooting"
                >
                  Troubleshooting
                </Link>
              </li>
              <li className="paragraph paragraph--tertiary">
                <Link
                  className="paragraph paragraph--tertiary paragraph--white"
                  to="refund"
                >
                  Warranty & Return
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link className="paragraph paragraph--tertiary paragraph--white" to="contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div>
          <p className="paragraph paragraph--tertiary paragraph--white">&copy;2023 SprayCare band. All rights reserved</p>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default FooterApp;
