import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import ShoppingCarComponent from './ShoppingCarComponent';
import { getNumberDevices } from '../../services/CookiesServices';
const ShoppingCar = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <ShoppingCarComponent  />
      <FooterAppp />
    </React.Fragment>
  );
};

export default ShoppingCar;
