import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import Survey from './survey';
const FrequenceQuestions = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <Survey />
      <FooterAppp />
    </React.Fragment>
  );
};

export default FrequenceQuestions;
