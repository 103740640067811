import states from "../assets/states/states.json";
import statesNamesAndAbbreviations from "../assets/states/statesNames.json";
const getStates = (pCode) => {
  return states[pCode.toUpperCase()];
};

const getCountryAbbreviation = (countryName) => {
  const countryNameIndex = Object.values(statesNamesAndAbbreviations).indexOf(
    countryName
  );
  return Object.keys(statesNamesAndAbbreviations)[countryNameIndex];
};

const getCountryName = (countryAbbr) => {
  const countryAbbrIndex = Object.keys(statesNamesAndAbbreviations).indexOf(countryAbbr);
  return Object.values(statesNamesAndAbbreviations)[countryAbbrIndex];
};

const getStateAbbreviation = (arr, stateName) => {
  for (let i = 0; i < arr.length; i++) {
    const position = arr[i].name;
    if(position === stateName){
      return arr[i].abbreviation;
    }
  }
};

const getStateName = (stateAbbr) => {
  const stateAbbrIndex = Object.keys(statesNamesAndAbbreviations).indexOf(stateAbbr);
  return Object.values(statesNamesAndAbbreviations)[stateAbbrIndex];
};

export {
  getStates,
  getCountryAbbreviation,
  getCountryName,
  getStateAbbreviation,
  getStateName,
};
