import React from "react";
import SpinnerComponent from '../../components/spinner';

export function BackerFormData(props) {
  return (
    <div className="backer-form-data">
      <form
        id="BackerItems"
        onSubmit={props.handleSubmit}
        className="forms forms-fluid"
        >
        {props.processing && <SpinnerComponent color='#ffffff' height="98%"/>}
        <h3>
          Please enter your email and your backer number, or contribution ID to answer the survey
        </h3>
        <br />
        <br />
        <div>
          <input
            type="email"
            name="backerEmail"
            placeholder="Email"
            value={props.backerEmail}
            onChange={props.onHandleBackerEmail}
            required />
          <label placeholder="Email"></label>
        </div>
        <div>
          <input
            type="text"
            name="backerNumber"
            placeholder="Backer Number or Contribution ID"
            value={props.backerNumber}
            onChange={props.onHandleBackerNumber}
            required />
          <label placeholder="Backer Number or Contribution ID"></label>
        </div>
        <div style={{marginBottom: props.displayAlert ? '35px' : '0px', transition: 'all cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.2s'}}>
        <p className={"checkout-label-paragraph--hide"} style={{transform: props.displayAlert ? 'scaleY(1)' : 'scaleY(0)', top: props.displayAlert ? '110%' : '100%', fontWeight: '600'}}>{props.message}</p>
        </div>
        <button
          id="add_car"
          className={`largeButtonOrder ${props.disabled ? 'disabled' : ''}`}
          type="submit"
          form="BackerItems"
          disabled={props.disabled}
        >
          ORDER NOW
        </button>
      </form>
    </div>
  );
}