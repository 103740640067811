import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import { getCookie } from '../services/CookiesServices';

export const PrivateRoute = ({
  isAuthenticated,
  component:Component,
  ...rest
}) => {
  return (
    <Route {...rest}
      component={(props) => (isAuthenticated)?(<Component {...props} />):(<Redirect to={"/login"} />)}
    />
   );
}
export const PublicRoute = ({
  isAuthenticated,
  component:Component,
  ...rest
}) => {
  return (
    <Route {...rest}
      component={(props) => (!isAuthenticated)?(<Component {...props} />):(<Redirect to={"/dashboard"} />)}
    />
   );
}

export const CheckoutPublicRoute = ({
  component:Component,
  ...rest
}) => {
  let devicesStored = getCookie('device_order');
  return (
    <Route {...rest}
      component={(props) => (devicesStored === '' || devicesStored === null || devicesStored === undefined || devicesStored === "[]")?(<Redirect to={"/product"} />):(<Component {...props} />)}
    />
   );
}
