import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import AboutComponent from './AboutComponent';
const Product = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <AboutComponent />
      <FooterAppp />
    </React.Fragment>
  );
};

export default Product;
