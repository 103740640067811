import React from 'react';

const PolicyComponent = () => {
  return (
    <React.Fragment>
      <section className="special_container">
      <div className="container termss">
        <div className="row">
          <div className="col-md-12">
            <h1 className="h1Terms">Privacy Policy</h1>
            <h3 className="h3LegalInformation">Legal Information</h3>
            <p className="termsParrafo">Last updated July 31, 2018</p>
            <div className="divTerms">
              <p className="termsParrafo">
                Thank you for choosing to be part of our community at Smart Projects LLC, doing business as SprayCare("<b>SprayCare”, “we”, “us”, or “our</b>"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at hello@spray.care
              </p>
              <p className="termsParrafo">
                When you visit our website spray.care, mobile application, Facebook application, and use our services, you trust us with your personal information.
                We take your privacy very seriously. In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect,
                how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy policy that you do not agree with,
                please discontinue use of our Sites or Apps and our services
              </p>
              <p className="termsParrafo">
                This privacy policy applies to all information collected through our website (such as spray.care), mobile application, Facebook application, ("<b>Apps</b>"), and/or any related services,
                sales, marketing or events (we refer to them collectively in this privacy policy as the "<b>Sites</b>").
              </p>
              <p className="termsParrafo">
                <b>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</b>
              </p>
              <p className="termsParrafo"><br />
              </p>
              <h3><b>TABLE OF CONTENTS</b></h3>
              <p className="termsParrafo">
                <br />
                <a href="#1" className="link">1. WHAT INFORMATION DO WE COLLECT?</a><br />
                <a href="#2" className="link">2. HOW DO WE USE YOUR INFORMATION?</a><br />
                <a href="#3" className="link">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a><br />
                <a href="#4" className="link">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a><br />
                <a href="#5" className="link">5. DO WE USE GOOGLE MAPS?</a><br />
                <a href="#6" className="link">6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a><br />
                <a href="#7" className="link">7. WHAT IS OUR STANCE ON THIRD­PARTY WEBSITES?</a><br />
                <a href="#8" className="link">8. HOW LONG DO WE KEEP YOUR INFORMATION?</a><br />
                <a href="#9" className="link">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a><br />
                <a href="#10" className="link">10. DO WE COLLECT INFORMATION FROM MINORS?</a><br />
                <a href="#11" className="link">11. WHAT ARE YOUR PRIVACY RIGHTS?</a><br />
                <a href="#12" className="link">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a><br />
                <a href="#13" className="link">13. DO WE MAKE UPDATES TO THIS POLICY?</a><br />
                <a href="#14" className="link">14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</a><br />
              </p>
              <h3 id={1}><b>
                  1. WHAT INFORMATION DO WE COLLECT?
                </b></h3>
              <p className="termsParrafo">
                <strong />
              </p>
              <p className="termsParrafo">
                <strong />
              </p>
              <h3><b>
                  Personal information you disclose to us
                </b></h3>
              <p className="termsParrafo">
                <strong />
              </p>
              <b id="infocollect">
                In Short: We collect personal information that you provide to us such
                as name, address, contact information, passwords and security data,
                payment information, and social media login data.
              </b>
              <p className="termsParrafo">
                <strong />
              </p>
              <p className="termsParrafo">
                We collect personal information that you voluntarily provide to us when
                registering at the Sites or Apps, expressing an interest in obtaining
                information about us or our products and services, when participating
                in activities on the Sites or Apps (such as posting messages in our
                online forums or entering competitions, contests or giveaways) or
                otherwise contacting us.
              </p>
              <p className="termsParrafo">
                The personal information that we collect depends on the context of your
                interactions with us and the Sites or Apps, the choices you make and
                the products and features you use. The personal information we collect
                can include the following:
              </p>
              <p className="termsParrafo">
                <strong>Name and Contact Data. </strong>
                We collect your first and last name, email address, postal address,
                phone number, and other similar contact data.
              </p>
              <p className="termsParrafo">
                <strong>Credentials. </strong>
                We collect passwords, password hints, and similar security information
                used for authentication and account access.
              </p>
              <p className="termsParrafo">
                <strong>Payment Data. </strong>
                We collect data necessary to process your payment if you make
                purchases, such as your payment instrument number (such as a credit
                card number), and the security code associated with your payment
                instrument. All payment data is stored by our payment processor and you
                should review its privacy policies and contact the payment processor
                directly to respond to your questions.
              </p>
              <p className="termsParrafo">
                <strong>Social Media Login Data. </strong>
                We provide you with the option to register using social media account
                details, like your Facebook, Twitter or other social media account. If
                you choose to register in this way, we will collect the Information
                described in the section called "
                <a href="#6" className="link">HOW DO WE HANDLE YOUR SOCIAL LOGINS</a>" below.
              </p>
              <p className="termsParrafo">
                All personal information that you provide to us must be true, complete
                and accurate, and you must notify us of any changes to such personal
                information.
              </p>
              <h3><b>
                  Information automatically collected
                </b></h3>
              <p className="termsParrafo">
                <strong />
              </p>
              <b id>
                In Short: Some information – such as IP address and/or browser and
                device characteristics – is collected automatically when you visit our
                Sites or Apps.
              </b>
              <p className="termsParrafo">
                <strong />
              </p>
              <p className="termsParrafo">
                We automatically collect certain information when you visit, use or
                navigate the Sites or Apps. This information does not reveal your
                specific identity (like your name or contact information) but may
                include device and usage information, such as your IP address, browser
                and device characteristics, operating system, language preferences,
                referring URLs, device name, country, location, information about how
                and when you use our Sites or Apps and other technical information.
                This information is primarily needed to maintain the security and
                operation of our Sites or Apps, and for our internal analytics and
                reporting purposes.
              </p>
              <br clear="all" />
              <div>
                <p className="termsParrafo">
                  Like many businesses, we also collect information through cookies and
                  similar technologies.
                </p>
                <h3 align="left"><b>
                    Information collected through our Apps
                  </b>
                </h3>
                <p className="termsParrafo">
                  <strong />
                </p>
                <b align="left">
                  In Short: We may collect information regarding your geo­location,
                  mobile device, push notifications, and Facebook permissions when you
                  use our apps.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  If you use our Apps, we may also collect the following information:
                </p>
                <p className="termsParrafo">
                </p><li>
                  <strong>Geo­Location Information. </strong>
                  We may request access or permission to and track location­based
                  information from your mobile device, either continuously or while you
                  are using our mobile application, to provide location­based services.
                  If you wish to change our access or permissions, you may do so in your
                  device’s settings.
                </li>
                <p />
                <p className="termsParrafo">
                </p><li>
                  <strong>Mobile Device Access. </strong>
                  We may request access or permission to certain features from your
                  mobile device, including your mobile device’s sms messages, social
                  media accounts, contacts, bluetooth, reminders, storage, location
                  services, and other features. If you wish to change our access or
                  permissions, you may do so in your device’s settings.
                </li>
                <p />
                <p className="termsParrafo">
                </p><li>
                  <strong>Mobile Device Data. </strong>
                  We may automatically collect device information (such as your mobile
                  device ID, model and manufacturer), operating system, version
                  information and IP address.
                </li>
                <p />
                <p className="termsParrafo">
                </p><li>
                  <strong>Push Notifications. </strong>
                  We may request to send you push notifications regarding your account or
                  the mobile application. If you wish to opt­out from receiving these
                  types of communications, you may turn them off in your device’s
                  settings.
                </li>
                <p />
                <p className="termsParrafo">
                </p><li>
                  <strong>Facebook Permissions. </strong>
                  We by default access your <u>Facebook</u> basic account information,
                  including your name, email, gender, birthday, current city, and profile
                  picture URL, as well as other information that you choose to make
                  public. We may also request access to other permissions related to your
                  account, such as friends, checkins, and likes, and you may choose to
                  grant or deny us access to each individual permission. For more
                  information regarding Facebook permissions, refer to
                </li>
                <p />
                <p className="termsParrafo">
                  the <u>Facebook Permissions Reference</u> page.
                </p>
                <h3><b>
                    Information collected from other sources
                  </b></h3>
                <p className="termsParrafo">
                  <strong />
                </p>
                <b align="left">
                  In Short: We may collect limited data from public databases, marketing
                  partners, social media platforms, and other outside sources.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We may obtain information about you from other sources, such as public
                  databases, joint marketing partners, social media platforms (such as
                  Facebook), as well as from other third parties. Examples of the
                  information we receive from other sources include: social media profile
                  information (your name, gender, birthday, email, current city, state
                  and country, user identification numbers for your contacts, profile
                  picture URL and any other information that you choose to make public);
                  marketing leads and search results and links, including paid listings
                  (such as sponsored links).
                </p>
                <h3 id={2}><b>
                    2. HOW DO WE USE YOUR INFORMATION?
                  </b></h3><br />
                <b>
                  In Short: We process your information for purposes based on legitimate
                  business interests, the fulfillment of our contract with you,
                  compliance with our legal obligations, and/or your consent.
                </b>
              </div>
              <br clear="all" />
              <div>
                <p className="termsParrafo">
                  We use personal information collected via our Sites or Apps for a
                  variety of business purposes described below. We process your personal
                  information for these purposes in reliance on our legitimate business
                  interests ("Business Purposes"), in order to enter into or perform a
                  contract with you ("Contractual"), with your consent ("Consent"),
                  and/or for compliance with our legal obligations ("Legal Reasons"). We
                  indicate the specific processing grounds we rely on next to each
                  purpose listed below.
                </p>
                <p className="termsParrafo">
                  We use the information we collect or receive:
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>To facilitate account creation and logon process </strong>
                  with your Consent. If you choose to link your account with us to a
                  third party account *(such as your Google or Facebook account), we use
                  the information you allowed us to collect from those third parties to
                  facilitate account creation and logon process. See the section below
                  headed "<a href="#6" className="link"><u>HOW DO WE HANDLE YOUR SOCIAL LOGINS</u></a>" for further
                  information.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>To send you marketing and promotional communications </strong>
                  for Business Purposes. We and/or our third party marketing partners may
                  use the personal information you send to us for our marketing purposes,
                  if this is in accordance with your marketing preferences. You can
                  opt­out of our marketing emails at any time (see the "<a href="#11" className="link"> <u>WHAT ARE YOUR PRIVACY RIGHTS</u></a>" below).
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>To send administrative information to you </strong>
                  for Business Purposes, Legal Reasons and/or possibly for Contractual.
                  We may use your personal information to send you product, service and
                  new feature information and/or information about changes to our terms,
                  conditions, and policies.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>Fulfill and manage your orders </strong>
                  for Contractual reasons. We may use your information to fulfill and
                  manage your orders, payments, returns, and exchanges made through the
                  Sites or Apps.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>To post testimonials </strong>
                  with your Consent. We post testimonials on our Sites or Apps that may
                  contain personal information. Prior to posting a testimonial, we will
                  obtain your consent to use your name and testimonial. If you wish to
                  update, or delete your testimonial, please contact us at<u> </u>and be
                  sure to include your name, testimonial location, and contact
                  information.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>Deliver targeted advertising to you </strong>
                  for our Business Purposes and/or with your Consent. We may use your
                  information to develop and display content and advertising (and work
                  with third parties who do so) tailored to your interests and/or
                  location and to measure its effectiveness.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>Request Feedback </strong>
                  for our Business Purposes and/or with your Consent. We may use your
                  information to request feedback and to contact you about your use of
                  our Sites or Apps.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>To enable user­to­user communications </strong>
                  with your Consent. We may use your information in order to enable
                  user­to­user communications with each user's consent.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>To enforce our terms, conditions and policies </strong>
                  for Business Purposes, Legal Reasons and/or possibly Contractual.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>For other Business Purposes. </strong>
                  We may use your information for other Business Purposes, such as data
                  analysis, identifying usage trends, determining the effectiveness of
                  our promotional campaigns and to evaluate and improve our Sites or
                  Apps, products, services, marketing and your
                </p>
                <p className="termsParrafo">
                  experience.
                </p>
                <h3 id={3}><b>
                    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
                  </b></h3>
              </div>
              <br clear="all" />
              <div>
                <hb align="left">
                  In Short: We only share information with your consent, to comply with
                  laws, to protect your rights, or to fulfill business obligations.
                </hb>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We only share and disclose your information in the following
                  situations:
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>Compliance with Laws. </strong>
                  We may disclose your information where we are legally required to do so
                  in order to comply with applicable law, governmental requests, a
                  judicial proceeding, court order, or legal process, such as in response
                  to a court order or a subpoena (including in response to public
                  authorities to meet national security or law enforcement requirements).
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>Vital Interests and Legal Rights. </strong>
                  We may disclose your information where we believe it is necessary to
                  investigate, prevent, or take action regarding potential violations of
                  our policies, suspected fraud, situations involving potential threats
                  to the safety of any person and illegal activities, or as evidence in
                  litigation in which we are involved.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>
                    Vendors, Consultants and Other Third­Party Service Providers.
                  </strong>
                  We may share your data with third party vendors, service providers,
                  contractors or agents who perform services for us or on our behalf and
                  require access to such information to do that work. Examples include:
                  payment processing, data analysis, email delivery, hosting services,
                  customer service and marketing efforts. We may allow selected third
                  parties to use tracking technology on the Sites or Apps, which will
                  enable them to collect data about how you interact with the Sites or
                  Apps over time. This information may be used to, among other things,
                  analyze and track data, determine the popularity of certain content and
                  better understand online activity. Unless described in this Policy, we
                  do not share, sell, rent or trade any of your information with third
                  parties for their promotional purposes.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>Business Transfers. </strong>
                  We may share or transfer your information in connection with, or during
                  negotiations of, any merger, sale of company assets, financing, or
                  acquisition of all or a portion of our business to another company.
                </p>
                <p className="termsParrafo">
                  ■
                  <strong>With your Consent. </strong>
                  We may disclose your personal information for any other purpose with
                  your consent.
                </p>
                <h3 id={4}><b>
                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                  </b></h3>
                <b>
                  In Short: We may use cookies and other tracking technologies to collect
                  and store your information.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We may use cookies and similar tracking technologies (like web beacons
                  and pixels) to access or store information. Specific information about
                  how we use such technologies and how you can refuse certain cookies is
                  set out in our Cookie Policy.
                </p>
                <h3 id={5}><b>
                    5. DO WE USE GOOGLE MAPS?
                  </b></h3>
                <b>
                  In Short: Yes, we use Google Maps for the purpose of providing better
                  service.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  This website, mobile application, or Facebook application uses Google
                  Maps APIs. You may find the Google Maps APIs Terms of Service     <a href="https://cloud.google.com/maps-platform/terms/" target="_blank" className="link">   <u>here</u></a>. To better understand Google’s Privacy Policy, please refer
                  to this <a href="https://policies.google.com/privacy" target="_blank" className="link"> <u>link</u></a>.
                </p>
                <p className="termsParrafo">
                  By using our Maps API Implementation, you agree to be bound by Google’s
                  Terms of Service. By using our implementation of the Google Maps APIs,
                  you agree to allow us to gain access to information about you including
                  personally identifiable information (such as usernames) and
                  non­personally identifiable information (such as location). We will be
                  collecting the following information:
                </p>
              </div>
              <br clear="all" />
              <div>
                <p className="termsParrafo">
                  ■ user's location to provide real time address verification.
                </p>
                <p className="termsParrafo">
                  For a full list of what we use information for, please see the previous
                  section titled "<a href="#2" className="link"><u>HOW DO WE USE</u> <u>YOUR INFORMATION?</u></a>" and “ <u><a href="#3" className="link">WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></u> ” You agree to
                </p>
                <p className="termsParrafo">
                  allow us to obtain or cache your location. You may revoke your consent
                  at anytime.
                </p>
                <p className="termsParrafo">
                  The Maps APIs that we use store and access cookies and other
                  information on your devices. If you are a user currently in the
                  European Union, please take a look at our EU User Consent Policy.
                </p>
                <h3 id={6}><b>
                    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                  </b></h3>
                <b>
                  In Short: If you choose to register or log in to our websites using a
                  social media account, we may have access to certain information about
                  you.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  Our Sites or Apps offer you the ability to register and login using
                  your third party social media account details (like your Facebook or
                  Twitter logins). Where you choose to do this, we will receive certain
                  profile information about you from your social media provider. The
                  profile Information we receive may vary depending on the social media
                  provider concerned, but will often include your name, e­mail address,
                  friends list, profile picture as well as other information you choose
                  to make public. If you login using Facebook, we may also request access
                  to other permissions related to your account, such as friends,
                  check­ins, and likes, and you may choose to grant or deny us access to
                  each individual permission.
                </p>
                <p className="termsParrafo">
                  We will use the information we receive only for the purposes that are
                  described in this privacy policy or that are otherwise made clear to
                  you on the Sites or Apps. Please note that we do not control, and are
                  not responsible for, other uses of your personal information by your
                  third party social media provider. We recommend that you review their
                  privacy policy to understand how they collect, use and share your
                  personal information, and how you can set your privacy preferences on
                  their sites and apps.
                </p>
                <h3 id={7}><b>
                    7. WHAT IS OUR STANCE ON THIRD­PARTY WEBSITES?
                  </b></h3>
                <b>
                  In Short: We are not responsible for the safety of any information that
                  you share with third­party providers who advertise, but are not
                  affiliated with, our websites.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  The Sites or Apps may contain advertisements from third parties that
                  are not affiliated with us and which may link to other websites, online
                  services or mobile applications. We cannot guarantee the safety and
                  privacy of data you provide to any third parties. Any data collected by
                  third parties is not covered by this privacy policy. We are not
                  responsible for the content or privacy and security practices and
                  policies of any third parties, including other websites, services or
                  applications that may be linked to or from the Sites or Apps. You
                  should review the policies of such third parties and contact them
                  directly to respond to your questions.
                </p>
                <h3 id={8}>
                  8. HOW LONG DO WE KEEP YOUR INFORMATION?
                </h3>
                <b>
                  In Short: We keep your information for as long as necessary to fulfill
                  the purposes outlined in this privacy policy unless otherwise required
                  by law.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We will only keep your personal information for as long as it is
                  necessary for the purposes set out in this privacy policy, unless a
                  longer retention period is required or permitted by law (such as tax,
                  accounting or other legal requirements). No purpose in this policy will
                  require us keeping your personal information for longer than 6 months
                  past the termination of the user's account.
                </p>
              </div>
              <br clear="all" />
              <div>
                <p className="termsParrafo">
                  When we have no ongoing legitimate business need to process your
                  personal information, we will either delete or anonymize it, or, if
                  this is not possible (for example, because your personal information
                  has been stored in backup archives), then we will securely store your
                  personal information and isolate it from any further processing until
                  deletion is possible.
                </p>
                <h3 id={9}>
                  9. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </h3>
                <b>
                  In Short: We aim to protect your personal information through a system
                  of organizational and technical security measures.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We have implemented appropriate technical and organizational security
                  measures designed to protect the security of any personal information
                  we process. However, please also remember that we cannot guarantee that
                  the internet itself is 100% secure. Although we will do our best to
                  protect your personal information, transmission of personal information
                  to and from our Sites or Apps is at your own risk. You should only
                  access the services within a secure environment.
                </p>
                <h3 id={10}>
                  10. DO WE COLLECT INFORMATION FROM MINORS?
                </h3>
                <b>
                  In Short: We do not knowingly collect data from or market to children
                  under 18 years of age.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We do not knowingly solicit data from or market to children under 18
                  years of age. By using the Sites or Apps, you represent that you are at
                  least 18 or that you are the parent or guardian of such a minor and
                  consent to such minor dependent’s use of the Sites or Apps. If we learn
                  that personal information from users less than 18 years of age has been
                  collected, we will deactivate the account and take reasonable measures
                  to promptly delete such data from our records. If you become aware of
                  any data we have collected from children under age 18, please contact
                  us at<u> </u>.
                </p>
                <h3 id={11}>
                  11. WHAT ARE YOUR PRIVACY RIGHTS?
                </h3>
                <b>
                  In Short: You may review, change, or terminate your account at any
                  time.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  If you are resident in the European Economic Area and you believe we
                  are unlawfully processing your personal information, you also have the
                  right to complain to your local data protection supervisory authority.
                  You can find their contact details here:
                  <u>
                    <a href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080" className="link">
                      http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080<u>/j</u>ustice/data
                    </a>
                    ­
                  </u>
                </p>
                <p className="termsParrafo">
                  <strong>Account Information</strong>
                </p>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  If you would at any time like to review or change the information in
                  your account or terminate your account, you can:
                </p>
                <p className="termsParrafo">
                  ■ Log into your account settings and update your user account.
                </p>
                <p className="termsParrafo">
                  ■ facebook login
                </p>
                <p className="termsParrafo">
                  ■ Contact us using the contact information provided.
                </p>
                <p className="termsParrafo">
                  Upon your request to terminate your account, we will deactivate or
                  delete your account and information from our active databases. However,
                  some information may be retained in our files to prevent fraud,
                  troubleshoot problems, assist with any investigations, enforce our
                  Terms of Use and/or comply with legal requirements.
                </p>
              </div>
              <br clear="all" />
              <div>
                <p className="termsParrafo">
                  <strong><u>Cookies and similar technolo</u>g<u>ies:</u> </strong>
                  Most Web browsers are set to accept cookies by default. If you prefer,
                  you can usually choose to set your browser to remove cookies and to
                  reject cookies. If you choose to remove cookies or reject cookies, this
                  could affect certain features or services of our Sites or Apps. To opt­
                  out of interest­based advertising by advertisers on our Sites or Apps
                  visit
                  <u>
                    <a href="http://www.aboutads.info/choices/" className="link">
                      http://www.aboutads.info/choices/<u>.</u>
                    </a>
                  </u>
                </p>
                <p className="termsParrafo">
                  ■
                  <strong><u>O</u>p<u>tin</u>g<u> out of email marketin</u>g: </strong>
                  You can unsubscribe from our marketing email list at any time by
                  clicking on the unsubscribe link in the emails that we send or by
                  contacting us using the details provided below. You will then be
                  removed from the marketing email list – however, we will still need to
                  send you service­related emails that are necessary for the
                  administration and use of your account. To otherwise opt­out, you may:
                </p>
                <p className="termsParrafo">
                  ■ Access your account settings and update preferences.
                </p>
                <h3 id={12}>
                  12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                </h3>
                <b>
                  In Short: Yes, if you are a resident of California, you are granted
                  specific rights regarding access to your personal information.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  California Civil Code Section 1798.83, also known as the “Shine The
                  Light” law, permits our users who are California residents to request
                  and obtain from us, once a year and free of charge, information about
                  categories of personal information (if any) we disclosed to third
                  parties for direct marketing purposes and the names and addresses of
                  all third parties with which we shared personal information in the
                  immediately preceding calendar year. If you are a California resident
                  and would like to make such a request, please submit your request in
                  writing to us using the contact information provided below.
                </p>
                <p className="termsParrafo">
                  If you are under 18 years of age, reside in California, and have a
                  registered account with the Sites or Apps, you have the right to
                  request removal of unwanted data that you publicly post on the Sites or
                  Apps. To request removal of such data, please contact us using the
                  contact information provided below, and include the email address
                  associated with your account and a statement that you reside in
                  California. We will make sure the data is not publicly displayed on the
                  Sites or Apps, but please be aware that the data may not be completely
                  or comprehensively removed from our systems.
                </p>
                <h3 id={13}>
                  13. DO WE MAKE UPDATES TO THIS POLICY?
                </h3>
                <b>
                  In Short: Yes, we will update this policy as necessary to stay
                  compliant with relevant laws.
                </b>
                <p className="termsParrafo">
                  <strong />
                </p>
                <p className="termsParrafo">
                  We may update this privacy policy from time to time. The updated
                  version will be indicated by an updated “Revised” date and the updated
                  version will be effective as soon as it is accessible. If we make
                  material changes to this privacy policy, we may notify you either by
                  prominently posting a notice of such changes or by directly sending you
                  a notification. We encourage you to review this privacy policy
                  frequently to be informed of how we are protecting your information.
                </p>
                <h3 id={14}>
                  14. HOW CAN YOU CONTACT US ABOUT THIS POLICY?
                </h3>
              </div>
              <br clear="all" />
              <p className="termsParrafo">
                If you have any further questions or comments about us or our policies,
                email us at <a href="mailto:hello@spray.care" className="link">hello@spray.care</a> or by
                post to:
              </p>
              <p className="termsParrafo">
                Smart Projects LLC
              </p>
              <p className="termsParrafo">
                P.O. Box 1876<br />Draper, UT 84020 <br />United States <br />
              </p>
              <h3>
                HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
              </h3>
              <p className="termsParrafo">
                Based on the laws of some countries, you may have the right to request
                access to the personal information we collect from you, change that
                information, or delete it in some circumstances. To request to review,
                update, or delete your personal information, please submit a request form
                by clicking <a href="contact" className="link"><u>here</u></a>. We will respond to your request within 15 days.
              </p>
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <h2 className="h3Footer">Copyright © 2020 Smart Projects. All rights reserved. SprayCare band ™</h2>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <a href="/">
          <button className="largeButtonOrder">HOME</button>
        </a>
      </section>
    </React.Fragment>
  );
};

export default PolicyComponent;
