import React from 'react';

import FooterAppp from '../../components/footerApp';
import RecoveryPasswordComponent from './RecoveryPasswordComponent';
const RecoveryPassword = ({match,location}) => {
  return (
    <>
     <RecoveryPasswordComponent match={match}/>
      <FooterAppp />
    </>
  )
}

export default RecoveryPassword;
