import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import MainComponent from './MainComponent';
import WarningAlert from "../../components/Alerts/WarningAlert";
import BlackfridayAlert from "../../components/Alerts/BlackfridayAlert";
import LineKickStarter from '../../components/LineKickStarter';
const Main = () => {
  return (
    <React.Fragment>
      <Navbar />
      <BlackfridayAlert show={true}/>
      <MainComponent />
      <FooterAppp padding={"50px"}/>
      <LineKickStarter  id="warning_line" color="red" noAnalytics/>
      <LineKickStarter  id="kickstarter_line" />
    </React.Fragment>
  );
};

export default Main;
