import React from "react";
import ScrollLink from "../../components/ScrollLink";
import "swiper/swiper.scss";
import {
  KickstarterBadge,
  SprayCareBandVideo,
  SprayCareBandVideoPhone,
  FtDisinfectant,
  FtRepellent,
  FtOils,
  FtAromatherapy,
} from "../../components/imagesComponents";
import ScrollAnimation from "react-animate-on-scroll";
import {ReactComponent as AmazonLogo} from '../../assets/img/AmazonBadge.svg';
const ftImages = [
  {
    img: FtDisinfectant,
    title: "Disinfectant Liquid",
    content: undefined
  },
  {
    img: FtRepellent,
    title: "Repellent Liquid",
    content: undefined
  },
  {
    img: FtOils,
    title: "Essentials oils",
    content: undefined
  },
  {
    img: FtAromatherapy,
    title: "Aromatherapy",
    content: undefined
  },
]

const Thumb = ({src, alt, title}) => (
     <div className="thumb-ctnr">
        <div className='thumb'>
          <img src={src} alt={alt}/>
          <span className="thumb__content">
            {title}
          </span>
        </div>
      </div>
);


const Header = (props) => (
  <header className="header" ref={props.headerRef}>
    <div className="header__media-content">
      <video className="video-background-phone" autoPlay loop muted playsInline>
        <source src={SprayCareBandVideoPhone} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <video
        className="video-background-desktop"
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={SprayCareBandVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
    </div>
    <ScrollAnimation
      className="header__text-box"
      animateIn="animate__fadeIn"
      delay={props.width <= 550 ? 3000 : 0}
    >
      <h2 className="heading-secondary heading-secondary--white u-margin-bottom-br">
        Spread good vibes
      </h2>
      <p className="paragraph paragraph--white u-margin-bottom-br">
        SprayCare is a lightweight wearable liquid dispenser created to clean up your hands and surfaces.
      </p>
      <div className="btn-group">
        <a href="/product" target="_blank" className="btn btn--order-now">
          ORDER NOW
        </a>
        <a
          href="https://www.amazon.com/dp/B09QT68WZ8"
          target="_blank"
          className="btn btn--amazon"
        >
          <AmazonLogo />
        </a>
      </div>
    </ScrollAnimation>
    <div className="mini-gallery">
      <span className="mini-gallery-use">USE CASES</span>
      <div className="mini-gallery-content">
        {ftImages.map(({ img, title }, index) => (
          <Thumb key={index} src={img} title={title} alt={title} />
        ))}
      </div>
      <span className="mini-gallery-use mini-gallery-use--advice">**Do not use oils directly, mix with distilled water, avoid liquids with any type of solvents <ScrollLink to={"/troubleshooting"} hash={"#oil"}>Read More.</ScrollLink></span>
      <div className="kickstarter-badge">
        <img src={KickstarterBadge} alt="" />
      </div>
    </div>
  </header>
);

export default Header;
