import React from "react";
import {
  useHistory,
} from "react-router-dom";


const ScrollLink = ({ children, to, hash, className, onPress, ...props }) => {
    let navigate = useHistory ();

    const clearAllTimeOuts = (hash) => {
        setTimeout(() => {
          if(hash) window.location.hash = hash;
        }, 0.1);
      }
    
    const navigator = (dir, hash) => {
        if (window.location.pathname !== dir) {
            setTimeout(() => {
                navigate.push(dir)
                clearAllTimeOuts(hash);
            }, 0.2);
        }
        if (((window.location.pathname === dir) && hash) && window.location.hash !== hash) {
            window.location.hash = hash
        }
    }

    const action = () => {
        onPress()
        navigator(to, hash)
    }

    return (
        <a
        style={{cursor: "pointer"}}
        className={`${className} ? ${className} : ""}`}
        onClick={onPress ? () => { action() } : ()=> {navigator(to, hash)} }
          {...props}
        >
          {children}
        </a>
    );
  }

  export default ScrollLink;