import React, { useEffect, useState } from 'react';

import CellPhone from '../../assets/img/cellphone.svg';
import MapMarker from '../../assets/img/map-marker.svg';
import  Email from '../../assets/img/email.svg';
import Instagram from '../../assets/img/instagram.svg';
import  Facebook from '../../assets/img/facebook.svg';

import generateRequest from '../../services/RequestService';
import SpinnerComponent from '../../components/spinner';
import Swal from 'sweetalert2';
const ContactComponent = () => {
  const [loading,setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);
  const [buttonValue, setButtonValue] = useState('CONTACT')
  useEffect(() => { }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    if (email === '' || subject === '' || message === '') {
      Swal.fire({ type: 'error', title: 'Please, fill all fields' });
    } else {
      setLoading(true);
      let object = { email, subject, message };
      setButtonValue("SENDING...");
      setIsSending(true);
      generateRequest('api/contact', 'POST', object)
        .then((val) => {
          setButtonValue("CONTACT");
          setIsSending(false);
          setEmail('');
          setSubject('');
          setMessage('');
          setLoading(false);
          Swal.fire({
            type: "success",
            title: "Email received, thanks for your interest"
          })
        })
        .catch((e) => {
          Swal.fire({
            type: "error",
            title: "Error to send email, check your internet connection"
          })
        });
    }
  };
  return (
    <React.Fragment>
      <section id="contact_section">
        {loading && <SpinnerComponent color={"transparent"} />}
        <div className="contact_section__first-element remove_mobile">
          <ul className="contact_section-list">
            <li>
              <img src={CellPhone} alt={'Map marker'} />
              +1(801) 753-8054
            </li>
            <li>
              <img src={MapMarker} alt={'Map marker'} />
              1881 W Traverse Pkwy Ste E #517<br /> Lehi UT
              84043 USA
            </li>
            <li>
              <img src={Email} alt={'Email'} /><a href="mailto:hello@spray.care">hello@spray.care</a>
            </li>
          </ul>
        </div>
        <div className="contact_section__second-element">
          <section className="special_container special_container--contact">
            <h1>Contact Us</h1>
            <p>We'd love to hear from you.</p>
            <form className="forms" id="contact_form" onSubmit={onSubmit}>
              <div>
                <input
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  value={email}
                />
                <label placeholder="Email"></label>
              </div>
              <div>
                <input
                  onChange={(e) => setSubject(e.currentTarget.value)}
                  type="text"
                  name="subject"
                  required
                  placeholder="Subject"
                  value={subject}
                />
                <label placeholder="Subject"></label>
              </div>
              <div>
                <textarea
                  placeholder="Message"
                  required
                  value={message}
                  onChange={(e) =>
                    setMessage(e.currentTarget.value)
                  }></textarea>
                <label placeholder="Message"></label>
              </div>
              <button type="submit" disabled={isSending} className={`largeButtonOrder ${isSending ? 'disabled' : ''}`}>
                {buttonValue}
              </button>
            </form>
          </section>
        </div>
        <div className="contact_section__third-element remove_mobile">
          <ul className="contact_section-list list-right">
            <li>
              <a href='https://www.instagram.com/spraycareband' target="_blank">
              Instagram&nbsp;
              <img src={Instagram} />
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/SprayCare-Band-112374130545619' target="_blank">
              Facebook&nbsp;
              <img src={Facebook} />
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="show_mobile">
        <div className="mobile_view">
          <h1 style={{ textAlign: 'center' }}>Say Hello</h1>
          <ul className="contact_section-list">
          <li style={{width: '100%'}}>
              <img src={MapMarker} />
              1881 W Traverse Pkwy Ste E #517<br /> Lehi UT
              84043 USA
            </li>
            <li style={{width: '100%'}}>
              <img src={CellPhone} />
              +1(801) 753-8054
            </li>
            <li style={{width: '100%'}}>
              <img src={Email} /> hello@spray.care
            </li>
          </ul>
        </div>
        <div className="mobile_view">
          <h1 style={{ textAlign: 'center' }}>Follow Us</h1>
          <ul className="contact_section-list">
            <li>
              <a href='https://www.instagram.com/spraycareband' target="_blank">
              Instagram &nbsp;
              <img src={Instagram} alt={'Instagram Logo'} />
              </a>
            </li>
            <li>
              <a href='https://www.facebook.com/SprayCare-Band-112374130545619' target="_blank">
              Facebook &nbsp;
               <img src={Facebook} alt={'Facebook Logo'} />
              </a>
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  );
};

export default ContactComponent;
