import React from "react";
import { SprayCareBannerFeaturesVideo, SprayCareBannerFeaturesVideoMobile, SprayCareBannerFeaturesVideoTablet } from "../../components/imagesComponents";
import ScrollAnimation from "react-animate-on-scroll";

const SprayCareBannerFeaturesSection = ({bannerFeat}) => (
<div className="banner-features" ref={bannerFeat}>
    <div className="banner-features__content">
      <ScrollAnimation animateIn='animate__fadeInUp'>
      <h2 className='heading-secondary heading-secondary--white heading-secondary--line-center'>
      The world's first wristband atomizer
        </h2>
       </ScrollAnimation>
    </div>
    <video autoPlay loop muted playsInline id='banrFeatVideoDektop'>
      <source src={SprayCareBannerFeaturesVideo} type='video/mp4'/>
        Your browser does not support HTML5 video.
    </video>
    <video autoPlay loop muted playsInline id='banrFeatVideoTablet'>
      <source src={SprayCareBannerFeaturesVideoTablet} type='video/mp4'/>
        Your browser does not support HTML5 video.
    </video>
    <video autoPlay loop muted playsInline id='banrFeatVideoMobile'>
      <source src={SprayCareBannerFeaturesVideoMobile} type='video/mp4'/>
        Your browser does not support HTML5 video.
    </video>
  </div>
  );
  
export default SprayCareBannerFeaturesSection;