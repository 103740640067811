import { URL } from '../services/conection';
import { deleteCookie } from './CookiesServices';
import Swal from 'sweetalert2';
const generateRequest = (
  pUrl,
  pMethod,
  pBody = {},
  isTokenRequired = false,
  hasTempToken = false
) => {
  return new Promise(async (resolve, reject) => {
    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (isTokenRequired) {
      let token = localStorage.getItem('token');
      if (token) headers['Authorization'] = `Bearer ${token}`;
    }

    if(hasTempToken){
      let token = localStorage.getItem('tempToken');
      if(token) headers['Authorization'] = token;
    }
    
    let options = {
      method: pMethod,
      headers,
    }
    if(pMethod !== 'GET') options.body = JSON.stringify(pBody);

    let resp = await fetch(`${URL}${pUrl}`,options);

    let json = await resp.json();
    if(json){
      if(json?.type === "INVALID_TOKEN"){
        deleteCookie();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('last_order');
        localStorage.removeItem('info');
        Swal.fire({
          title:"Your session expired, please login again",
          onClose:() => {window.location.replace("/login")}
        })
      }else resolve(json);
    }else reject({ message: 'Request error' });
  });
};
export default generateRequest;
