import React, {useMemo, useState, useEffect } from 'react';
import { getCookie } from '../../services/CookiesServices';
import generateRequest from '../../services/RequestService';


import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";


import MasterCard from '../../assets/img/mastercard.svg';
import AmericanExpress from  '../../assets/img/american_express.svg';
import Right from '../../assets/img/right.svg';
import  Venmo from '../../assets/img/venmo.svg';
import Visa from '../../assets/img/visa.svg';
import SpinnerComponent from '../../components/spinner';
import Swal from 'sweetalert2';
import './braintree.css';
import $ from 'jquery';
import { deleteCookie,geTotalDevices } from '../../services/CookiesServices';
import { URL } from '../../services/conection';
import { encryptInformation,dcryptInformation } from '../../services/EncryptService';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
//import {getStates} from "../../services/States";






const useResponsiveFontSize = () => {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
   () => ({
     style: {
       base: {
         fontSize,
         color: "black",
         fontFamily: "Montserrat",
         fontWeight: 500,
         "::placeholder": {
           color: "#757575",
         },
       },
       invalid: {
         color: "red",
       },
     },
   }),
   [fontSize]
  );

  return options;
};
const CheckoutPaymentComponent = ({ changeScreen }) => {

  const [processing, setProcessing] = useState(false);
  const [stripePayButtonIsDisabled, setStripePayButtonIsDisabled] = useState(true);
  const [loading,setLoading] = useState(false);
  const [hostedFormValues, setHostedFormValues] = useState(null);
  const [dataDevice, setDeviceData] = useState({});
  const [validPay, setValidPay] = useState(false);
  useEffect(() => {
    let objectLocal = localStorage.getItem('info');
    let objectParse = dcryptInformation(objectLocal);
    setDeviceData(objectParse);
    geTotalDevices()
  }, []);


  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const handleStripePayButton = (event) => {
    if (event.complete){
      setStripePayButtonIsDisabled(false);
    }else{
      setStripePayButtonIsDisabled(true);
    }
  }

  const createPaypalOrder = (data, actions) => {

    let devices = dataDevice.devices
    //let stateRate = getStates(dataDevice.country).find((val) => val.abbreviation === dataDevice.state);

    // let shippingC = {
    //   name: 'Shipping',
    //   description: 'Shipping',
    //   unit_amount: {
    //     currency_code: "USD",
    //     value: dataDevice.shipping
    //   },
    // quantity: 1}
    // let taxC = {
    //    name: 'Tax',
    //    description: 'Tax',
    //    unit_amount: {
    //      currency_code: "USD",
    //      value: dataDevice.sales_tax
    //    },
    //   quantity: 1}

    let productItems = Object.keys(devices).map(function(item){
      //let saleTax = stateRate.sales_tax;
      //let tax = parseFloat(((devices[item].price / 100) * saleTax).toFixed(2));
      return {
        name: devices[item].name,
        description: devices[item].name + ' ' + devices[item].colorName,
        unit_amount: {
          currency_code: "USD",
          value: devices[item].wholesaleprice && devices[item].quantity === 25 ? devices[item].wholesaleprice : devices[item].price
        },
        quantity: devices[item].quantity,
      }
    })
    //productItems.push(taxC)
    //productItems.push(shippingC)

    return actions.order
     .create({
       intent: "CAPTURE",
       purchase_units: [
         {
           description: 'SprayCare Band Company',
           amount: {
             currency_code: "USD",
             details: {
               subtotal: dataDevice.subtotal,
               shipping: dataDevice.shipping,
               tax: dataDevice.sales_tax
             },
             value: dataDevice.total,
             breakdown: {
               item_total: {
                 currency_code: "USD",
                 value: dataDevice.subtotal
               },
               shipping: {
                 currency_code: "USD",
                 value: dataDevice.shipping
               },
               tax_total: {
                 currency_code: "USD",
                 value: dataDevice.sales_tax
               }
             }
           },
           payee: {
             email: dataDevice.email
           },
           items: productItems,
           // shipping_address :{
           //   "line1": "2211 N First Street",
           //   "line2": "Building 17",
           //   "city": "San Jose",
           //   "country_code": "US",
           //   "postal_code": "95131",
           //   "state": "CA",
           //   "phone": "(123) 456-7890"
           //   }
         },
       ],
       // not needed if a shipping address is actually needed
       application_context: {
         shipping_preference: "NO_SHIPPING",
       },
     })
     .then((orderID) => {
       return orderID;
     });
  };

  // check Approval
  const onApprove = async (data, actions) => {
    return  actions.order.capture().then(async function (details) {
      const { id, status, purchase_units } = details;
      try{
        let paymentType = 'PAYPAL'
        // if (purchase_units[0] && purchase_units[0].soft_descriptor){
        //   paymentType = 'CARD'
        // }
        dataDevice.method_pay = paymentType;
        dataDevice.quantity = geTotalDevices();
        dataDevice.id = id
        const response = await fetch(`${URL}api/payment/create-payment-intent`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataDevice),
        });

        let res = await response.json();
        let objectOrder = {
          customer: `${dataDevice.firstName} ${dataDevice.lastName}`,
          id: res.saleId,
          method: paymentType,
          email: dataDevice.email,
          date: new Date(),
          devices: dataDevice.devices,
          total: dataDevice.total,
          sale_tax:dataDevice.sales_tax,
          subtotal: dataDevice.subtotal,
          shipping: dataDevice.shipping,
        };
        sessionStorage.setItem('last_order', JSON.stringify(objectOrder));
        if (res.code === 200) {
          deleteCookie();
          localStorage.removeItem("info");
          window.location.replace('pay_success');
        }else {
          setLoading(false);
          Swal.fire("Error", res.message)
        }

      }catch(ex){
        console.log('Error', ex)
        setProcessing(false);
        setLoading(false);
      }
    });
  };
  //capture likely error
  const onError = (data, actions) => {
    Swal.fire("Error in the transaction");
  };



  const onPaypalPay = (pFlag) => {
    setLoading(pFlag);
  }

  const previous = () => {
    changeScreen({ id: 2, type: 'PREVIUS' });
  };
  const expire = () =>{
    let backerEmail = getCookie('backer_validation');
    if(backerEmail !== ''){
      generateRequest('api/user/expire-backer', 'POST', {backerEmail}, true)
      .then(({applied, message}) => {
        if (applied) {
          console.log(message);
          deleteCookie();
          localStorage.removeItem("info");
          window.location.replace('pay_success');
        }else{
          console.log(message);
          deleteCookie();
          localStorage.removeItem("info");
          window.location.replace('pay_success');
        }
      })
      .catch((e) => console.log(e, "error"));
    }else{
    console.error('Free shipping cannot be applied');
    // deleteCookie();
    // localStorage.removeItem("info");
    // window.location.replace('pay_success');
    }
  }
  const pay = async (event) => {
    event.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (!error) {
      try{
        const {id} = paymentMethod;
        let dataLocal = dataDevice;
        dataLocal.method_pay = "CARD";
        dataLocal.quantity = geTotalDevices();
        dataLocal.id = id
        dataLocal.isStripe = true
        const response = await fetch(`${URL}api/payment/create-payment-intent`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(dataLocal),
        });

        let res = await response.json();
        let objectOrder = {
          customer: `${dataDevice.firstName} ${dataDevice.lastName}`,
          id: res.saleId,
          method: 'CARD',
          email: dataDevice.email,
          date: new Date(),
          devices: dataDevice.devices,
          total: dataDevice.total,
          sale_tax:dataDevice.sales_tax,
          subtotal: dataDevice.subtotal,
          shipping: dataDevice.shipping,
        };
        sessionStorage.setItem('last_order', JSON.stringify(objectOrder));
        if (res.code === 200) {
          deleteCookie();
          localStorage.removeItem("info");
          window.location.replace('pay_success');
        }else {
          setLoading(false);
          Swal.fire("Error","We have an error with your payment method, please try again")
        }

      }catch(ex){
        console.log('Error', ex)
        setProcessing(false);
        setLoading(false);
      }

    }else{
      console.log('errorsote', error.message)
      setLoading(false);
      Swal.fire("Error",error.message)
    }



  //   hostedFormValues.tokenize(async function (tokenizeErr, payload) {
  //     if (tokenizeErr) {
  //       console.error(tokenizeErr);
  //       return;
  //     }
  //     let dataLocal = data;
  //     dataLocal.nonce = payload.nonce;
  //     dataLocal.method_pay = "CARD";
  //     dataLocal.quantity = geTotalDevices();
  //     let sendPay = await fetch(`${URL}api/payment/checkout`, {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(dataLocal),
  //     });
  //     let resp = await sendPay.json();
  //     let objectOrder = {
  //       customer: `${data.firstName} ${data.lastName}`,
  //       id: resp.saleId,
  //       method: 'CARD',
  //       email: data.email,
  //       date: new Date(),
  //       devices: data.devices,
  //       total: data.total,
  //       sale_tax:data.sales_tax,
  //       subtotal: data.subtotal,
  //       shipping: data.shipping,
  //     };
  //     sessionStorage.setItem('last_order', JSON.stringify(objectOrder));
  //     if (resp.code === 200) {
  //       expire()
  //     }else {
  //       setLoading(false);
  //       Swal.fire("Error","There is an issue with your payment method, please try again")
  //     }
  //   });
   };



  return (
    <React.Fragment>
     {loading && <SpinnerComponent />}
      <form onSubmit={pay} className="forms" id="stripeCardForm">
      <section id="payment_section">
        <div>
          <div>
            Credit card
          </div>
          <div>
            <ul>
              <li>
                <img src={Visa} alt={'visa'} />
              </li>
              <li>
                <img src={MasterCard} alt={'Master card'} />
              </li>
              <li>
                <img src={AmericanExpress} alt={'American Express'} />
              </li>
            </ul>
          </div>
        </div>
        <div className="forms unable-first-child-style">
            <CardElement options={{...options, showIcon: true}} onChange={handleStripePayButton} />
        </div>
        <div className="forms unable-first-child-style">
            <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }}>
              <PayPalButtons
               style={{ layout: "horizontal",
                 shape:  'rect',
                 label:  'pay',
                 height: 40, tagline: false}}
               createOrder={createPaypalOrder}
               onApprove={onApprove}
              />
            </PayPalScriptProvider>
        </div>
      </section>
      <div className="button_form_shipping_container">
        <div>
          <button
            type="button"
            className="largeButtonOrder btn_transparent"
            onClick={previous}>
            RETURN TO SHIPPING
          </button>
        </div>
        <div>
          <button
            type="submit"
            className={`largeButtonOrder ${stripePayButtonIsDisabled ? "disabled" : ""}`}
            onClick={pay}
            disabled={stripePayButtonIsDisabled}
            form="stripeCardForm"
          >
            PAY NOW
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        id="modalCard"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
        </div>

      </div>
      </form>
    </React.Fragment>
  );
};

export default CheckoutPaymentComponent;
