import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import CreateAccountComponent from './CreateAccount';
const CreateAccount = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <CreateAccountComponent />
      <FooterAppp />
    </React.Fragment>
  );
};

export default CreateAccount;
