import React, { useEffect, useState } from 'react';

import  LogoBlack from '../../assets/img/logo-black.svg';
import ElementToPayComponent from '../../components/ElemetToPayComponent';
import { Link } from "react-router-dom";
const PaySuccessComponent = () => {
  const [devices, setDevices] = useState([]);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [saleTax,setSaleTax] = useState(0);
  const [shippingCost, setShippingCost] = useState(10);
  const [sale, setSale] = useState({});
  const [dateFomat, setDateFormat] = useState('');
  useEffect(() => {
    let localInformation = sessionStorage.getItem('last_order');
    if (localInformation !== null && localInformation !== '') {
      let informationParse = JSON.parse(localInformation);
      setSale(informationParse);
      setTotal(informationParse.total);
      setSubTotal(informationParse.subtotal);
      setShippingCost(informationParse.shipping);
      setDevices(informationParse.devices);
      setSaleTax(informationParse.sale_tax);
      let newDate = new Date(informationParse.date);
      let month = newDate.getMonth() + 1;
      let day =
        newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
      let fullDate = `${day}/${
        month < 10 ? '0' + month : month
      }/${newDate.getFullYear()}`;
      setDateFormat(fullDate);
    }
  }, []);
  return (
    <>
      <section id="pay_success_continer">
        <div className="pay_success-logo">
          <a href="/">
            <img src={LogoBlack} alt="logo" />
          </a>
        </div>
        <div className="order">
          <h1 className="order_customer-name">Hey {sale.customer},</h1>
          <div className="order_titles">
            <h6>Here are your order details.</h6>
            <h6>Thanks.</h6>
            <h6>
              Order confirmation #{sale.id} <strong>{sale.status}</strong>
            </h6>
          </div>
          <div className="tabs_container">
            <div className="tabs_card">
              <div>
                <span className="card_black">Customer:</span>{' '}
                <span>{sale.email}</span>
              </div>
              <div>
                <span className="card_black">Payment method:</span>{' '}
                <span>{sale.method}</span>
              </div>
            </div>
            <div className="tabs_card">
              <div>
                <span className="card_black">Order number:</span>{' '}
                <span>#{sale.id} </span>
              </div>
              <div>
                <span className="card_black">Currency:</span> <span>USD</span>
              </div>
            </div>
            <div className="tabs_card">
              <div>
                <span className="card_black">Invoice date:</span>{' '}
                <span>{dateFomat} </span>
              </div>
            </div>
            <div className="tabs_card">
              <div className="tabs_card-table">
                <div>
                  <div id="car_item_checkout">
                    {devices.map((element, index) => (
                      <ElementToPayComponent key={index} {...element} />
                    ))}
                  </div>
                  <div className="car_item_checkout-costs">
                    <div className="cost_container">
                      <div>
                        <h5>Cost of shipping</h5>
                      </div>
                      <div><span>${shippingCost}</span></div>
                    </div>
                    <div className="cost_container">
                      <div>
                        <h5>Sale Tax</h5>
                      </div>
                      <div><span>${saleTax}</span></div>
                    </div>
                    <div className="cost_container">
                      <div>
                        <h5>Subtotal</h5>
                      </div>
                      <div><span>${subTotal}</span></div>
                    </div>
                  </div>
                  <div>
                    <div className="cost_container">
                      <div>
                        <h1>Total</h1>
                      </div>
                      <div><span>${total}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link to='/' className="largeButtonOrder">
          <button type="button" className="largeButtonOrder">BACK HOME</button>
          </Link>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
        </div>
      </section>
    </>
  );
};

export default PaySuccessComponent;
