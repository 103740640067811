import React from 'react';
import Navbar from '../../components/navbar';
import FooterAppp from '../../components/footerApp';
import DownloadComponent from './DownloadComponent';
const Manual = () => {
  return (
    <React.Fragment>
      <Navbar isBlack={true} />
      <DownloadComponent />
      <FooterAppp />
    </React.Fragment>
  );
};

export default Manual;
