import React from 'react';
import { Link } from "react-router-dom";

const Accesory = ({ id, img, text, onClick, checked, price, link,  }) => (
   <div className={checked ? "accessory accessory-selected" : "accessory"} onClick={onClick} id={id}>
    <div className="accessory-data">
    <p className="accessory-name">
      {text}
      <span>{`$${price}`}</span>
    </p>
    {img &&
      <div className='accessory-img'>
        {link
          ? (<Link to={link}>
            <img src={img} alt={text} />
          </Link>
          ) : (<img src={img} alt={text} />)}
      </div>}
    </div>
  </div>
)

export default Accesory;