import React from 'react';
import Swal from 'sweetalert2';
const AddressComponent = ({
  _id,
  address,
  apartment,
  city,
  country,
  firstname,
  lastname,
  phone,
  zip,
  openModal,
  deleteAddress,
  state,
  showDeleteButton=true
}) => {
  return (
    <React.Fragment>
      <div className="frame frame_address">
        <div
          className="update_address"
          onClick={() => {
            let object = {
              _id,
              address,
              apartment,
              city,
              country,
              firstname,
              lastname,
              phone,
              state,
              zip,
            };
            openModal(object);
          }}>
          <ul>
            <li>
              <span>{firstname}</span> <span>{lastname}</span>
            </li>
            <li>
              <span className="zip">{zip}</span>{' '}
              <span className="city">{city}</span>{' '}
              <span className="province">{state}</span>
            </li>
            <li className="country"><span>{country}</span></li>
            <li className="address"><span>{address}</span></li>
          </ul>
        </div>

        <div>
        {showDeleteButton?(<button
          className="delete_address"
          onClick={() => {
            Swal.fire({
              icon: 'warning',
              title: 'Are you sure?',
              cancelButtonColor: '#d33',
              text: "You won't be able to revert this!",
              confirmButtonText: 'Yes, delete it!',
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                deleteAddress(_id);
              }
            });
          }}>
          -
        </button>):''}

        </div>
      </div>
    </React.Fragment>
  );
};
export default AddressComponent;
