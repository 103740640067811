import React from 'react';
import FooterApp from '../../components/footerApp';
import PaySuccessComponent from './PaySuccessComponent';
const PaySuccess = () => {
  return (
    <>
      <PaySuccessComponent />
      <FooterApp />
    </>
  );
};
export default PaySuccess;
