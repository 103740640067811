import React, { useState } from "react";
// import {
//   SCColorPink2,
//   SCColorWhite2,
//   SCColorBlue2,
//   SCColorBlack2,
// } from "../../components/imagesComponents";

const RewardItems = (props) => {
  const [wristBandColor, setWristBandColor] = useState("BLACK");

  const wBColor = (color) => {
    if (color === "WHITE") {
      return "#BFBFBF";
    } else if (color === "PINK") {
      return "#FFA1C6";
    } else if (color === "BLUE") {
      return "#0057b8";
    } else {
      return "#000000";
    }
  };

  // const wBImage = (color) => {
  //   if (color === "WHITE") {
  //     return SCColorWhite2;
  //   } else if (color === "PINK") {
  //     return SCColorPink2;
  //   } else if (color === "BLUE") {
  //     return SCColorBlue2;
  //   } else {
  //     return SCColorBlack2;
  //   }
  // };

  const currentBackerDevicesObject = props.backerDevicesObject;

  return (
    <>
      <div
        style={{ maxWidth: "650px", marginLeft: "auto", marginRight: "auto" }}
      >
        <p>
          <b>
            Please select your {props.deviceNumber} wristband color
          </b>
        </p>
      </div>
      <br />
      <br />
      <div className={`your-rewards-items ${props.editDisabled ? "your-rewards-items--disabled" : ""}`}>
        {/* <img src={`${wBImage(wristBandColor)}`} alt="" /> */}
        <div className="wristband">
          <div className="wristband-color">
            <input
              type="radio"
              name={props.group}
              id={`wristbandColorBlack${props.group}`}
              value="BLACK"
              checked={props.disabled ? props.filled === "BLACK" ? true : false : wristBandColor === "BLACK" ? true : false}
              onChange={e => {}}
              disabled={props.disabled}
              onClick={(e) => {
                setWristBandColor(e.target.value);
                currentBackerDevicesObject[props.deviceNumber + "Wristband"] =
                  e.target.value;
                props.setBackerDevicesObject(currentBackerDevicesObject);
              }}
            />
            <div className={`wristband-color-radio ${props.editDisabled ? "wristband-color-radio--disabled" : ""}`}/>
            <label htmlFor={`wristbandColorBlack${props.group}`}> Black </label>
          </div>
          <div className="wristband-color">
            <input
              type="radio"
              name={props.group}
              id={`wristbandColorBlue${props.group}`}
              value="BLUE"
              checked={props.disabled ? props.filled === "BLUE" ? true : false : wristBandColor === "BLUE" ? true : false}
              onChange={e => {}}
              disabled={props.disabled}
              onClick={(e) => {
                setWristBandColor(e.target.value);
                currentBackerDevicesObject[props.deviceNumber + "Wristband"] =
                  e.target.value;
                props.setBackerDevicesObject(currentBackerDevicesObject);
              }}
            />
            <div className={`wristband-color-radio ${props.editDisabled ? "wristband-color-radio--disabled" : ""}`} />
            <label htmlFor={`wristbandColorBlue${props.group}`}> Blue </label>
          </div>
          <div className="wristband-color">
            <input
              type="radio"
              name={props.group}
              id={`wristbandColorWhite${props.group}`}
              value="WHITE"
              checked={props.disabled ? props.filled === "WHITE" ? true : false : wristBandColor === "WHITE" ? true : false}
              onChange={e => {}}
              disabled={props.disabled}
              onClick={(e) => {
                setWristBandColor(e.target.value);
                currentBackerDevicesObject[props.deviceNumber + "Wristband"] =
                  e.target.value;
                props.setBackerDevicesObject(currentBackerDevicesObject);
              }}
            />
            <div className={`wristband-color-radio ${props.editDisabled ? "wristband-color-radio--disabled" : ""}`} />
            <label htmlFor={`wristbandColorWhite${props.group}`}> White </label>
          </div>
          <div className="wristband-color">
            <input
              type="radio"
              name={props.group}
              id={`wristbandColorPink${props.group}`}
              value="PINK"
              checked={props.disabled ? props.filled === "PINK" ? true : false : wristBandColor === "PINK" ? true : false}
              onChange={e => {}}
              disabled={props.disabled}
              onClick={(e) => {
                setWristBandColor(e.target.value);
                currentBackerDevicesObject[props.deviceNumber + "Wristband"] =
                  e.target.value;
                props.setBackerDevicesObject(currentBackerDevicesObject);
              }}
            />
            <div className={`wristband-color-radio ${props.editDisabled ? "wristband-color-radio--disabled" : ""}`} />
            <label htmlFor={`wristbandColorPink${props.group}`}> Pink </label>
          </div>
        </div>
        <h1
          style={{
            marginLeft: "auto",
            transition: "all cubic-bezier(0.68, -0.55, 0.265, 1.55) 0.2s",
            color: `${wBColor(props.disabled ? props.filled : wristBandColor)}`,
          }}
        >
          {props.disabled ? props.filled : wristBandColor}
        </h1>
      </div>
    </>
  );
};

export default RewardItems;
