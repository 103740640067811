import React,{useState} from 'react';
import Swal from 'sweetalert2';
import generateRequest from "../../services/RequestService";
import $ from 'jquery';

const ModalContent = () => {
  const [email,setEmail] = useState('');
  const [loading,setLoading] = useState(false);
  const [buttonText,setButtonText] = useState('SEND');
  const onSubmit = async (e) => {
    e.preventDefault();
    if(email === ''){
      Swal.fire("Please fill email field");
      return;
    }
    setLoading(true);
    setButtonText("Sending...");
    let resp = await generateRequest("api/user/recover-password",'POST',{email:email.trim()},false);
    if(resp.code === 200) {
      Swal.fire("Please, check your email");
      setEmail('');
      $('#recoverModal').modal("hide");
    }else Swal.fire("Error in your request, try Again");
    setLoading(false);
    setButtonText("SEND");
  }
    return (
      <>
      <div
        id="recoverModal"
        className="modal fade bd-example-modal-lg"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <h5 style={{ textAlign: 'center', marginTop: '30px',fontSize:"20px" }}>
              Recover Password
            </h5>
            <form className="forms" onSubmit={onSubmit}>
              <div>
                <input
                  type="email"
                  name="city"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label placeholder="Email"></label>
              </div>
              <div>
                <div className="forms-multiple_fields forms_padding_fields">
                  <button
                    type="button"
                    className="largeButtonOrder btn_transparent"
                    data-dismiss="modal">
                    CANCEL
                  </button>
                </div>
                <div className="forms-multiple_fields">
                  <button type="submit" className={`largeButtonOrder ${loading?'disabled':''}`} disabled={loading}>
                    {buttonText}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
}

export default ModalContent;
