import React from 'react';
import Selected from '../../assets/img/selected.svg';
import Accesory from './Accesory';

const ProductView = (props) => {

  return (<section id="producto_container">
    <div>
      <div className="producto_container-reloj">
        <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img alt={`SprayCare ${props.selectedColor.color}`} ref={props.spraycareWatches} className="producto_container-img" src={props.addonImage ? props.addonImage : props.selectedColor.images} />
            </div>
            {props.imageProduct2 && <div className="carousel-item">
              <img alt='SprayCare with repellent' ref={props.spraycareWatches} className="producto_container-img" src={props.imageProduct2} />
            </div>}
            {props.imageProduct3 && <div className="carousel-item">
              <img alt='SprayCare with disinfectant' ref={props.spraycareWatches} className="producto_container-img" src={props.imageProduct3} />
            </div>}
          </div>
        </div>
        {props.thumbEnable && <ul>
          <li data-target="#carouselExampleIndicators" data-slide-to="0">
            <img src={props.selectedColor.images} alt={`SprayCare ${props.selectedColor.color}`} />
          </li>
          {props.imageProduct3 && <li data-target="#carouselExampleIndicators" data-slide-to="1">
            <img src={props.imageProduct2} alt='SprayCare with repellent' />
          </li>}
          {props.imageProduct3 && <li data-target="#carouselExampleIndicators" data-slide-to="2">
            <img src={props.imageProduct3} alt='SprayCare with disinfectant' />
          </li>}
        </ul>}
      </div>
    </div>
    <div>
      <div>
        <h1 className="line_image">SprayCare Band</h1>
        <span>{props.discount ? 'US $25.99' : 'US $23.00'} {props.discount && ( <span className='line_image--discount'>US $32.99</span> )}<span className='line_image--discount'>US $32.99</span></span>
        {props.enableColorChoose && <div div className="producto_container-colors">
          <h1>Colors: <span style={{
            color: props.selectedColor.colorValue,
            fontWeight: 800
          }}>{props.selectedColor.color}</span></h1>
          <ul>
            <li data-index="0" onClick={e => props.selectItem(e)}>
              <img alt={`SprayCare ${props.selectedColor.color}`} className="color_selected" src={Selected} />
            </li>
            <li data-index="1" onClick={e => props.selectItem(e)}>
              <img alt={`SprayCare ${props.selectedColor.color}`} className="color_selected" src={Selected} />
            </li>
            <li data-index="2" onClick={e => props.selectItem(e)}>
              <img alt={`SprayCare ${props.selectedColor.color}`} className="color_selected" src={Selected} />
            </li>
            <li data-index="3" onClick={e => props.selectItem(e)}>
              <img alt={`SprayCare ${props.selectedColor.color}`} className="color_selected" src={Selected} />
            </li>
          </ul>
        </div>}
        <div className="producto_container-quantity">
          <h1>Quantity</h1>
          <ul>
            <li>
              <button className="plusLesButtons" onClick={() => props.plusLess('less')}>
                -
                  </button>
            </li>
            <li id="number_car">{props.quantity ? props.quantity : 0}</li>
            <li>
              <button className="plusLesButtons" onClick={() => props.plusLess('pluss')}>
                +
                  </button>
            </li>
          </ul>
        </div>
        {props.enableAccesories && <div className='accesories'>
          <h1>Accesories</h1>
          {props.addOn.map(
            ({ id, text, img, checked, price, setChecked, color, link }) => (
              <Accesory
                id={id}
                text={text}
                img={img}
                checked={checked}
                price={price}
                onClick={setChecked}
                color={color}
                link={link}
              />
            )
          )}
        </div>}
        {props.backerInput && <form action="" className="forms forms-fluid ">
        <h3 onClick={props.testFunction}>If you are a backer on KickStarter or Indiegogo, please enter your email and your backer number, or contribution ID.</h3>
        <br/>
        <br/>
        <div>
            <input
              type="email"
              name="backerEmail"
              placeholder="Email"
              value={props.backerEmail}
              onChange={props.setBackerEmail}
              // required
            />
            <label placeholder="Email"></label>
        </div>
        <div>
            <input
              type="text"
              name="backerNumber"
              placeholder="Backer Number or Contribution ID"
              value={props.backerNumber}
              onChange={props.setBackerNumber}
              // required
            />
            <label placeholder="Backer Number or Contribution ID"></label>
        </div>
        </form>}
        <button
        id="add_car"
        className="largeButtonOrder disabled"
        onClick={() =>
          window.location.pathname === "/strap-adapter"
            ? (props.saveBackerNumber())
            : props.quantity === 0 ||
              props.quantity === false ||
              props.idReloj === null
            ? props.addJustAddons()
            : props.addShoppingCart()
        }
        disabled={
          props.length > 0
            ? props.length === 0
            : props.quantity === 0 ||
              props.quantity === false ||
              props.idReloj === null
        }
        >
          ORDER NOW
        </button>
      </div>
    </div>
  </section>);
}

export default ProductView;
