import React from "react";
import {
  SmartwatchFront,
  SmartwatchSide,
  SprayCareFront,
  SprayCareSide
} from "../../components/imagesComponents";

const SizeComparison = ({sizeComparisonRef}) => (
<div className="size-comparison" ref={sizeComparisonRef}>
    <h1 className='heading-primary heading-primary--white heading-primary--line'>
      Size comparison
      </h1>
    <div className="size-comparison__content">
      <div className="size-comparison__item-ctnr">
        <div className="size-comparison__item">
          <div className="size-comparison__item-title size-comparison__item-title--sprayCare">
            <h2 className='heading-quinary heading-quinary--white heading-quinary--features'>
              SprayCare
              </h2>
          </div>
          <div className="size-comparison__item-height size-comparison__item-height--sprayCare"><p className="paragraph paragraph--white paragraph--measures">1.69 inches <br /> (43mm)</p></div>
          <div className="size-comparison__item-width size-comparison__item-width--sprayCare"><p className="paragraph paragraph--white paragraph--measures">1.6&nbsp;inches <br /> (40mm)</p></div>
          <img src={SprayCareFront} alt="" className="size-comparison__item-img size-comparison__item-img--sprayCare" />
        </div>
      </div>
      <div className="size-comparison__item-ctnr">
        <div className="size-comparison__item">
          <div className="size-comparison__item-title size-comparison__item-title--smartwatch">
            <h2 className='heading-quinary heading-quinary--white heading-quinary--features'>
              Smartwatch
              </h2>
          </div>
          <div className="size-comparison__item-height size-comparison__item-height--smartwatch"><p className="paragraph paragraph--white paragraph--measures">1.73 inches <br /> (44mm)</p></div>
          <div className="size-comparison__item-width size-comparison__item-width--smartwatch"><p className="paragraph paragraph--white paragraph--measures">1.5&nbsp;inches<br />(38mm)</p></div>
          <img src={SmartwatchFront} alt="" className="size-comparison__item-img size-comparison__item-img--smartwatch" />
        </div>
      </div>
    </div>
    <div className="size-comparison__content">
      <div className="size-comparison__item-ctnr">
        <div className="size-comparison__item">
          <div className="size-comparison__item-title size-comparison__item-title--sprayCare">
            <h2 className='heading-quinary heading-quinary--white heading-quinary--features'>
              SprayCare
              </h2>
          </div>
          <div className="size-comparison__item-height size-comparison__item-height--sprayCare"><p className="paragraph paragraph--white paragraph--measures">1.69&nbsp;inches <br /> (43mm)</p></div>
          <div className="size-comparison__item-width size-comparison__item-width--sprayCare"><p className="paragraph paragraph--white paragraph--measures">0.6&nbsp;inches<br /> (15mm)</p></div>
          <img src={SprayCareSide} alt="" className="size-comparison__item-img size-comparison__item-img--sprayCare" />
        </div>
      </div>
      <div className="size-comparison__item-ctnr">
        <div className="size-comparison__item">
          <div className="size-comparison__item-title size-comparison__item-title--smartwatch">
            <h2 className='heading-quinary heading-quinary--white heading-quinary--features'>
              Smartwatch
              </h2>
          </div>
          <div className="size-comparison__item-height size-comparison__item-height--smartwatch"><p className="paragraph paragraph--white paragraph--measures">1.73 inches <br /> (44mm)</p></div>
          <div className="size-comparison__item-width size-comparison__item-width--smartwatch"><p className="paragraph paragraph--white paragraph--measures">0.41 inches<br />(10.4mm)</p></div>
          <img src={SmartwatchSide} alt="" className="size-comparison__item-img size-comparison__item-img--smartwatch" />
        </div>
      </div>
    </div>
  </div>
  );

export default SizeComparison;