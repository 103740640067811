import React from "react";
import {
  CriticSlash,
  CriticTrendHunter,
  CriticYanko
} from "../../components/imagesComponents";

const SprayCareCriticsSection = ({criticRef}) => (
<section className="critics" ref={criticRef}>
    <h1 className='heading-primary heading-primary--line'>
      What the media is saying about SprayCare
              </h1>
    <p className='paragraph u-margin-top-medium'>
      Users comments
              </p>
    <div className="critics-content">
      <div className="critics-critic">
        <div className="critics-critic-img">
        <img src={CriticSlash} alt="" />
        </div>
        <p className="paragraph" data-start="“" data-end="”">
          Spider-Man invented a wrist-based blaster of webbing for those times when the webbing isn’t part of the whole radioactive spider setup
            </p>
      </div>
      <div className="critics-critic">
      <div className="critics-critic-img">
        <img src={CriticTrendHunter} alt="" />
      </div>
        <p className="paragraph" data-start="“" data-end="”">
          The SprayCare Band is a wearable accessory that will work to eliminate germs and bacteria that could be lurking on hands as well as common surfaces thanks to its multifunctional design
            </p>
      </div>
      <div className="critics-critic">
      <div className="critics-critic-img">
        <img src={CriticYanko} alt="" />
      </div>
        <p className="paragraph" data-start="“" data-end="”">
          I’ll admit, web-slinging from building to building seems like an absolute dream, but give me a wristband that sanitizes objects and I’d probably settle for that right now!
            </p>
      </div>
    </div>
  </section>
);

export default SprayCareCriticsSection;
